import { AxiosError } from "axios";
import client from "../../api/client";
import TApiResult from "../../types/api/TApiResult";
import getErrorProperty from "./config/getErrorProperty";

export const postPasswordUpdate = async (
  email: string,
  password: string
): Promise<TApiResult> => {
  return client()
    .password.update.post({ body: { email: email, password: password } })
    .then((res) => {
      return {
        isSuccess: true,
        status: 200,
        message: "",
      };
    })
    .catch((e: AxiosError) => {
      const { status, message, errorMessages } = getErrorProperty(
        e,
        "postPasswordUpdate"
      );

      const invalidMessage =
        errorMessages && errorMessages.details.length > 0
          ? errorMessages.details[0].values.join(" ")
          : "正しいパスワードを入力してください。";

      return {
        isSuccess: false,
        status: status,
        message: invalidMessage,
      };
    });
};

import {
  FieldErrors,
  FieldValues,
  Path,
  UseFormRegister,
} from "react-hook-form";
import Label from "../../atoms/form/reactHookForms/Label";
import ErrorMessage from "../../atoms/form/reactHookForms/ErrorMessage";
import STSelectBox from "../../../assets/styles/atoms/form/reactHookForms/selectBox.module.scss";
import { ERROR_BIRTHDAY_DATE } from "../../../utils/messages";

type Props<T extends FieldValues> = {
  label: string;
  formName: Path<T>;
  register: UseFormRegister<T>;
  errors: FieldErrors<T>;
  options: { years: number[]; months: number[]; days: number[] };
  required: boolean;
};

export const DatePullDownRow = <T extends FieldValues>(props: Props<T>) => {
  const { label, formName, register, errors, options, required } = props;

  const isErrors = !!errors[formName];
  const errorMessage = ERROR_BIRTHDAY_DATE; //全て共通の文言

  const containerStyle = {
    display: "flex",
    justifyContent: "space-between",
    gap: "8px",
    marginTop: "8px",
    width: "100%",
    alignItems: "center",
  };

  const selectStyle = {
    flex: 1,
    appearance: "none" as const,
    borderRadius: "5px",
    fontSize: "14px",
    padding: "8px",
    border: "1px solid #ccc",
    backgroundColor: "#fff",
  };

  return (
    <>
      <Label label={label} htmlFor={formName} required={required} />
      <div style={containerStyle}>
        <select
          style={selectStyle}
          {...register(`${formName}.year` as Path<T>)}
        >
          <option value=""></option>
          {options.years.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
        <span className={STSelectBox.selectbox}></span>年
        <select
          style={selectStyle}
          {...register(`${formName}.month` as Path<T>)}
        >
          <option value=""></option>
          {options.months.map((month) => (
            <option key={month} value={month}>
              {month}
            </option>
          ))}
        </select>
        <span className={STSelectBox.selectbox}></span>月
        <select style={selectStyle} {...register(`${formName}.day` as Path<T>)}>
          <option value=""></option>
          {options.days.map((day) => (
            <option key={day} value={day}>
              {day.toString().padStart(2, "0")}
            </option>
          ))}
        </select>
        <span className={STSelectBox.selectbox}></span>日
      </div>
      {isErrors && <ErrorMessage name={formName} message={errorMessage} />}
    </>
  );
};

import { CheckSpFlag } from "../../../hooks/CheckSpFlag";
import STDualInputFrom from "../../../assets/styles/molecules/reactHookForms/dualInputForm.module.scss";
import {
  FieldErrors,
  FieldValues,
  Path,
  UseFormRegister,
} from "react-hook-form";
import ErrorMessage from "../../atoms/form/reactHookForms/ErrorMessage";
import Label from "../../atoms/form/reactHookForms/Label";
import OptionMessage from "./OptionMessage";
import InputField from "../../atoms/form/reactHookForms/InputField";

type Props<T extends FieldValues> = {
  label: {
    parent: string;
    children: {
      first: string;
      second: string;
    };
  };
  formName: {
    first: Path<T>;
    second: Path<T>;
  };
  register: UseFormRegister<T>;
  errors: FieldErrors<T>;
  placeholder: {
    first: string;
    second: string;
  };
  className?: string;
  required?: boolean;
  isReadOnly?: boolean;
  defaultValue?: string;
  optionInformation?: string;
};

const DualInputForm = <T extends FieldValues>(props: Props<T>) => {
  const {
    label,
    formName,
    register,
    errors,
    placeholder,
    className,
    required,
    isReadOnly,
    defaultValue,
    optionInformation,
  } = props;
  const isSp = CheckSpFlag();

  const pcLabel = `${label.parent}（${label.children.first}・${label.children.second}）`;
  const spFirstLabel = `${label.parent} （${label.children.first}）`;
  const spSecondLabel = `${label.parent}（${label.children.second}）`;

  const isFirstErrors = !!errors[formName.first];
  const firstErrorMessage = errors[formName.first]?.message as string;

  const isSecondErrors = !!errors[formName.second];
  const secondErrorMessage = errors[formName.second]?.message as string;

  return (
    <>
      {isSp ? (
        <>
          <div className={className}>
            {label &&
              <Label label={spFirstLabel} htmlFor={formName.first} required={required} />
            }
            {optionInformation !== undefined &&
              <OptionMessage information={optionInformation} />
            }
            <InputField<T>
              formName={formName.first}
              register={register}
              isErrors={isFirstErrors}
              placeholder={placeholder.first}
              defaultValue={defaultValue}
              isReadOnly={isReadOnly}
            />
            {isFirstErrors &&
              <ErrorMessage name={formName.first} message={firstErrorMessage} />
            }
          </div>
          <div className={className}>
            {label &&
              <Label label={spSecondLabel} htmlFor={formName.second} required={required} />
            }
            {optionInformation !== undefined &&
              <OptionMessage information={optionInformation} />
            }
            <InputField<T>
              formName={formName.second}
              register={register}
              isErrors={isSecondErrors}
              placeholder={placeholder.second}
              defaultValue={defaultValue}
              isReadOnly={isReadOnly}
            />
            {isSecondErrors &&
              <ErrorMessage name={formName.second} message={secondErrorMessage} />
            }
          </div>
        </>
      ) : (
        <>
          {label &&
            <Label label={pcLabel} htmlFor={formName.first} required={required} />
          }
          {optionInformation !== undefined &&
            <OptionMessage information={optionInformation} />
          }
          <div className={STDualInputFrom.pcLayout}>
            <div className={`${className} ${STDualInputFrom.pcLayout_first}`}>
              <InputField<T>
                formName={formName.first}
                register={register}
                isErrors={isFirstErrors}
                placeholder={placeholder.first}
                defaultValue={defaultValue}
                isReadOnly={isReadOnly}
              />
              {isFirstErrors &&
                <ErrorMessage name={formName.first} message={firstErrorMessage} />
              }
            </div>
            <div className={`${className}`}>
              <InputField<T>
                formName={formName.second}
                register={register}
                isErrors={isSecondErrors}
                placeholder={placeholder.second}
                defaultValue={defaultValue}
                isReadOnly={isReadOnly}
              />
              {isSecondErrors &&
                <ErrorMessage name={formName.second} message={secondErrorMessage} />
              }
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default DualInputForm;

import STIconButton from "../../assets/styles/atoms/iconButton.module.scss";

type Props = {
  className?: string;
  children: React.ReactNode;
  clickAction: () => void;
  disabled?: boolean;
};

const Button: React.VFC<Props> = (props: Props) => {
  return (
      <button
        className={`${STIconButton.icon_button_root} ${props.className}`}
        onClick={props.clickAction}
        disabled={props.disabled ? props.disabled : false}
      >
        {props.children}
      </button>
  );
};

export default Button;

import PAGE_CONFIG from "../../config/page";
import getImageUrl from "../../utils/getImageUrl";
import { getSubdomain } from "../../utils/getSubdomain";

type Props = {
  imageUrl: string | undefined;
  alt: string | undefined;
  className: string;
  shownDefault?: boolean;
};
const subdomain = getSubdomain(window.location.hostname);
const Image: React.FC<Props> = (props: Props) => {
  return props.shownDefault ? (
    <img
      src={props.imageUrl}
      alt={props.alt}
      className={props.className}
      onError={(e) =>
        (e.currentTarget.src = getImageUrl(
          subdomain,
          PAGE_CONFIG.IMAGE_URL_PATH.SITE,
          PAGE_CONFIG.SAMPLE_IMG
        ))
      }
    />
  ) : (
    <img src={props.imageUrl} alt={props.alt} className={props.className} />
  );
};

export default Image;

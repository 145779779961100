import styled from "styled-components";
import PAGE_CONFIG from "../../config/page";
import getImageUrl from "../../utils/getImageUrl";
import { getSubdomain } from "../../utils/getSubdomain";
import STTopBackground from "../../assets/styles/organisms/topBackground.module.scss";
import { useContext, useEffect, useState } from "react";
import { SiteContext } from "../../providers/SiteProvider";

const subdomain = getSubdomain(window.location.hostname);
const key = window.location.search.split('=')[1]?.startsWith('site') ? window.location.search.split('=')[1] : '';

const TopBackground: React.FC = () => {
  const site = useContext(SiteContext);
  const [topBgImageUrl, setTopBgImageUrl] = useState<string>("");
  const [topSpBgImageUrl, setTopSpBgImageUrl] = useState<string>("");

  useEffect(() => {
    const fetchImages = async () => {
      if (!key) {
        setTopBgImageUrl(getImageUrl(subdomain, PAGE_CONFIG.IMAGE_URL_PATH.SITE, PAGE_CONFIG.BACKGROUND_TOP));
        setTopSpBgImageUrl(getImageUrl(subdomain, PAGE_CONFIG.IMAGE_URL_PATH.SITE, PAGE_CONFIG.BACKGROUND_TOP_SP));
      } else {
        setTopBgImageUrl(site.backgroundPC);
        setTopSpBgImageUrl(site.backgroundSP);
      }
    };

    fetchImages();
  }, [key, site.backgroundPC, subdomain]);

  const Container = styled.div`
    @media only screen and (max-width: 799px) {
      background-image: url("${topSpBgImageUrl}"), url("${topSpBgImageUrl}");
    }
    background-image: url("${topBgImageUrl}");
  `;

  const ContainerOnlySPImage = styled.div`
    @media only screen and (max-width: 799px) {
      background-image: url("${topSpBgImageUrl}");
    }
    background-image: url("${topBgImageUrl}");
  `;

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = topSpBgImageUrl;
    img.onload = () => setLoaded(true);
    img.onerror = () => setLoaded(false);
  }, [topSpBgImageUrl]);

  return loaded ? (
    <ContainerOnlySPImage className={STTopBackground.top_background} />
  ) : (
    <Container className={STTopBackground.top_background} />
  );
};

export default TopBackground;

class TAppStaticInfoArticle {
  public title: string;
  public content: string;
  public uid?: string | undefined;

  constructor(title: string, content: string, uid?: string | undefined) {
    this.title = title;
    this.content = content;
    this.uid = uid;
  }
}

export default TAppStaticInfoArticle;

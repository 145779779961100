import client from "../../api/client";
import TApiResult from "../../types/api/TApiResult";
import { AxiosError } from "axios";
import getErrorProperty from "./config/getErrorProperty";
import { TEventDetailResponse } from "../../api/events/_id@number";

export type TApiEventDetailResult = {
  result?: TEventDetailResponse;
};

export const fetchEventDetail = async (
  id: number
): Promise<TApiResult & TApiEventDetailResult> => {
  return client()
    .events._id(id)
    .get()
    .then((res) => {
      return {
        isSuccess: true,
        status: 200,
        message: "",
        result: res.body,
      };
    })
    .catch((e: AxiosError) => {
      const { status, message } = getErrorProperty(e, "fetchEventDetail");
      return {
        isSuccess: false,
        status: status,
        message: message,
      };
    });
};

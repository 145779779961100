const setYears = () => {
  const years = [];
  for (let i = 1900; i <= new Date().getFullYear(); i++) {
    years.push(i);
  }
  return years.sort((a, b) => b - a);
};

const setMonths = () => {
  const months = [];
  for (let i = 1; i <= 12; i++) {
    months.push(i);
  }
  return months;
};

const setDays = () => {
  const days = [];
  for (let i = 1; i <= 31; i++) {
    days.push(i);
  }
  return days;
};

export const years = setYears();
export const months = setMonths();
export const days = setDays();

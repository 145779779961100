import TAppStaticInfoArticle from "../../types/TAppStaticInfoArticle";
import { useEffect, useState } from "react";
import "../../resources/CKEEditor.css";
import { useHistory } from "react-router-dom";
import { routes } from "../../router/Router";
import StaticInfoArticleCard from "../molecules/StaticInfoArticleCard";
import { fetchContentPreviewDetail } from "../../utils/api/fetchContentPreviewDetail";
import { getAuth } from "firebase/auth";

type Props = {
  previewKey: string;
};

const StaticInfoPreviewCard: React.FC<Props> = ({ previewKey }: Props) => {
  const [infoArticle, setInfoArticle] = useState<TAppStaticInfoArticle>();
  const history = useHistory();
  const auth = getAuth();
  useEffect(() => {
    fetchContentPreviewDetail(previewKey).then((res) => {
      if (!res.isSuccess || !res.result) return history.push(routes.error.path);
      const newInfoArticle = new TAppStaticInfoArticle(
        res.result.title,
        res.result.content,
        auth.currentUser?.uid
      );
      setInfoArticle(newInfoArticle);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previewKey, history]);

  return infoArticle ? (
    <StaticInfoArticleCard
      title={infoArticle.title}
      content={infoArticle.content}
      uid={infoArticle.uid}
    />
  ) : (
    <></>
  );
};

export default StaticInfoPreviewCard;

import { useMemo, useEffect, useState, useContext } from "react";
import { ScratchSetting, PlayedScratch } from "../types/popup";
import axios from "axios";
import { fetchBannerSettings } from "../utils/api/fetchBannerSettings";
import { TBannerSettingResponse } from "../api/banner_setting";
import { TPopupSettingResponse } from "../api/popup_setting";
import { fetchPopupSettings } from "../utils/api/fetchPopupSettings";
import { getAuth } from "firebase/auth";
import { isToday, isBefore, isAfter, isEqual } from "date-fns";

import useModal from "../hooks/useModal";
import { SiteContext } from "../providers/SiteProvider";

//ポップアップの状態、表示対象を管理するカスタムフック
const useAutomaticPopup = () => {
  const [popupSetting, setPopupSetting] = useState<TPopupSettingResponse>();
  const [isLoadingPopupSetting, setIsLoadingPopupSetting] =
    useState<boolean>(true);
  const [errorPopupSetting, setErrorPopupSetting] = useState<boolean>(false);
  const [scratchSetting, setScratchSetting] = useState<ScratchSetting>({
    id: 0,
    siteId: 0,
    type: 0,
    startDate: undefined,
    endDate: undefined,
    title: "",
    primaryColor: "",
    secondaryColor: "",
    catchCopy: "",
    description: "",
    extra: "",
    isPublic: false,
  });
  const [isLoadingScratchSetting, setIsLoadingScratchSetting] =
    useState<boolean>(true);
  const [errorScratchSetting, setErrorScratchSetting] =
    useState<boolean>(false);
  const [bannerSetting, setBannerSetting] = useState<TBannerSettingResponse>();
  const [isLoadingBannerSetting, setIsLoadingBannerSetting] =
    useState<boolean>(true);
  const [errorBannerSetting, setErrorBannerSetting] = useState<boolean>(false);
  const [playedScratch, setPlayedScratch] = useState<PlayedScratch>();
  const [isLoadingPlayedScratch, setIsLoadingPlayedScratch] =
    useState<boolean>(true);
  const [errorPlayedScratch, setErrorPlayedScratch] = useState<boolean>(false);
  const site = useContext(SiteContext);

  const auth = getAuth();

  useEffect(() => {
    const fetchPopupSetting = async () => {
      try {
        setIsLoadingPopupSetting(true);
        const response = await fetchPopupSettings();
        if (!response.isSuccess || !response.result) {
          throw new Error("ポップアップ情報の取得に失敗しました。");
        }
        setPopupSetting(response.result);
      } catch (error) {
        setErrorPopupSetting(true);
        console.log(error);
      } finally {
        setIsLoadingPopupSetting(false);
      }
    };
    fetchPopupSetting();
  }, []);

  useEffect(() => {
    const playedScratch = async () => {
      const token = await auth.currentUser?.getIdToken();
      if (!scratchSetting) {
        setIsLoadingPlayedScratch(false);
        return;
      }
      try {
        setIsLoadingPlayedScratch(true);
        const playedScratchResponse = await axios.get(
          `${process.env.REACT_APP_GAMIFICATIONS_API_ENDPOINT}/${scratchSetting.id}/played`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        setPlayedScratch(playedScratchResponse.data);
      } catch (error) {
        setErrorPlayedScratch(true);
        console.log(error);
      } finally {
        setIsLoadingPlayedScratch(false);
      }
    };
    playedScratch();
  }, [scratchSetting, auth.currentUser]);

  useEffect(() => {
    const getBanner = async (displayRefId: number) => {
      //ポップアップ表示対象のバナーを取得
      try {
        setIsLoadingBannerSetting(true);
        const response = await fetchBannerSettings();
        if (!response.isSuccess || !response.result) {
          throw new Error("バナー情報の取得に失敗しました。");
        }
        const targetBanner = response.result.find((newBenefitItem) => {
          return newBenefitItem.location_id === displayRefId;
        });
        setBannerSetting(targetBanner);
      } catch (error) {
        setErrorBannerSetting(true);
        console.log(error);
      } finally {
        setIsLoadingBannerSetting(false);
      }
    };

    const getScratch = async (siteId: number, displayType: number) => {
      //スクラッチ情報取得
      try {
        setIsLoadingScratchSetting(true);
        const scratchSettingResponse = await axios.get(
          `${process.env.REACT_APP_GAMIFICATIONS_API_ENDPOINT}?siteId=${siteId}&type=1`
        );
        setScratchSetting(scratchSettingResponse.data);
      } catch (error) {
        console.log(error);
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 404) {
            // 404の場合はスクラッチ設定がないのでエラーを出さない
            setErrorScratchSetting(false);
          } else {
            setErrorScratchSetting(true);
          }
        } else {
          setErrorScratchSetting(true);
        }
      } finally {
        setIsLoadingScratchSetting(false);
      }
    };

    if (isLoadingPopupSetting || errorPopupSetting || !popupSetting) return;

    const {
      display_ref_id: displayRefId,
      display_ref_id_new_ui: displayRefIdNewUi,
      site_id: siteId,
      display_type: displayType,
    } = popupSetting;

    site.newUIVersion ? getBanner(displayRefIdNewUi) : getBanner(displayRefId);
    getScratch(siteId, displayType);
  }, [isLoadingPopupSetting, errorPopupSetting, popupSetting]);

  const { isOpenClickScratchBanner } = useModal(false);
  const oneDateInvisible = localStorage.getItem("oneDateInvisible");
  const permanentInvisible = localStorage.getItem("permanentInvisible");

  const isLoading = useMemo(() => {
    // isLoadingPopupSetting, isLoadingScratchSetting, isLoadingBannerSetting, isLoadingPlayedScratchのいずれかがtrueの場合はtrue
    if (
      isLoadingPopupSetting ||
      isLoadingScratchSetting ||
      isLoadingBannerSetting ||
      isLoadingPlayedScratch
    ) {
      return true;
    }
    return false;
  }, [
    isLoadingPopupSetting,
    isLoadingScratchSetting,
    isLoadingBannerSetting,
    isLoadingPlayedScratch,
  ]);

  const error = useMemo(() => {
    // errorPopupSetting, errorScratchSetting, errorBannerSetting, errorPlayedScratchのいずれかがtrueの場合はtrue
    if (
      errorPopupSetting ||
      errorScratchSetting ||
      errorBannerSetting ||
      errorPlayedScratch
    ) {
      return true;
    }
    return false;
  }, [
    errorPopupSetting,
    errorScratchSetting,
    errorBannerSetting,
    errorPlayedScratch,
  ]);

  /**
   * ポップアップ表示条件に合致しているか確認
   *
   */
  const shouldDisplayWhenLoaded = useMemo(() => {
    if (!popupSetting) {
      return false;
    }
    if (!popupSetting.is_public) {
      return false;
    }

    const displayStartDate = new Date(popupSetting.display_start_date);
    const displayEndDate = new Date(popupSetting.display_end_date);
    const today = new Date();

    // 表示対象期間外の場合ポップアップ非表示
    if (isBefore(today, displayStartDate) || isAfter(today, displayEndDate)) {
      return false;
    }

    //データに変更がない限り永久非表示
    if (permanentInvisible) {
      const permanentInvisibleJsonParse = JSON.parse(permanentInvisible);
      if (popupSetting) {
        if (
          isEqual(
            new Date(popupSetting?.updated_at),
            new Date(permanentInvisibleJsonParse.savePopupUpdatedAt)
          )
        ) {
          return false;
        } else {
          localStorage.removeItem("permanentInvisible");
          localStorage.removeItem("oneDateInvisible");
          return true;
        }
      }
    }

    if (oneDateInvisible) {
      //24時間非表示
      //falseの場合データを削除
      const oneDateInvisibleJsonParse = JSON.parse(oneDateInvisible);
      //表示開始日、終了日、表示対象が一つでも一致しない場合は再表示
      if (
        !isEqual(
          new Date(popupSetting?.display_start_date),
          new Date(oneDateInvisibleJsonParse.popupStartDate)
        ) ||
        !isEqual(
          new Date(popupSetting?.display_end_date),
          new Date(oneDateInvisibleJsonParse.popupEndDate)
        ) ||
        popupSetting.display_ref_id !==
          oneDateInvisibleJsonParse.popupDisplayRefId
      ) {
        localStorage.removeItem("permanentInvisible");
        localStorage.removeItem("oneDateInvisible");
        return true;
      }
      //ローカルストレージに保存した日付が現在日の場合はtrue
      if (isToday(new Date(oneDateInvisibleJsonParse.saveDate))) {
        return false;
      } else {
        localStorage.removeItem("oneDateInvisible");
      }
    }

    return true;
  }, [oneDateInvisible, permanentInvisible, popupSetting]);

  return {
    shouldDisplayWhenLoaded,
    scratchSetting,
    setScratchSetting,
    bannerSetting,
    popupSetting,
    playedScratch,
    isOpenClickScratchBanner,
    isLoading,
    error,
  };
};
export default useAutomaticPopup;

import * as React from "react";
import { SVGProps } from "react";
import { SiteContext } from "../../../providers/SiteProvider";

const SvgHamburgerMenu = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={22}
      height={22}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.644 16.477H18.31m-14.666-11H18.31 3.644Zm0 5.5H18.31 3.644Z"
        stroke='var(--main-color)'
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SvgHamburgerMenu;

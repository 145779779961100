import { FieldValues, Path, UseFormRegister } from "react-hook-form";
import STForm from "../../../../assets/styles/atoms/form/reactHookForms/form.module.scss";

type Props<T extends FieldValues> = {
  formName: Path<T>;
  register: UseFormRegister<T>;
  placeholder: string;
  isErrors: boolean;
  type?: "text" | "password";
  defaultValue?: string;
  isReadOnly?: boolean;
  onChange?: () => void;
}

const InputField = <T extends FieldValues>(props: Props<T>) => {
  const {
    formName,
    register,
    isErrors,
    placeholder,
    type = "text",
    defaultValue,
    isReadOnly,
    onChange,
  } = props;

  return (
    <>
    {!isReadOnly ? (
      <input
        type={type}
        id={formName}
        {...register(formName, {
          onChange
        })}
        className={!isErrors ? STForm.form : STForm.error}
        placeholder={placeholder}
        defaultValue={defaultValue}
        readOnly={isReadOnly}
        data-testid={`entry-form_${formName}`}
      />
    ) : (
      <input
        type={type}
        id={formName}
        {...register(formName, {
          onChange
        })}
        className={STForm.readOnly}
        placeholder={placeholder}
        defaultValue={defaultValue}
        readOnly={isReadOnly}
        data-testid={`entry-form_${formName}`}
      />
    )}
    </>
  );
};

export default InputField;

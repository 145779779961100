import { FieldErrors, FieldValues, Path, UseFormRegister } from "react-hook-form";
import STPassword from "../../../assets/styles/atoms/form/reactHookForms/password.module.scss";
import { useState } from "react";
import Label from "../../atoms/form/reactHookForms/Label";
import InputField from "../../atoms/form/reactHookForms/InputField";
import ErrorMessage from "../../atoms/form/reactHookForms/ErrorMessage";
import OptionMessage from "./OptionMessage";

type Props<T extends FieldValues> = {
  label: string;
  formName: Path<T>;
  register: UseFormRegister<T>;
  errors: FieldErrors<T>;
  placeholder: string;
  required?: boolean;
  optionInformation?: string;
  onChange?: () => void;
}

const PassowordForm = <T extends FieldValues>(props: Props<T>) => {
  const {
    label,
    formName,
    register,
    errors,
    placeholder,
    required = false,
    optionInformation,
    onChange,
  } = props;

  const [isShown, setIsShown] = useState<boolean>(false);

  const isErrors = !!errors[formName];
  const errorMessage = errors[formName]?.message as string;

  return (
    <div className={STPassword.container}>
      <Label label={label} htmlFor={formName} required={required} />
      {optionInformation !== undefined &&
        <OptionMessage information={optionInformation} />
      }
      <InputField
        type={isShown ? "text" : "password"}
        formName={formName}
        register={register}
        isErrors={isErrors}
        placeholder={placeholder}
        onChange={onChange}
      />
      <div className={STPassword.icon_conatainer}>
        <i
          className={
            isShown
            ? `${STPassword.icon} fa fa-eye`
            : `${STPassword.icon} fa fa-eye-slash`
          }
          onClick={() => setIsShown(!isShown)}
        />
        {isErrors &&
          <ErrorMessage name={formName} message={errorMessage} />
        }
      </div>
    </div>
  )
}

export default PassowordForm;

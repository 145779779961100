import { SVGProps } from "react";

const SvgArrowRightMenuHeader = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <mask id="mask0_11_1187"  maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
            <rect width="16" height="16" fill="#D9D9D9"/>
        </mask>
        <g mask="url(#mask0_11_1187)">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M4.89052 1.88076C5.41122 1.37308 6.25544 1.37308 6.77614 1.88076L12.1095 7.08076C12.6302 7.58844 12.6302 8.41156 12.1095 8.91924L6.77614 14.1192C6.25544 14.6269 5.41122 14.6269 4.89052 14.1192C4.36983 13.6116 4.36983 12.7884 4.89052 12.2808L9.28105 8L4.89052 3.71924C4.36983 3.21156 4.36983 2.38844 4.89052 1.88076Z" fill="var(--main-color)"/>
        </g>
    </svg>
);

export default SvgArrowRightMenuHeader;

class TPager {
  static readonly LIMIT: number = 20;
  public limit: number;
  public current?: number;
  public max: number;
  public total: number;

  constructor(current?: number, total?: number, limit?: number) {
    this.limit = limit ? limit : TPager.LIMIT
    this.current = current;
    this.max = total
      ? total !== 0
        ? Math.ceil(Number(total / this.limit))
        : 1
      : 0;
    this.total = total || 0;
  }

  offset(): number | undefined {
    return this.current && (this.current - 1) * this.limit;
  }
}

export default TPager;

import { Link } from "react-router-dom";
import Image from "../atoms/Image";

type Props = {
  linkUrl: string;
  imageUrl: string;
  className: string;
  alt?: string;
};

const ImageLink: React.FC<Props> = (props: Props) => {
  const linkPattern = /^http.+/;
  return (
    <>
      {!linkPattern.test(props.linkUrl) ? (
        <Link to={props.linkUrl}>
          <Image
            imageUrl={props.imageUrl}
            alt={props.alt ? props.alt : ""}
            className={props.className}
          />
        </Link>
      ):(
        <a href={props.linkUrl}>
          <Image
            imageUrl={props.imageUrl}
            alt={props.alt ? props.alt : ""}
            className={props.className}
          />
        </a>
      )}
    </>
  );
};
export default ImageLink;

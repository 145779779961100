import * as React from "react";
import { SVGProps } from "react";

const Information = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width}
    height={props.height}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={props.className}
  >
    <path
      d="M5.41663 8.91667H6.58329V5.41667H5.41663V8.91667ZM5.99996 4.25C6.16524 4.25 6.30388 4.194 6.41588 4.082C6.52788 3.97 6.58368 3.83156 6.58329 3.66667C6.58329 3.50139 6.52729 3.36275 6.41529 3.25075C6.30329 3.13875 6.16485 3.08295 5.99996 3.08333C5.83468 3.08333 5.69604 3.13933 5.58404 3.25133C5.47204 3.36333 5.41624 3.50178 5.41663 3.66667C5.41663 3.83195 5.47263 3.97058 5.58463 4.08258C5.69663 4.19458 5.83507 4.25039 5.99996 4.25ZM5.99996 11.8333C5.19301 11.8333 4.43468 11.6801 3.72496 11.3737C3.01524 11.0672 2.39788 10.6517 1.87288 10.1271C1.34788 9.60209 0.932348 8.98472 0.626293 8.275C0.320237 7.56528 0.167015 6.80695 0.166626 6C0.166626 5.19306 0.319848 4.43472 0.626293 3.725C0.932737 3.01528 1.34826 2.39792 1.87288 1.87292C2.39788 1.34792 3.01524 0.93239 3.72496 0.626335C4.43468 0.320279 5.19301 0.167057 5.99996 0.166668C6.8069 0.166668 7.56524 0.31989 8.27496 0.626335C8.98468 0.932779 9.60204 1.34831 10.127 1.87292C10.652 2.39792 11.0678 3.01528 11.3742 3.725C11.6807 4.43472 11.8337 5.19306 11.8333 6C11.8333 6.80695 11.6801 7.56528 11.3736 8.275C11.0672 8.98472 10.6517 9.60209 10.127 10.1271C9.60204 10.6521 8.98468 11.0678 8.27496 11.3743C7.56524 11.6807 6.8069 11.8337 5.99996 11.8333ZM5.99996 10.6667C7.30274 10.6667 8.40621 10.2146 9.31038 9.31042C10.2145 8.40625 10.6666 7.30278 10.6666 6C10.6666 4.69722 10.2145 3.59375 9.31038 2.68958C8.40621 1.78542 7.30274 1.33333 5.99996 1.33333C4.69718 1.33333 3.59371 1.78542 2.68954 2.68958C1.78538 3.59375 1.33329 4.69722 1.33329 6C1.33329 7.30278 1.78538 8.40625 2.68954 9.31042C3.59371 10.2146 4.69718 10.6667 5.99996 10.6667Z"
      fill="#396D5A"
    />
  </svg>
);

export default Information;

type Props = {
  className: string;
  id?: string;
  value: string;
  checked: boolean;
  onChange: (checked: string) => void;
};

const AppCheckBox: React.FC<Props> = (props: Props) => {
  const hundleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = props.checked ? "" : props.value;
    props.onChange(newValue);
  };

  return (
    <input
      id={props.id}
      type="checkbox"
      className={`${props.className}`}
      value={props.checked ? props.value : ""}
      checked={props.checked}
      onChange={hundleChange}
    />
  );
};

export default AppCheckBox;

import STLabel from "../../../../assets/styles/atoms/form/reactHookForms/label.module.scss"

type Props = {
  label: string;
  htmlFor: string;
  required?: boolean;
}

const Label = (props: Props) => {
  const { label, htmlFor, required } = props;

  return (
    <label className={STLabel.label} htmlFor={htmlFor}>
      {label}
      {required && <span className={STLabel.required}>必須</span>}
    </label>
  );
}

export default Label;

import React from 'react';
import STMenuList from "../../assets/styles/organisms/menuList.module.scss";

type Props = {
    id: number;
    siteId: number;
    locationId: number;
    imgUrl: string;
    imgType: number;
    linkUrl: string;
    linkName: string;
    description: string | undefined;
}


const MenuItem: React.FC<Props> = (props: Props) => {
  return (
    <a href={props.linkUrl} className={STMenuList.menu_item_link} >
        <div className={STMenuList.menu_item}>
            <div>
                <img className={STMenuList.menu_item_img} src={props.imgUrl}></img>
            </div>
        </div>
        <div className={STMenuList.link_name}>
          <h4>{props.linkName}</h4>
        </div>
    </a>
  )
}

export default MenuItem

import {
  createContext,
  ReactNode,
  useEffect,
  useState,
} from "react";
import { getSubdomain } from "../utils/getSubdomain";
import { fetchSite, TApiSiteResult } from "../utils/api/fetchSite";
import TApiResult from "../types/api/TApiResult";
import setManifestURL from "../utils/generageManifest";
import setFaviconURL from "../utils/setFaviconURL";
import { Helmet } from "react-helmet";
import getImageUrl from "../utils/getImageUrl";
import PAGE_CONFIG from "../config/page";
import { fetchSitePreview } from "../utils/api/fetchSitePreview";
import { useHistory } from "react-router-dom";
import { routes } from "../router/Router";

export type SiteContextType = {
  name: string;
  isPoint: boolean;
  isPublic: boolean;
  tenantId: string;
  googleAnalyticsId?: string;
  enablesHomePopup: boolean;
  homePopupDescription?: string;
  newUIVersion: boolean;
  backgroundColor: string;
  mainColor: string;
  backgroundPC: string;
  backgroundSP: string;
  logo: string;
  favicoPC: string;
  favicoIOS: string;
};

export const SiteContext = createContext({} as SiteContextType);

export const SiteProvider = (props: { children: ReactNode }) => {
  const history = useHistory();
  const { children } = props;
  const [tenantId, setTenantId] = useState<string>();
  const [isPoint, setIsPoint] = useState(false);
  const [isPublic, setIsPublic] = useState(false);
  const subdomain = getSubdomain(window.location.hostname);
  const [name, setName] = useState<string>("");
  const [googleAnalyticsId, setGoogleAnalyticsId] = useState<string>();
  const [enablesHomePopup, setEnablesHomePopup] = useState<boolean>(false);
  const [homePopupDescription, setHomePopupDescription] = useState<string>();
  const [newUIVersion, setNewUIVersion] = useState<boolean>(true);
  const [backgroundColor, setBackgroundColor] = useState<string>('');
  const [mainColor, setMainColor] = useState<string>('');
  const [backgroundPC, setBackgroundPC] = useState<string>('');
  const [backgroundSP, setBackgroundSP] = useState<string>('');
  const [logo, setLogo] = useState<string>('');
  const [favicoPC, setFaviconPC] = useState<string>('');
  const [favicoIOS, setFaviconIOS] = useState<string>('');
  const key = window.location.search.split('=')[1]?.startsWith('site') ? window.location.search.split('=')[1] : '';
  const HeadForFavicons = () => (
    <Helmet>
      <link
        rel="icon"
        href={!key ? 
          getImageUrl(
            subdomain,
            PAGE_CONFIG.IMAGE_URL_PATH.SITE,
            PAGE_CONFIG.FAVICON
          )
          :
          favicoPC ?? ''
        }
      />
      <link
        rel="apple-touch-icon"
        href={!key ?
          getImageUrl(
            subdomain,
            PAGE_CONFIG.IMAGE_URL_PATH.SITE,
            PAGE_CONFIG.APPLE_TOUCH_ICON
          )
          :
          favicoIOS ?? ''
        }
      />
    </Helmet>
  );

  useEffect(() => {
    const handleData = async () => {
      if(key){
        await fetchSitePreview(key).then((res : any) => {
          if (!res.isSuccess || !res.result) return history.push(routes.error.path);
          setNewUIVersion(res.result?.current_version ? true : false);
          setBackgroundColor(res.result?.sub_color);
          setMainColor(res.result?.main_color);
          setBackgroundPC(res.result?.background_pc?.length > 300 ? `data:image/png;base64,${res.result.background_pc}` : res.result?.background_pc);
          setBackgroundSP(res.result?.background_sp?.length > 300 ? `data:image/png;base64,${res.result.background_sp}` : res.result?.background_sp);
          setLogo(res.result?.logo?.length > 300 ? `data:image/png;base64,${res.result.logo}` : res.result?.logo);
          setFaviconPC(res.result?.favico_pc?.length > 300 ? `data:image/png;base64,${res.result.favico_pc}` : res.result?.favico_pc);
          setFaviconIOS(res.result?.favico_sp?.length > 300 ? `data:image/png;base64,${res.result.favico_ios}` : res.result?.favico_ios);
          document.documentElement.style.setProperty("--main-color", res.result.main_color);
          document.documentElement.style.setProperty("--sub-color", res.result.sub_color);
        })
      }
      fetchSite().then((res: TApiResult & TApiSiteResult) => {
        if (!res.isSuccess || !res.result) return;
        setName(res.result.name);
        setTenantId(res.result.gcp_id);
        setManifestURL(res.result.name, subdomain);
        setIsPoint(res.result.is_point === 1);
        setIsPublic(res.result.is_public);
        setGoogleAnalyticsId(res.result.google_analytics_id);
        setEnablesHomePopup(res.result.enables_home_popup);
        if(!key){
          setNewUIVersion(res.result.ui_version ? true : false);
          setBackgroundColor(res.result.sub_color);
          setMainColor(res.result.main_color);
          document.documentElement.style.setProperty("--main-color", res.result.main_color);
          document.documentElement.style.setProperty("--sub-color", res.result.sub_color);
        }
        const defaultDescription =
          "ホーム画面への追加はお済みですか？ \n簡単10秒設定で↓↓↓  \nさらに便利に活用いただけます！";
        const homePopupDescription =
          res.result.home_popup_description ?? defaultDescription;
        setHomePopupDescription(homePopupDescription);
      });
    }
    handleData();
  }, [subdomain, tenantId]);

  if (subdomain === "") return <>subdomain is empty</>;
  if (!tenantId)
    return (
      <>
        <HeadForFavicons />
      </>
    );

  return (
    <SiteContext.Provider
      value={{
        name,
        isPoint,
        isPublic,
        tenantId,
        googleAnalyticsId,
        enablesHomePopup,
        homePopupDescription,
        newUIVersion, 
        backgroundColor,
        mainColor,
        backgroundPC,
        backgroundSP,
        logo,
        favicoPC,
        favicoIOS
      }}
    >
      <HeadForFavicons />
      {children}
    </SiteContext.Provider>
  );
};

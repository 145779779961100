// NOTE: https://qiita.com/saba_can00/items/bdefb28a1873658cf5d9
export const MEMBER_EDIT_FORM_ITEM = {
  name: "name",
  nameKana: "name_kana",
  tel: "tel",
  email: "email",
  birthday: "birthday",
  instagramOwnerinput: "instagram_ownerinput",
  line: "line",
  existPassword: "exist_password",
  newPassword: "new_password",
  newPasswordConfirm: "new_password_confirm",
  mail_not_recommended: "mail_not_recommended",
  line_not_recommended: "line_not_recommended",
} as const;

type TypeOfMemberEditFormItem = typeof MEMBER_EDIT_FORM_ITEM;
export type TAppMemberEditFormItem =
  TypeOfMemberEditFormItem[keyof TypeOfMemberEditFormItem];

import { TAppFormError } from "../../../types/TAppFormError";
import TableRow from "../../atoms/TableRow";
import STSingleTextAreaRow from "../../../assets/styles/molecules/formTableRow/singleTextAreaRow.module.scss";
import TextArea from "../../atoms/form/TextArea";
import AlartMessages from "../../atoms/AlartMessages";

type Props<T> = {
  title: string;
  subTitle?: string;
  placeholder?: string;
  defaultValue?: string;
  required?: boolean;
  formKey: T;
  col?: number;
  row?: number;
  errors: TAppFormError<T>[];
  hundleOnChange: (value: string) => void;
  isDisplay?: boolean;
  canChange?: boolean;
};

const SingleTextAreaRow = <T,>({
  title,
  subTitle,
  placeholder,
  defaultValue,
  required = false,
  formKey,
  col = 30,
  row = 5,
  errors,
  hundleOnChange,
  isDisplay = true,
  canChange = true,
}: Props<T>) => {
  if (!isDisplay) return <></>;
  return (
    <TableRow title={title} subTitle={subTitle} required={required}>
      <span>
        <TextArea
          rows={row}
          cols={col}
          defaultValue={defaultValue}
          placeholder={placeholder}
          className={
            canChange ? STSingleTextAreaRow.form : STSingleTextAreaRow.disable
          }
          name={title}
          onChange={(input) => hundleOnChange(input)}
        />
      </span>
      {AlartMessages<T>({
        title: title,
        errors: errors,
        keys: [formKey],
      })}
    </TableRow>
  );
};

export default SingleTextAreaRow;

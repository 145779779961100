
import { Box, Link } from "@material-ui/core"
import Image from "../atoms/Image";
import CloseIcon from "../../components/atoms/Icon"
import styled, { keyframes } from 'styled-components';
import ReactMarkdown from 'react-markdown';
import { ScratchSetting, PlayedScratch, PlayingScratch } from "../../types/popup";
import { TPopupSettingResponse } from "../../api/popup_setting";
import { useEffect, useState } from 'react'
import useModal from "../../hooks/useModal";
import { getContrastTextColor } from '../../utils/getContrastTextColor'
import STPopupScratch from '../../assets/styles/organisms/popupScratch.module.scss'
import axios from "axios";
import { getAuth } from "firebase/auth";
import Ribbon from "../Ribbon";
import { useMultipleClickPreventer } from "../molecules/UseMultipleClickPreventer";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
const FadeIn = styled.div`
  animation: ${fadeIn} 2s ease-in-out;//0.５秒から2秒に修正
`;
type Props = {
  popupSetting: TPopupSettingResponse,
  scratchRes: ScratchSetting,
  playedScratch: PlayedScratch | undefined,
  saveInvisibleDate: () => void,
  handleClosePopupOrBanner: () => void,
  updateInvisibleUpdatedAt: () => void,
}

const PopupScratch = ({
  popupSetting,
  scratchRes,
  playedScratch,
  handleClosePopupOrBanner,
  updateInvisibleUpdatedAt,
  saveInvisibleDate,
}: Props) => {
  const [playScratchHistory, setPlayScratchHistory] = useState<PlayedScratch>();
  const [playingScratch, setPlayingScratch] = useState<PlayingScratch>()
  const { handlePlayedScratch, } = useModal(false)
  const changeColorATag = {
    a: ({ ...props }) => {
      return <a {...props} className={STPopupScratch.linkStyle} rel="noopener noreferrer">{props.children}</a>
    }
  };
  useEffect(() => {
    const playedScratch = async () => {
      const auth = getAuth();
      const token = await auth.currentUser?.getIdToken();
      if (!scratchRes) return;
      try {
        const playedScratchResponse = await axios.get(
          `${process.env.REACT_APP_GAMIFICATIONS_API_ENDPOINT}/${scratchRes.id}/played`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        setPlayScratchHistory(playedScratchResponse.data);
      } catch (error) {
        console.log(error);
      }
    };
    playedScratch();
  }, [scratchRes])

  
  /**
   * スクラッチを行なった時にプレイ履歴を作成
   * ローカルストレージにプレイ日付を保存
   * 
   */
  const handleCompleteScratchAction = useMultipleClickPreventer(async () => {
        const res = await handlePlayedScratch(scratchRes.id);
        setPlayingScratch(res.data);
        // 24時間非表示
        saveInvisibleDate();
  });

  /**
   * 閉じるボタン押下時にpopupを閉じる。
   * ×ボタンで閉じる→popupを24時間（その日中）非表示
   */
  const handleClosePopupAndSaveUpdatedAt = () => {
    handleClosePopupOrBanner()
    saveInvisibleDate()
  }
  const updateFontSizeByLength = (title: string | undefined): number => {
    if (title === undefined) {
      return 0;
    }
    switch (title.replace(/\r?\n/g, '').length) {
      case 19:
      case 20:
        return 20;
      case 21:
      case 22:
        return 18;
      case 23:
      case 24:
      case 25:
      case 26:
        return 17;
      case 27:
      case 28:
      case 29:
      case 30:
        return 16;
      default:
        return 21;
    }
  };
  const CompletedScratchPopupBody = () => {
    return (
      <Box className={STPopupScratch.scratchBody}>
        <FadeIn>
          <Box mt={2} mb={4} mr={'auto'} ml={'auto'} textAlign={'center'} width={'180px'} height={'180px'}>
            <Box position={'relative'}>
              <Image imageUrl={"/scratch_complete.png"} alt={'ゲットポイント画像'} className="" shownDefault={true} />
              <Box
                position={'absolute'}
                top={'50%'} left={'50%'}
                style={{ transform: 'translate(-50%, -50%)' }}>
                <p style={{ fontSize: '40px', color: '#715114', fontWeight: '700' }}>{playingScratch?.pointAmount ? playingScratch?.pointAmount : playScratchHistory?.pointAmount}</p>
                <p style={{ fontSize: '20px', color: '#715114', fontWeight: '700' }}>ポイント</p>
              </Box>
            </Box>
          </Box>
          <Box textAlign={'center'}>
            <Box fontSize={'16px'}
              fontWeight={'700'}
              whiteSpace={'pre-wrap'}
              mb={2}>
              おめでとうございます！
              {playingScratch?.pointAmount ?? playScratchHistory?.pointAmount}ポイント獲得しました!
            </Box>
            {scratchRes.extra && <Box>
              もっとポイントを貯めるには
              <Link href={scratchRes.extra} target="_blank" rel="noopener noreferrer" className={STPopupScratch.linkStyle}>こちら</Link>
            </Box>}
          </Box>
        </FadeIn>
      </Box>
    );
  }
  return (
    <Box>
      <Ribbon catchCopy={scratchRes?.catchCopy} ribbonColor={scratchRes?.primaryColor} fontColor={getContrastTextColor(scratchRes?.primaryColor)} />
      <Box onClick={handleClosePopupAndSaveUpdatedAt}>
        <CloseIcon src={'/close_icon.svg'} alt="閉じるアイコン" className={STPopupScratch.closeIcon} />
      </Box>
      {scratchRes?.title && <Box className={STPopupScratch.titleContainer}>
        <Box className={STPopupScratch.title} fontSize={updateFontSizeByLength(scratchRes.title) + 'px'}>{scratchRes.title}</Box>
      </Box>}
      {
        playScratchHistory?.played || playingScratch !== undefined ? <CompletedScratchPopupBody /> :
          <Box className={STPopupScratch.scratchBody}>
            <Box onClick={handleCompleteScratchAction} mt={2} mb={4} mr={'auto'} ml={'auto'} textAlign={'center'} width={'180px'} height={'180px'} style={{ cursor: 'pointer' }}>
              <Image imageUrl={"/scratch.png"} alt={'スクラッチ画像'} className={STPopupScratch.scratchPng} shownDefault={true} />
            </Box>
            <Box>
              <Box maxWidth={'376px'} fontSize={'14px'} textAlign={'center'} whiteSpace={'pre-wrap'}>
                <ReactMarkdown components={changeColorATag} linkTarget={"_blank"}>{scratchRes?.description ?? ""}</ReactMarkdown>
              </Box>
            </Box>
          </Box>
      }
    </Box >
  )
}
export default PopupScratch
import { AxiosError } from "axios";
import { getAuth } from "firebase/auth";
import client from "../../api/client";
import { TMemberLinkUserLineAccountResponse } from "../../api/member/link_user_line_account";
import TApiResult from "../../types/api/TApiResult";
import getErrorProperty from "./config/getErrorProperty";
import tokenNotFoundWithValidateErrors from "./config/tokenNotFoundWithValidateErrors";

export type TApitMemberLinkUserLineAccountResult = {
  result?: TMemberLinkUserLineAccountResponse;
};

export const postMemberLinkUserLineAccount = async (params: {
  linkToken: string;
}): Promise<TApiResult & TApitMemberLinkUserLineAccountResult> => {
  const auth = getAuth();
  const token = await auth.currentUser?.getIdToken();
  if (!token) return tokenNotFoundWithValidateErrors;

  return await client()
    .member.link_user_line_account.post({
      body: {
        link_token: params.linkToken,
      },
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      return {
        isSuccess: true,
        status: 200,
        message: "",
        result: res.body,
      };
    })
    .catch((e: AxiosError) => {
      const { status, message } = getErrorProperty(e, "postMemberLinkUserLineAccount");
      return {
        isSuccess: false,
        status: status,
        message: message,
      };
    });
};

import client from "../../api/client";
import TApiResult from "../../types/api/TApiResult";
import { AxiosError } from "axios";
import getErrorProperty from "./config/getErrorProperty";
import { TReservationSettingResponse } from "../../api/reservation_settings";

export type TApiReservationSettingDetailResult = {
  result?: TReservationSettingResponse;
};

export const fetchReservationSettingDetail = async (
  id: number
): Promise<TApiResult & TApiReservationSettingDetailResult> => {
  return client()
    .reservation_settings.get({
      query: { event_id: id },
    })
    .then((res) => {
      return {
        isSuccess: true,
        status: 200,
        message: "",
        result: res.body,
      };
    })
    .catch((e: AxiosError) => {
      const { status, message } = getErrorProperty(
        e,
        "fetchReservationSettingDetail"
      );
      return {
        isSuccess: false,
        status: status,
        message: message,
      };
    });
};

import BaseHead from "../templates/BaseHead";
import STUtils from "../../assets/styles/utility.module.scss";
import InfoArticleList from "../organisms/InfoArticleList";
import { getSubdomain } from "../../utils/getSubdomain";
import { routes } from "../../router/Router";
import { useLocation } from "react-router-dom";
import { CONTENT_TYPE, TAppContentType } from "../../types/TAppContentType";
import { useContext } from "react";
import { SiteContext } from "../../providers/SiteProvider";
import BottomNavigationBar from "../organisms/BottomNavigationBar";

const InformationList: React.FC = () => {
  const query = new URLSearchParams(useLocation().search);
  const page = query.get("page") ? Number(query.get("page")) : 1;
  const list = query.get("list") ?? undefined;
  const category = query.get("category")
    ? Number(query.get("category"))
    : undefined;
  const title = query.get("title") ?? undefined;
  const categorySlug = query.get("category_slug") ?? undefined;
  const newUiVersion = useContext(SiteContext).newUIVersion;
  const contentTypeIds: TAppContentType[] = [
    CONTENT_TYPE.information,
    CONTENT_TYPE.video,
  ]
  if(!newUiVersion || !list){
    contentTypeIds.push(CONTENT_TYPE.video);
  }

  const subdomain = getSubdomain(window.location.hostname);
  return (
    <BaseHead
      title={routes.informations.title}
      login={true}
      subdomain={subdomain}
      hasBackground={true}
      hasNavigationBar={true}
    >
      <section className={STUtils.container_large} style={{marginBottom: newUiVersion ? "24px" : ""}}>
        <InfoArticleList
          requestPage={page}
          requestCategory={category}
          requestTitle={title}
          requestCategorySlug={categorySlug}
          contentTypeIds={contentTypeIds}
        />
        {newUiVersion &&
          <div>
            <BottomNavigationBar />
          </div>
        }
      </section>
    </BaseHead>
  );
};

export default InformationList;

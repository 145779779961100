import { useMemo } from "react";

export const useIsMobile = () => {
  const userAgent = window.navigator.userAgent;

  const isSafari = useMemo(() => {
    return (
      userAgent.includes("Safari") &&
      !userAgent.includes("Chrome") &&
      !userAgent.includes("Chromium")
    );
  }, [userAgent]);

  const isAndroidChrome = useMemo(() => {
    return (
      userAgent.includes("Android") &&
      userAgent.includes("Chrome") &&
      !userAgent.includes("Edge") &&
      !userAgent.includes("OPR")
    );
  }, [userAgent]);

  const isMobile = isSafari || isAndroidChrome;

  return {
    isSafari,
    isAndroidChrome,
    isMobile,
  };
};

import { Helmet } from "react-helmet";
import { routes } from "../../router/Router";

const Presenter: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>{routes.staticError.title}</title>
      </Helmet>
      <main>
        <h1>error...</h1>
      </main>
    </>
  );
};

export default Presenter;

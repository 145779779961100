import PAGE_CONFIG from "../config/page";

const getImageUrl = (
  subdomain: string,
  path: string,
  fileName: string
): string => {
  return `${PAGE_CONFIG.CDN_ENDPOINT}/${subdomain}${path}/${fileName}`;
};

export default getImageUrl;

import STTextDate from "../../assets/styles/atoms/textDate.module.scss";

type Props = {
  date: string;
};

const TextDate: React.FC<Props> = (props: Props) => {
  return <span className={STTextDate.text_date}>{props.date}</span>;
};

export default TextDate;

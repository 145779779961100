import PointHistoryList from "../organisms/PointHistoryList";
import PointHistoryPointCard from "../organisms/PointHistoryPointCard";
import BaseHead from "../templates/BaseHead";
import STUtils from "../../assets/styles/utility.module.scss";
import { getSubdomain } from "../../utils/getSubdomain";
import { useContext, useEffect, useState } from "react";
import { TAppPointHistory } from "../../types/TAppPointHistory";
import { TAppRankSummary } from "../../types/TAppRankSummary";
import TPager from "../../types/TAppPager";
import {
  fetchPointHistories,
  TApiPointHisotriesResult,
} from "../../utils/api/fetchPointHistories";
import TApiResult from "../../types/api/TApiResult";
import { useHistory, useLocation } from "react-router-dom";
import { routes } from "../../router/Router";
import { postMemberAccessLog } from "../../utils/api/postMemberAccessLog";
import { ACCESS_LOG_ACTION } from "../../types/TAppAccessLogAction";
import { SiteContext } from "../../providers/SiteProvider";
import BottomNavigationBar from "../organisms/BottomNavigationBar";

const years = [...Array(3)].map((_, i) => `${new Date().getFullYear() - i}`);

const Presenter: React.FC = () => {
  const query = new URLSearchParams(useLocation().search);
  const requestPage = query.get("page") ? Number(query.get("page")) : undefined;

  const subdomain = getSubdomain(window.location.hostname);
  const [year, setYear] = useState(years[0]);

  const [pointHistories, setPointHistories] = useState<TAppPointHistory[]>([]);
  const [rankSummary, setRankSummary] = useState<TAppRankSummary>();
  const [pager, setPager] = useState<TPager>(new TPager(requestPage));
  const history = useHistory();

  const site = useContext(SiteContext);

  const getStatus = (code: number, isCancel: boolean) => {
    if (isCancel) return "取消済";

    switch (code) {
      case 1:
        return "申請中";
      case 2:
        return "承認済";
      case 9:
        return "却下";
      default:
        return "";
    }
  };

  useEffect(() => {
    const offset = requestPage ? (requestPage - 1) * TPager.LIMIT : undefined;
    const limit = requestPage ? TPager.LIMIT : undefined;

    fetchPointHistories(year, offset, limit).then(
      (res: TApiResult & TApiPointHisotriesResult) => {
        if (!res.isSuccess || !res.result)
          return history.push(routes.error.path);
        const newHistories: TAppPointHistory[] = res.result.point.map((res) => {
          return {
            status: getStatus(res.status, res.cancel_flg),
            pointName: res.point_name,
            point: res.point,
            entryDate: res.entry_date,
            actionMonth: res.action_month,
            cancelFlg: res.cancel_flg,
          };
        });
        setPointHistories(newHistories);
        const newPager: TPager = new TPager(
          requestPage,
          res.result.total_count
        );
        setPager(newPager);
        const newRankSummary: TAppRankSummary = {
          name: res.result.rank.name,
          currentPeriodPoint: res.result.rank.current_period_point,
          icon: res.result.rank.icon,
        };
        setRankSummary(newRankSummary);
      }
    );
  }, [year, requestPage]);

  useEffect(() => {
    const accessLogAction = site.isPoint ? ACCESS_LOG_ACTION.pointHistory : ACCESS_LOG_ACTION.rewardHistory;
    postMemberAccessLog(accessLogAction, document.referrer);
  }, []);

  return (
    <BaseHead
      title={routes.pointHistory.title}
      login={true}
      subdomain={subdomain}
    >
      <section className={STUtils.container_large} style={{minHeight: 'calc(100vh - 265px)'}} >
        <PointHistoryPointCard rank={rankSummary} />
        <PointHistoryList
          pointHistories={pointHistories}
          pager={pager}
          year={year}
          setYear={setYear}
          years={years}
        />
        <BottomNavigationBar />
      </section>
    </BaseHead>
  );
};

export default Presenter;

import { Link } from "react-router-dom";
import Image from "../atoms/Image";
import Time from "../atoms/Time";

import STSpContentCard from "../../assets/styles/molecules/spContentCard.module.scss";
import GoodIcon from "../atoms/GoodIcon";
import { format } from "../../types/api/TApiDatetime";
import playButtonImage from "../atoms/play_video_button.png";
import noImage from "../../assets/images/noImage-SP.png";

type Props = {
  title: string;
  className: string;
  linkUrl: string;
  thumbnailUrl?: string | null;
  thumbnailAlt?: string;
  createdAt: string;
  goodCount?: number | null;
  contentTypeId?: number;
  url?: string | null;
};

const SpContentCard: React.FC<Props> = (props: Props) => {
  const isVideo = props.contentTypeId === 5;
  const renderVideo = (props: Props) => {
    return (
      <div className={STSpContentCard.wrapper}>
        <img
          src={playButtonImage}
          alt="再生ボタン"
          className={STSpContentCard.playButton}
        />
        <div
          style={{ backgroundImage: `url(${props.thumbnailUrl || noImage})` }}
          className={STSpContentCard.content_card_video_thumbnail_img}
        ></div>
      </div>
    );
  };

  return (
    <div className={STSpContentCard.content_card_wrap}>
      <Link to={props.linkUrl}>
        <div className={STSpContentCard.content_card_thumbnail}>
          {isVideo ? (
            renderVideo(props)
          ) : (
              <div className={STSpContentCard.wrapper}>
                <div
                  style={{ backgroundImage: `url(${props.thumbnailUrl || noImage})` }}
                  className={STSpContentCard.content_card_article_thumbnail_img}
                ></div>
              </div>
            )}
        </div>
      </Link>
      <div className={STSpContentCard.content_card_textwrap}>
        <div className={STSpContentCard.content_card_title_block}>
          <Link
            className={STSpContentCard.content_card_content_title}
            to={props.linkUrl}
          >
            {props.title}
          </Link>
        </div>
        <div className={STSpContentCard.content_card_time_goodCount}>
          <Time
            className={STSpContentCard.content_card_content_time}
            time={format(props.createdAt, "yyyy.MM.dd")}
          />
          {!isVideo && (
            <div className={STSpContentCard.good_count}>
              <GoodIcon count={props.goodCount ?? 0} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SpContentCard;

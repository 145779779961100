export type TAppUserSession = {
  isLimited: boolean;
  expire: number | null;
};

// 全てのプロパティを"Optional"とし、全ての値についてunknown型とした型を返す
type WouldBe<T> = { [P in keyof T]?: unknown };

// Objectかどうかを判定する型ガード関数
const isObject = <T extends Record<string, unknown>>(
  value: unknown
): value is WouldBe<T> => typeof value === "object" && value !== null;

// 型かどうかを判定する型ガード関数
export const isTAppUserSession = (value: unknown): value is TAppUserSession =>
  isObject<TAppUserSession>(value) &&
  typeof value.isLimited === "boolean" &&
  (typeof value.expire === "number" || value.expire === null);

import { AxiosError } from "axios";
import { getAuth } from "firebase/auth";
import client from "../../api/client";
import TApiResult from "../../types/api/TApiResult";
import getErrorProperty from "./config/getErrorProperty";

export const postContactDetail = async (
  sei: string,
  mei: string,
  kanaSei: string,
  kanaMei: string,
  email: string,
  tel: string,
  contact: string
): Promise<TApiResult> => {
  const body = {
    body: {
      sei: sei,
      mei: mei,
      kana_sei: kanaSei,
      kana_mei: kanaMei,
      email: email,
      tel: tel,
      contact: contact,
    },
  };

  const auth = getAuth();
  const token = await auth.currentUser?.getIdToken();
  const authHeader = token && { headers: { Authorization: `Bearer ${token}` } };
  return await client()
    .contact_details.post({ ...body, ...authHeader })
    .then((res) => {
      return {
        isSuccess: true,
        status: 200,
        message: "",
      };
    })
    .catch((e: AxiosError) => {
      const { status, message } = getErrorProperty(e, "postContactDetail");
      return {
        isSuccess: false,
        status: status,
        message: message,
      };
    });
};

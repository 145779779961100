import client from "../../api/client";
import TApiResult from "../../types/api/TApiResult";
import { AxiosError } from "axios";
import getErrorProperty from "./config/getErrorProperty";
import { TMessageSettingResponce } from "../../api/message_setting";

export type TApiMessageSettingsResult = {
  result?: TMessageSettingResponce[];
};

export const fetchMessageSetting = async (
  panel_id: number
): Promise<TApiResult & TApiMessageSettingsResult> => {
  return client()
    .message_setting.get({
      query: {
        panel_id: panel_id,
      },
    })
    .then((res) => {
      return {
        isSuccess: true,
        status: 200,
        message: "",
        result: res.body,
      };
    })
    .catch((e: AxiosError) => {
      const { status, message } = getErrorProperty(e, "fetchMessageSetting");
      return {
        isSuccess: false,
        status: status,
        message: message,
      };
    });
};

import client from "../../api/client";
import TApiResult from "../../types/api/TApiResult";
import { AxiosError } from "axios";
import getErrorProperty from "./config/getErrorProperty";
import { TMemberRegisterResponce } from "../../api/member/register/_id@string/_datetime_hash@string";

export type TApiMemberRegisterResult = {
  result?: TMemberRegisterResponce;
};

export const fetchMemberRegister = async (
  id: string,
  datetimeHash: string
): Promise<TApiResult & TApiMemberRegisterResult> => {
  return client()
    .member.register._id(id)
    ._datetime_hash(datetimeHash)
    .get()
    .then((res) => {
      return {
        isSuccess: true,
        status: 200,
        message: "",
        result: res.body,
      };
    })
    .catch((e: AxiosError) => {
      const { status, message } = getErrorProperty(e, "fetchMemberRegister");
      return {
        isSuccess: false,
        status: status,
        message: message,
      };
    });
};

import client from "../../api/client";
import TApiResult from "../../types/api/TApiResult";
import { AxiosError } from "axios";
import getErrorProperty from "./config/getErrorProperty";
import { TContentResponse } from "../../api/contents/_idOrSlug@string";
import { getAuth } from "firebase/auth";

export type TApiContentResult = {
  result?: TContentResponse;
};

export const fetchContentDetail = async (
  idOrSlug: string
): Promise<TApiResult & TApiContentResult> => {
  const auth = getAuth();
  const token = await auth.currentUser?.getIdToken();
  const authHeader = token
    ? {
        headers: { Authorization: `Bearer ${token}` },
      }
    : undefined;

  return await client()
    .contents._idOrSlug(idOrSlug)
    .get(authHeader)
    .then((res) => {
      return {
        isSuccess: true,
        status: 200,
        message: "",
        result: res.body,
      };
    })
    .catch((e: AxiosError) => {
      const { status, message } = getErrorProperty(e, "fetchContentDetail");
      return {
        isSuccess: false,
        status: status,
        message: message,
      };
    });
};

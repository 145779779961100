// NOTE: https://qiita.com/saba_can00/items/bdefb28a1873658cf5d9
export const CONTACT_FORM_ITEM = {
  sei: "sei",
  mei: "mei",
  kanaSei: "kana_sei",
  kanaMei: "kana_mei",
  tel: "tel",
  email: "email",
  emailConfirm: "email_confirm",
  body: "body",
} as const;

type TypeOfContactFormItem = typeof CONTACT_FORM_ITEM;
export type TAppContactFormItem =
  TypeOfContactFormItem[keyof TypeOfContactFormItem];

import { Link } from "react-router-dom";
import STUtils from "../../assets/styles/utility.module.scss";
import STContactApplyCard from "../../assets/styles/organisms/contactApplyCard.module.scss";
import { routes } from "../../router/Router";

const ContactApplyCard: React.FC = () => {
  return (
    <div className={STUtils.container_form}>
      <h1 className={STContactApplyCard.title}>お問い合わせ完了</h1>
      <div>
        <p className={STContactApplyCard.description}>
          お問い合わせの送信が
          <br className={STUtils.dn_pc} />
          完了いたしました。
        </p>
        <p className={STContactApplyCard.text}>
          ※メールでのお問い合わせに対するご返信や対応につきましては、
          <br className={STUtils.dn_sp} />
          2~3営業日を目安としております。
        </p>
      </div>
      <div className={STContactApplyCard.btn}>
        <Link to={routes.top.path}>MYページTOP</Link>
      </div>
    </div>
  );
};

export default ContactApplyCard;

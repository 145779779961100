import client from "../../api/client";
import TApiResult from "../../types/api/TApiResult";
import { AxiosError } from "axios";
import getErrorProperty from "./config/getErrorProperty";
import { TPointHistoriesResponse } from "../../api/point/history";
import { getAuth } from "firebase/auth";
import tokenNotFound from "./config/tokenNotFound";

export type TApiPointHisotriesResult = {
  result?: TPointHistoriesResponse;
};

export const fetchPointHistories = async (
  year: string,
  offset?: number,
  limit?: number
): Promise<TApiResult & TApiPointHisotriesResult> => {
  const auth = getAuth();
  const token = await auth.currentUser?.getIdToken();
  if (!token) return tokenNotFound;
  return await client()
    .point.history.get({
      query: {
        year: year,
        skip: offset,
        limit: limit,
      },
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      return {
        isSuccess: true,
        status: 200,
        message: "",
        result: res.body,
      };
    })
    .catch((e: AxiosError) => {
      const { status, message } = getErrorProperty(e, "fetchPointHistories");
      return {
        isSuccess: false,
        status: status,
        message: message,
      };
    });
};

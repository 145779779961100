import BaseHead from "../templates/BaseHead";
import STUtils from "../../assets/styles/utility.module.scss";
import InfoArticleDetail from "../organisms/InfoArticleDetail";
import { getSubdomain } from "../../utils/getSubdomain";
import { useHistory, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { TAppInfoArticle } from "../../types/TAppInfoArticle";
import { fetchContentDetail } from "../../utils/api/fetchContentDetail";
import { routes } from "../../router/Router";
import { postMemberAccessLog } from "../../utils/api/postMemberAccessLog";
import { ACCESS_LOG_ACTION } from "../../types/TAppAccessLogAction";
import Ogp from "../atoms/Ogp";
import { getAuth } from "firebase/auth";
import { SiteContext } from "../../providers/SiteProvider";
import BottomNavigationBar from "../organisms/BottomNavigationBar";

const News: React.FC = () => {
  const urlParams = useParams<{ id: string }>();

  const subdomain = getSubdomain(window.location.hostname);

  const history = useHistory();
  const auth = getAuth();
  const [infoArticle, setInfoArticle] = useState<TAppInfoArticle>();
  const [isClicked, setIsClicked] = useState<boolean>(false);
  const newUiVersion = useContext(SiteContext).newUIVersion;

  useEffect(() => {
    fetchContentDetail(urlParams.id).then((res) => {
      if (!res.result || !res.isSuccess) return history.push(routes.error.path);
      const eyeCatchImaegeUrl =
        res.result.img_url !== null
          ? res.result.img_url !== ""
            ? res.result.img_url
            : undefined
          : undefined;
      const newInfoArticle = {
        id: String(res.result.id),
        uid: auth.currentUser?.uid ? auth.currentUser?.uid : null,
        title: res.result.title,
        content: res.result.content,
        eyeCatchImaegeUrl: eyeCatchImaegeUrl,
        postDate: res.result.post_date,
        categoryName: res.result.category_name,
        categoryId: res.result.category_id,
        likeCount: res.result.like_count,
      };
      setInfoArticle(newInfoArticle);
      setIsClicked(res.result.like_flg);
      postMemberAccessLog(ACCESS_LOG_ACTION.contentDetail, document.referrer);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlParams.id]);

  if (!infoArticle) return <></>;

  return (
    <BaseHead title={infoArticle.title} login={true} subdomain={subdomain} hasNavigationBar={true}>
      <Ogp
        path={routes.newsDetail.path(infoArticle.id)}
        imageUrl={infoArticle.eyeCatchImaegeUrl}
      />
      <section className={STUtils.container_large}>
        <InfoArticleDetail
          infoArticle={infoArticle}
          isClicked={isClicked}
          setInfoArticle={setInfoArticle}
          setIsClicked={setIsClicked}
        />
        {newUiVersion &&
            <div style={{margin: "24px 0"}}>
                <BottomNavigationBar />
            </div>
        }
      </section>
    </BaseHead>
  );
};

export default News;

import client from "../../api/client";
import TApiResult from "../../types/api/TApiResult";
import { AxiosError } from "axios";
import getErrorProperty from "./config/getErrorProperty";
import { TCategoryResponse } from "../../api/category/_idOrSlug@string";
import { getAuth } from "firebase/auth";
import tokenNotFound from "./config/tokenNotFound";

export type TApiCategoryResult = {
  result?: TCategoryResponse;
};

export const fetchCategory = async (
  idOrSlug: string,
): Promise<TApiResult & TApiCategoryResult> => {
  const auth = getAuth();
  const token = await auth.currentUser?.getIdToken();
  if (!token) return tokenNotFound;
  return await client()
    .category._idOrSlug(idOrSlug)
    .get({
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      return {
        isSuccess: true,
        status: 200,
        message: "",
        result: res.body,
      };
    })
    .catch((e: AxiosError) => {
      const { status, message } = getErrorProperty(e, "fetchCategory");
      return {
        isSuccess: false,
        status: status,
        message: message,
      };
    });
};

import { Link } from "react-router-dom";
import STUtils from "../../assets/styles/utility.module.scss";
import STMailAddressConfirmApplyCard from "../../assets/styles/organisms/mailAddressConfirmApplyCard.module.scss";
import { routes } from "../../router/Router";

type Props = {
  action: string;
};

const MailAddressConfirmApplyCard: React.FC<Props> = ({ action }: Props) => {
  return (
    <div className={STUtils.container_form}>
      <h1
        className={
          STMailAddressConfirmApplyCard.mail_address_confirm_apply_card_title
        }
      >
        メール送信完了
      </h1>
      <p
        className={
          STMailAddressConfirmApplyCard.mail_address_confirm_apply_card_txt
        }
      >
        メール本文のURLから{action}を行ってください。
        <br />
        <br />
        メールが届かなかった場合は、
        <br />
        <Link
          to={routes.contact.path}
          className={
            STMailAddressConfirmApplyCard.mail_address_confirm_apply_card_link
          }
        >
          お問合せフォーム
        </Link>
        からご連絡ください。
      </p>
    </div>
  );
};

export default MailAddressConfirmApplyCard;

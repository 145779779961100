import client from "../../api/client";
import TApiResult from "../../types/api/TApiResult";
import { AxiosError } from "axios";
import getErrorProperty from "./config/getErrorProperty";
import { getAuth } from "firebase/auth";
import { TContentPreviewResponse } from "../../api/contents/preview/_key@string";

export type TApiContentPreviewResult = {
  result?: TContentPreviewResponse;
};

export const fetchContentPreviewDetail = async (
  key: string
): Promise<TApiResult & TApiContentPreviewResult> => {
  const auth = getAuth();
  const token = await auth.currentUser?.getIdToken();
  const authHeader = token
    ? {
        headers: { Authorization: `Bearer ${token}` },
      }
    : undefined;

  return await client()
    .contents.preview._key(key)
    .get(authHeader)
    .then((res) => {
      return {
        isSuccess: true,
        status: 200,
        message: "",
        result: res.body,
      };
    })
    .catch((e: AxiosError) => {
      const { status, message } = getErrorProperty(
        e,
        "fetchContentPreviewDetail"
      );
      return {
        isSuccess: false,
        status: status,
        message: message,
      };
    });
};

import React, { useEffect, useMemo, useState } from 'react';
import MenuItem from '../molecules/MenuItem';
import STMenuList from "../../assets/styles/organisms/menuList.module.scss";
import { TApiBannerSettingsResult, fetchBannerSettings } from '../../utils/api/fetchBannerSettings';
import TApiResult from '../../types/api/TApiResult';
import { useHistory } from 'react-router-dom';
import { routes } from '../../router/Router';
import { ScratchSetting } from '../../types/popup';

type MenuData = {
    id: number;
    siteId: number;
    locationId: number;
    imgUrl: string;
    imgType: number;
    linkUrl: string;
    linkName: string;
    description: string | undefined;
}

type Props = {
  onClickScratchBanner: () => void;
  scratchSetting: ScratchSetting | undefined;
  setScratchSetting: (scratchSetting: ScratchSetting) => void;
}

const MenuList = (props:Props) => {
  const SCRATCH_LOCATION_ID = 19;
  const menus: ReadonlyArray<number> = useMemo(() => {
    return [
      22, // メニュー1
      23,
      24,
      25,
      26,
      27,
      28,
      29
    ];
  }, []);
  const [menuData, setMenuData] = useState<MenuData[] | undefined>();
  const [scratchSettingItem, setScratchSettingItem] = useState<MenuData>();
  const history = useHistory();

  useEffect(() => {
    fetchBannerSettings().then((res: TApiResult & TApiBannerSettingsResult) => {
      if (!res.isSuccess || !res.result) {
        return history.push(routes.error.path);
      }
      let newBenefitItems = res.result.map((res) => {
        return {
          id: res.id,
          siteId: res.site_id,
          locationId: res.location_id,
          imgUrl: res.img_url,
          imgType: res.img_type,
          linkUrl: res.link_url,
          linkName: res.link_name,
          description: res.description,
        };
      });
      const newBenefitItemList = newBenefitItems.filter((newBenefitItem) => {
        return (
          newBenefitItem.imgUrl !== null &&
          menus.includes(newBenefitItem.locationId)
        );
      });
      newBenefitItemList.sort((a, b) => {
        return a.locationId - b.locationId;
      });
      if(props.scratchSetting) {
        const scratchSettingItem = newBenefitItems.filter(item => {
          return item.locationId === SCRATCH_LOCATION_ID && props.scratchSetting?.isPublic;
        })
        if (scratchSettingItem.length !== 0){
          setScratchSettingItem(scratchSettingItem[0]);
        }
      }
      setMenuData(newBenefitItemList);
    }).catch((err) => {
      console.error(err);
    });
  }, [menus, props.scratchSetting]);


  if (!menuData) return <></>;

  return (
    <div className={STMenuList.menu_list}>
      {menuData.map((menu) => (
        <MenuItem key={menu.id} {...menu} />
      ))}
      {scratchSettingItem && (
        <div className={STMenuList.menu_item_link}>
          <div
            className={STMenuList.menu_item}
            onClick={props.onClickScratchBanner}
            style={{ cursor: "pointer" }}
          >
            <div>
              <img
                alt="スクラッチバナー画像"
                src={scratchSettingItem.imgUrl}
                className={STMenuList.menu_item_img}
              />
            </div>
          </div>
          <div className={STMenuList.link_name}>
            <h4>スクラッチ</h4>
          </div>
        </div>
      )}
    </div>
  );
}

export default MenuList;

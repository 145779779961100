import STFile from "../../assets/styles/organisms/file.module.scss";
import STCustomAccordionDetails from "../../assets/styles/molecules/customAccordion.module.scss";
import { Box, Divider, Typography, AccordionDetails } from "@material-ui/core";
import { Fragment } from "react";
import { format } from "date-fns";
import { FileData } from "../../types/FileData";

type FileDetailsProps = {
  file: FileData;
  getDownload: (fileId: number) => void;
};

export const FileDetails = ({ file, getDownload }: FileDetailsProps) => {

  return (
    <Fragment>
      <AccordionDetails className={STCustomAccordionDetails.custom_accordion_details}>
        <Box
          display="flex"
          width="100%"
          justifyContent="space-between"
          alignItems="center"
          onClick={() => getDownload(file.id)}
        >
          <Box minWidth={100}>
            <Typography noWrap className={STFile.file_name}>
              {file.name}
            </Typography>
          </Box>
          <Box>
            <Typography className={STFile.file_date_title}>更新日：</Typography>
            <Typography className={STFile.file_tate}>
              {format(new Date(file.modified), "yyyy/MM/dd")}
            </Typography>
          </Box>
        </Box>
      </AccordionDetails>
      <Divider className={STFile.divider} />
    </Fragment>
  );
};

type Props = {
  id?: string;
  className: string;
  for: string;
  value: string;
};

const Label: React.FC<Props> = (props: Props) => {
  return (
    <label id={props.id} className={props.className} htmlFor={props.for}>
      {props.value}
    </label>
  );
};

export default Label;

import { Box, Button, makeStyles } from "@material-ui/core";
import { SiteContext } from "../../providers/SiteProvider";
import { useContext, useState } from "react";
import STButtonLink from "../../assets/styles/atoms/buttonLink.module.scss";
import STMemberInfoCard from "../../assets/styles/organisms/memberInfoCard.module.scss";
import { useIsMobile } from "../../hooks/useIsMobile";

const useStyles = makeStyles(() => ({
  wrapper: {
    width: "100%",
    height: "15%",
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    alignItems: "center",
    borderTop: "2px solid lightgray",
    borderBottom: "1px solid lightgray",
  },
  content: {
    width: "fit-content",
    margin: "0 auto",
    height: "100%",
    fontSize: "16px",
    padding: "12px 0 0",
    fontWeight: "bold",
  },
  contentChild: {
    padding: "10px",
  },
  contentChildSp: {
    padding: "10px",
    fontSize: "14px",
  },
  buttonWrapper: {
    width: "100%",
    margin: "0 auto",
  },
  buttonArea: {
    display: "flex",
    justifyContent: "center",
    padding: "5px 0 10px 0",
  },
  buttonMessage: {
    fontSize: "14px",
    padding: "0",
    width: "fit-content",
    fontWeight: "bold",
  },
}));

interface Props {
  onClick: () => void;
  error: string;
}
export const MailAttention = ({ onClick, error }: Props) => {
  const mainColor = useContext(SiteContext).mainColor;
  const classes = useStyles();
  const { isMobile } = useIsMobile();
  const [isSend, setIsSend] = useState(false);
  const onClickFunction = () => {
    setIsSend(true);
    onClick();
  };
  return (
    <Box className={classes.wrapper}>
      {isSend ? (
        <Box className={classes.content}>
          <p
            className={isMobile ? classes.contentChildSp : classes.contentChild}
          >
            認証用のメールを送信しました。
            <br />
            メールに記載のURLにアクセスし、
            {isMobile && <br />}
            認証を完了させてください。
          </p>
          {error && <p className={classes.contentChild}>{error}</p>}
        </Box>
      ) : (
        <>
          <Box className={classes.content}>
            <p
              className={
                isMobile ? classes.contentChildSp : classes.contentChild
              }
            >
              メールアドレス認証を完了させてください。
            </p>
            {error && <p className={classes.contentChild}>{error}</p>}
          </Box>
          <Box className={classes.buttonArea}>
            <Button
              variant="contained"
              onClick={onClickFunction}
              className={`${STButtonLink.primary} ${STMemberInfoCard.member_point_request_btn}`}
              style={{
                backgroundColor: mainColor,
                color: "#FFF",
                borderRadius: "25px",
                height: "40px",
                width: "200px",
                padding: 0,
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="18"
                viewBox="0 0 22 18"
                fill="none"
                style={{
                  padding: "0 4px",
                  width: "fit-content",
                }}
              >
                <path
                  d="M19.75 17.5H2.25C1.285 17.5 0.5 16.715 0.5 15.75V2.25C0.5 1.285 1.285 0.5 2.25 0.5H19.75C20.715 0.5 21.5 1.285 21.5 2.25V15.75C21.5 16.715 20.715 17.5 19.75 17.5ZM2.25 2C2.112 2 2 2.112 2 2.25V15.75C2 15.888 2.112 16 2.25 16H19.75C19.888 16 20 15.888 20 15.75V2.25C20 2.112 19.888 2 19.75 2H2.25Z"
                  fill="#FFF"
                />
                <path
                  d="M11 10.7751C10.31 10.7751 9.62 10.5131 9.083 9.99006L4.34 5.37706C4.043 5.08806 4.036 4.61306 4.325 4.31606C4.614 4.01906 5.089 4.01306 5.386 4.30106L10.129 8.91406C10.617 9.39006 11.383 9.39006 11.872 8.91406L16.615 4.30106C16.913 4.01306 17.387 4.01906 17.676 4.31606C17.965 4.61306 17.958 5.08806 17.661 5.37706L12.918 9.99006C12.38 10.5131 11.69 10.7751 11.001 10.7751H11Z"
                  fill="#FFF"
                />
              </svg>
              <span className={classes.buttonMessage}>
                認証用のメールを送る
              </span>
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};

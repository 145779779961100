import client from "../../api/client";
import TApiResult from "../../types/api/TApiResult";
import { AxiosError } from "axios";
import getErrorProperty from "./config/getErrorProperty";
import { TEventRequiredResponse } from "../../api/event_required";

export type TApiEventRequiredResult = {
  result?: TEventRequiredResponse[];
};

export const fetchEventRequired = async (
  id: number
): Promise<TApiResult & TApiEventRequiredResult> => {
  return client()
    .event_required.get({
      query: { event_id: id },
    })
    .then((res) => {
      return {
        isSuccess: true,
        status: 200,
        message: "",
        result: res.body,
      };
    })
    .catch((e: AxiosError) => {
      const { status, message } = getErrorProperty(e, "fetchEventRequired");
      return {
        isSuccess: false,
        status: status,
        message: message,
      };
    });
};

import client from "../../api/client";
import TApiResult from "../../types/api/TApiResult";
import { AxiosError } from "axios";
import getErrorProperty from "./config/getErrorProperty";
import { TContentFixedResponse } from "../../api/contents/fixed/_idOrslug@string";

export type TApiContentFixedResult = {
  result?: TContentFixedResponse;
};

export const fetchContentDetailFixed = async (
  idOrSlug: string
): Promise<TApiResult & TApiContentFixedResult> => {
  return await client()
    .contents.fixed._idOrslug(idOrSlug)
    .get()
    .then((res) => {
      return {
        isSuccess: true,
        status: 200,
        message: "",
        result: res.body,
      };
    })
    .catch((e: AxiosError) => {
      const { status, message } = getErrorProperty(
        e,
        "fetchContentDetailFixed"
      );
      return {
        isSuccess: false,
        status: status,
        message: message,
      };
    });
};

import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useLoginUser } from "../hooks/useLoginUser";

import { initializeApp } from "firebase/app";
import {
  browserSessionPersistence,
  getAuth,
  indexedDBLocalPersistence,
  setPersistence,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import FIREBASE from "../config/firebase";
import { postMemberAccessLog } from "../utils/api/postMemberAccessLog";
import { ACCESS_LOG_ACTION } from "../types/TAppAccessLogAction";
import CONSTANTS from "../utils/constants";
import { addHours } from "date-fns";
import { TAppUserSession } from "../types/TAppUserSession";

export const useAuth = () => {
  const history = useHistory();
  const { setLoginUser } = useLoginUser();

  const removeUserSession = () => {
    localStorage.removeItem(CONSTANTS.USER_SESSION_KEY);
  };

  const saveUserSession = (save: boolean) => {
    removeUserSession();

    const expire = save ? null : addHours(new Date(), 2).getTime();
    const userSession: TAppUserSession = { isLimited: !save, expire: expire };
    localStorage.setItem(
      CONSTANTS.USER_SESSION_KEY,
      JSON.stringify(userSession)
    );
  };

  const login = useCallback(
    (email: string, password: string, save: boolean, tenantId: string) => {
      auth.tenantId = tenantId;

      return setPersistence(auth, indexedDBLocalPersistence)
        .then((_) => signInWithEmailAndPassword(auth, email, password))
        .then(
          (userCredential) => {
            const user = userCredential.user;
            setLoginUser(user);
            return user.getIdToken();
          },
          (err) => {
            return undefined;
          }
        )
        .then((token: string | undefined) => {
          if (token) {
            postMemberAccessLog(ACCESS_LOG_ACTION.login, document.referrer);
            saveUserSession(save);
            return true;
          }
          return false;
        });
    },
    [setLoginUser]
  );

  const logout = useCallback(() => {
    signOut(auth).then(() => {
      removeUserSession();
      history.go(0);
    });
  }, []);

  return { login, logout };
};

initializeApp(FIREBASE);
const auth = getAuth();

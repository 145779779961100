import { useMemo } from "react";

interface OSInfo {
  isWindows: boolean;
  isMacOS: boolean;
  isAndroid: boolean;
  isIOS: boolean;
  os: string;
}

declare global {
  interface Window {
    MSStream?: any;
  }
}

export const useOS = (): OSInfo => {
  const userAgent = window.navigator.userAgent || window.navigator.vendor;

  const isWindows = useMemo(() => {
    return userAgent.includes("Windows");
  }, [userAgent]);

  const isMacOS = useMemo(() => {
    return userAgent.includes("Macintosh") || userAgent.includes("Mac OS");
  }, [userAgent]);

  const isAndroid = useMemo(() => {
    return userAgent.includes("Android");
  }, [userAgent]);

  const isIOS = useMemo(() => {
    return /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
  }, [userAgent]);

  const os = useMemo(() => {
    if (isWindows) return "Windows";
    if (isMacOS) return "macOS";
    if (isAndroid) return "Android";
    if (isIOS) return "iOS";
    return "Unknown";
  }, [isWindows, isMacOS, isAndroid, isIOS]);

  return {
    isWindows,
    isMacOS,
    isAndroid,
    isIOS,
    os,
  };
};

import { STATIC_PAGE_SLUG } from "../../types/TAppStaticPageSlug";
import { routes } from "../../router/Router";
import { Redirect } from "react-router-dom";

const Privacy: React.FC = () => {
  return (
    <Redirect
      to={{ pathname: routes.staticDetail.path(STATIC_PAGE_SLUG.privacy) }}
    />
  );
};

export default Privacy;

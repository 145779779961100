import * as React from "react";
import { SVGProps } from "react";

const SvgGoodBtnOff = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="GoodBtnOff_svg__\u30EC\u30A4\u30E4\u30FC_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 55 20"
    xmlSpace="preserve"
    {...props}
  >
    <style>{".GoodBtnOff_svg__st2{fill:#87867c}"}</style>
    <path
      d="M8 15.6s6.9-4.4 6.9-8.4c0-2-1.6-3.6-3.6-3.6-1.4 0-2.7.9-3.3 2.2-.5-1.3-1.8-2.2-3.3-2.2-2 0-3.6 1.6-3.6 3.6 0 4 6.9 8.4 6.9 8.4z"
      style={{
        fill: "none",
        stroke: "#87867c",
        strokeMiterlimit: 10,
      }}
    />
    <path
      className="GoodBtnOff_svg__st2"
      d="M20.8 5.4c-.1.4-.1.9-.1 1.6 0 1.2.1 2.7.3 3.5.2 1.2.7 2 1.1 2 .2 0 .4-.2.5-.6.3-.6.5-1.3.6-2.1.3.4.5.6 1 .9C23.6 13.1 23 14 22 14c-.8 0-1.5-.8-1.9-2.1-.4-1.2-.6-2.8-.6-5.7v-.7h1.3zm5.7.6c1.5 1.8 2.3 3.7 2.7 6.2l-1.2.5c-.1-1.1-.4-2.3-.8-3.4s-1-2-1.7-2.8l1-.5zM32.8 5.4c-.1.4-.1.9-.1 1.6 0 1.2.1 2.7.3 3.5.2 1.2.7 2 1.1 2 .2 0 .4-.2.5-.6.3-.6.5-1.3.6-2.1.3.4.5.6 1 .9C35.6 13.1 35 14 34 14c-.8 0-1.5-.8-1.9-2.1-.4-1.2-.6-2.8-.6-5.7v-.7h1.3zm5.7.6c1.5 1.8 2.3 3.7 2.7 6.2l-1.2.5c-.1-1.1-.4-2.3-.8-3.4s-1-2-1.7-2.8l1-.5zM42.8 6.6h.3c.3 0 .9 0 1.6-.1V4.4h1.2c-.1.3-.1.4-.1 2 .1 0 .2 0 .2-.1l.4.5-.2.2c-.1.1-.1.2-.5.5V8.5l.3-.3c.4-.4 1.2-1 1.7-1.3.7-.4 1.4-.6 2-.6 1.7 0 2.7 1.4 2.7 4 0 .7 0 1.1-.2 1.7.2.2.7.5 1 .7.2.1.2.1.3.2l-.5 1.2-1.1-1.1c-.2.4-.3.6-.6.8-.4.4-1.2.6-1.9.6-1.3 0-2.2-.7-2.2-1.7s.9-1.8 2.2-1.8c.6 0 1.1.1 1.8.4.1-.4.1-.7.1-1.1 0-1.9-.6-2.8-1.7-2.8-.5 0-1.2.3-2 .9-.4.3-.7.6-1.9 1.6 0 3.3 0 4.2.1 4.8h-1.3c.1-.4.1-.4.1-1.2v-2.8c-.1.2-.4.5-.7.8-.6.6-.7.8-.9 1l-.6-1.2c.3-.2.4-.3 2.2-2.1V7.3h-.5c-.7 0-1 .1-1.3.1v-.8zm6.8 5.3c-.6 0-1.1.3-1.1.8 0 .4.5.7 1.1.7.3 0 .6-.1.9-.2.2-.1.4-.3.5-.6 0-.1.1-.2.1-.2-.6-.3-1-.5-1.5-.5z"
    />
  </svg>
);

export default SvgGoodBtnOff;

import { TAppFormError } from "../../../types/TAppFormError";
import STSingleCheckBoxRow from "../../../assets/styles/molecules/formTableRow/singleCheckBoxRow.module.scss";
import AlartMessages from "../../atoms/AlartMessages";
import validateFormRow from "../../../utils/form/validateFormRow";
import AppCheckBox from "../../atoms/form/AppCheckBox";
import Label from "../../atoms/form/Label";

type Props<T> = {
  subTitle?: string;
  label: string;
  defaultValue?: number;
  required?: boolean;
  formKey: T;
  errors: TAppFormError<T>[];
  setErrors: (errors: TAppFormError<T>[]) => void;
  hundleOnChange: (value: number) => void;
  isDisplay?: boolean;
  canChange?: boolean;
};

const SingleCheckBoxRow = <T,>({
  label,
  defaultValue,
  required = false,
  formKey,
  errors,
  setErrors,
  hundleOnChange,
  isDisplay = true,
  canChange = true,
}: Props<T>) => {
  const hundleChange = (input: string) => {
    validateFormRow<T>({
      input,
      errors,
      setErrors,
      formKey,
      required,
    });
    hundleOnChange(Number(input));
  };

  if (!isDisplay) return <></>;
  return (
      <>
        <AppCheckBox
          id={label}
          className={
            canChange
              ? `${STSingleCheckBoxRow.form}`
              : STSingleCheckBoxRow.disable
          }
          checked={defaultValue === 1}
          value="1"
          onChange={(input) => hundleChange(input)}
        />
        <Label
          id=""
          className={STSingleCheckBoxRow.label}
          for={label}
          value={label}
        />
        {AlartMessages<T>({
          title: label,
          errors: errors,
          keys: [formKey],
        })}
      </>
  );
};

export default SingleCheckBoxRow;

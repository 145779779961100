import styled from "styled-components";
import goodIconHeartMark from "../../assets/images/good_btn_heart_mark.svg";

type Props = {
  count: number;
};
const Container = styled.span`
  display: inline-block;
  background-image: url(${goodIconHeartMark});
  background-size: 13px 13px;
  background-repeat: no-repeat;
  background-position: left center;
  color: #87867c;
  font-size: 14px;
  padding-left: 15px;
`;

const GoodIcon: React.FC<Props> = (props: Props) => {
  return <Container>{props.count > 1000 ? '999+' : props.count}</Container>;
};

export default GoodIcon;

import { ReactElement } from "react";

type Props = {
  className?: string;
  buttonClassName?: string;
  clickAction: () => void;
  text: string | ReactElement;
  disabled?: boolean;
};

const Button: React.FC<Props> = (props: Props) => {
  return (
    <div className={props.className}>
      <button
        className={props.buttonClassName}
        onClick={props.clickAction}
        disabled={props.disabled ? props.disabled : false}
      >
        {props.text}
      </button>
    </div>
  );
};

export default Button;

import React, { useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import STUtils from "../../assets/styles/utility.module.scss";
import STMemberEntryFrom from "../../assets/styles/organisms/memberEntryForm.module.scss";
import { MemberEntryForm as TMemberEntryForm } from "../../types/form/memberEntryForm";
import { Link, useHistory, useLocation } from "react-router-dom";
import STButton from "../../assets/styles/atoms/button.module.scss";
import PAGE_CONFIG from "../../config/page";
import { fetchMessageSetting } from "../../utils/api/fetchMessageSetting";
import { PANEL } from "../../types/TAppPanel";
import getAddressData from "../../utils/getAddressData";
import { routes } from "../../router/Router";
import HtmlMessage from "../atoms/HtmlMessage";
import useMultipleClickPreventer from "../molecules/UseMultipleClickPreventer";
import {
  TApiMemberRegisterResult,
  TApiMemberRegisterValidateErrors,
  postMemberRegister,
} from "../../utils/api/postMemberRegister";
import TApiResult from "../../types/api/TApiResult";
import buildValidationSchema from "../../utils/form/memberEntryValidation";
import ErrorMessage from "../atoms/form/reactHookForms/ErrorMessage";
import {
  MEMBER_ENTEY_FORM_ITEM,
  TAppMemberEntryFormItem,
} from "../../types/TAppMemberEntryFormItem";
import {
  TMemberRegistrationFormSettingResult,
  fetchMemberRegistrationFormSetting,
} from "../../utils/api/fetchMemberRegistrationFormSettings";
import { TMemberRegistrationFormSettingResponse } from "../../api/member_registration_form_setting";
import { FieldComponent } from "./MemberEntryDynamicField";
import { Customer } from "../../api/member/register/_id@string/_datetime_hash@string";

const transformRegisterErrorResult: {
  [key in TAppMemberEntryFormItem]: {
    index: number;
    formName: keyof typeof MEMBER_ENTEY_FORM_ITEM;
  };
} = {
  sei: { index: 1, formName: "sei" },
  mei: { index: 2, formName: "mei" },
  kana_sei: { index: 3, formName: "kanaSei" },
  kana_mei: { index: 4, formName: "kanaMei" },
  contractor_sei: { index: 5, formName: "contractorSei" },
  contractor_mei: { index: 6, formName: "contractorMei" },
  email: { index: 7, formName: "email" },
  tel: { index: 8, formName: "tel" },
  zipcode: { index: 9, formName: "zipcode" },
  pref_name: { index: 10, formName: "prefName" },
  city_name: { index: 11, formName: "cityName" },
  address: { index: 12, formName: "address" },
  instagram_ownerinput: { index: 13, formName: "instagramOwnerinput" },
  birthday: { index: 14, formName: "birthday" },
  password: { index: 15, formName: "password" },
  passwordConfirm: { index: 16, formName: "passwordConfirm" },
};

type Props = {
  customer: Customer;
  setIsApplied: (isApplied: boolean) => void;
  setSigninData: Function;
};

const MemberEntryForm: React.FC<Props> = (props) => {
  const [validationSchema, setValidationSchema] = useState<
    yup.ObjectSchema<any>
  >(yup.object().shape({}));

  const {
    register,
    watch,
    setError,
    setFocus,
    trigger,
    setValue,
    getValues,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<TMemberEntryForm>({
    mode: "all",
    resolver: yupResolver(validationSchema),
  });

  const isApply = watch("apply", false);
  const [customMessage, setCustomMessage] = useState("");
  const [memberRegistrationFormSetting, setMemberRegistrationFormSetting] =
    useState<TMemberRegistrationFormSettingResponse | null>(null);
  const history = useHistory();
  const searchParams = new URLSearchParams(useLocation().search);
  const skipOption = searchParams.get("option");

  useEffect(() => {
    setValue("contractorSei", props.customer.last_name ?? "");
    setValue("contractorMei", props.customer.first_name ?? "");
    setValue("email", props.customer.email ?? "");
    setValue(
      "birthday",
      props.customer.birthday
        ? {
            year: props.customer.birthday.split("-")[0],
            month: props.customer.birthday.split("-")[1],
            day: props.customer.birthday.split("-")[2],
          }
        : { year: "", month: "", day: "" }
    );
    setValue("tel", props.customer.tel ?? "");
    setValue("zipcode", props.customer.zipcode ?? "");
    setValue("prefName", props.customer.pref_name ?? "");
    setValue("cityName", props.customer.city_name ?? "");
    setValue("address", props.customer.address ?? "");
  }, [props.customer, setValue]);

  const handlePostCodeBtnClick = () => {
    getAddressData(
      getValues("zipcode"),
      (prefecture: string, city: string, area: string, street: string) => {
        const address = `${area}${street}`.trim();
        setValue("prefName", prefecture);
        setValue("cityName", city);
        setValue("address", address);
        trigger("prefName");
        trigger("cityName");
        trigger("address");
      }
    );
  };
  const formatDate = (date: { year: string; month: string; day: string }) => {
    const { year, month, day } = date;

    if (!year || !month || !day) {
      return "";
    }

    const formattedMonth = month.padStart(2, "0");
    const formattedDay = day.padStart(2, "0");

    return `${year}-${formattedMonth}-${formattedDay}`;
  };

  const onSubmit: SubmitHandler<TMemberEntryForm> = useMultipleClickPreventer(
    () => {
      // passwordConfirmとapplyは会員登録に不要なリクエストパラメータなので抜く。
      const { passwordConfirm, apply, zipcode, birthday, ...formParam } =
        getValues();
      const newZipcode = zipcode ? zipcode.replace(/-/g, "") : "";
      const formattedBirthday = birthday
        ? formatDate({
            year: birthday.year,
            month: birthday.month,
            day: birthday.day,
          })
        : "";
      return postMemberRegister({
        ...formParam,
        zipcode: newZipcode,
        birthday: formattedBirthday,
        option: skipOption ?? "",
        ambassador_id: searchParams.get("id") ?? "",
      }).then(
        (
          res: TApiResult &
            TApiMemberRegisterResult &
            TApiMemberRegisterValidateErrors
        ) => {
          if (
            !res.isSuccess ||
            !res.result ||
            res.validateErrors.length !== 0
          ) {
            let focusedIndex = 16;
            let focusedFormName:
              | keyof typeof MEMBER_ENTEY_FORM_ITEM
              | undefined;
            res.validateErrors.forEach(({ key, messages }) => {
              const { formName, index } = transformRegisterErrorResult[key];

              focusedFormName =
                index < focusedIndex ? formName : focusedFormName;
              focusedIndex = index < focusedIndex ? index : focusedIndex;

              setError(formName, {
                type: "manual",
                message: messages[0],
              });
            });

            if (focusedFormName !== undefined) {
              setFocus(focusedFormName);
            }
          } else {
            props.setSigninData({
              email: formParam.email,
              password: formParam.password,
            });
            props.setIsApplied(true);
          }
        }
      );
    }
  );
  useEffect(() => {
    fetchMessageSetting(PANEL.memberEntry).then((res) => {
      if (!res.isSuccess || !res.result) return history.push(routes.error.path);
      const newMessage =
        res.result.length !== 0 ? res.result[0].letter_content : "";
      setCustomMessage(newMessage);
    });

    fetchMemberRegistrationFormSetting().then(
      (res: TApiResult & TMemberRegistrationFormSettingResult) => {
        if (res.isSuccess && res.result) {
          setMemberRegistrationFormSetting(
            res.result
              .filter((item) => item.is_display)
              .sort((a, b) => a.display_order - b.display_order)
          );
          const dynamicSchema = buildValidationSchema(res.result);
          setValidationSchema(dynamicSchema);
          reset(getValues());
        } else {
          setMemberRegistrationFormSetting(null);
        }
      }
    );
  }, [getValues, history, reset]);

  return (
    <div
      className={`${STMemberEntryFrom.member_entry_form} ${STUtils.container_form}`}
    >
      <h1 className={STMemberEntryFrom.member_entry_form_title}>
        新規会員登録
      </h1>
      <div className={STMemberEntryFrom.member_entry_form_custom_message}>
        {customMessage && <HtmlMessage message={customMessage} />}
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          {memberRegistrationFormSetting?.map((field) => (
            <FieldComponent
              key={field.id}
              field={field}
              register={register}
              errors={errors}
              setValue={setValue}
              trigger={trigger}
              getValues={getValues}
              handlePostCodeBtnClick={handlePostCodeBtnClick}
              props={props}
              emailReadOnly={skipOption === "skip"} // 承認スキップのルートはemailが編集可能になる
            />
          ))}
        </div>
        <div className={STMemberEntryFrom.member_entry_form_password_message}>
          <Link
            to={routes.term.path}
            target="_blank"
            className={STMemberEntryFrom.member_entry_card_agreement_text_link}
          >
            規約
          </Link>
          および
          <Link
            to={routes.privacy.path}
            target="_blank"
            className={STMemberEntryFrom.member_entry_card_agreement_text_link}
          >
            プライバシーポリシー
          </Link>
          について確認の上、同意します。
          <input
            type="checkbox"
            id="apply"
            {...register("apply")}
            className={`${STMemberEntryFrom.member_entry_card_agreement_checkbox} ${STMemberEntryFrom.agreement_checkbox}`}
            data-testid="entry-form_apply"
          />
          <label
            className={STMemberEntryFrom.member_entry_card_agreement_label}
            htmlFor="apply"
          >
            同意する
          </label>
          {errors.apply && (
            <ErrorMessage name="apply" message={errors.apply?.message ?? ""} />
          )}
        </div>
        <div
          className={
            isApply
              ? `${STMemberEntryFrom.submit_container} ${STButton.primary}`
              : `${STMemberEntryFrom.submit_container} ${STButton.disable}`
          }
        >
          <button
            type="submit"
            disabled={!isApply}
            data-testid="entry-form_submit"
            className={`${STMemberEntryFrom.submit_button}`}
          >
            登録
          </button>
        </div>
      </form>
    </div>
  );
};
export default MemberEntryForm;

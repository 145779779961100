import { AxiosError } from "axios";
import client from "../../api/client";
import TApiResult from "../../types/api/TApiResult";
import getErrorProperty from "./config/getErrorProperty";
import { TAppFormError } from "../../types/TAppFormError";
import { TAppTrackingRequestFormItem } from "../../types/TAppTracking";
import { getAuth } from "firebase/auth";

export type TApiTrackingResult = {
  result?: {
    res: {};
  };
};

export type TApiTrackingValidateErrors = {
  validateErrors: TAppFormError<TAppTrackingRequestFormItem>[];
};

export const postTracking = async (param: {
  uid: string | null;
  referrer_uid: string | null;
  session_uuid: string;
  url: string;
  tracking_flg: number;
  track_event_name: string;
  click_tag: string;
  referrer: string;
  share_url?: string;
}): Promise<TApiResult & TApiTrackingValidateErrors> => {
  const auth = getAuth();
  const token = await auth.currentUser?.getIdToken();
  return client()
    .tracking.post({
      body: {
        uid: param.uid,
        referrer_uid: param.referrer_uid,
        session_uuid: param.session_uuid,
        url: param.url,
        tracking_flg: param.tracking_flg,
        track_event_name: param.track_event_name,
        click_tag: param.click_tag,
        referrer: param.referrer,
        share_url: param.share_url,
      },
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      return {
        isSuccess: true,
        status: 200,
        message: "",
        validateErrors: [],
      };
    })
    .catch((e: AxiosError) => {
      const { status, message, errorMessages } = getErrorProperty(
        e,
        "postReservation"
      );
      if (!errorMessages) {
        return {
          isSuccess: false,
          status: status,
          message: message,
          validateErrors: [],
        };
      }

      const validateErrors: TAppFormError<TAppTrackingRequestFormItem>[] =
        errorMessages.details.map((d) => {
          return {
            key: d.key as TAppTrackingRequestFormItem,
            messages: d.values,
          };
        });

      return {
        isSuccess: false,
        status: status,
        message: "",
        validateErrors: validateErrors,
      };
    });
};

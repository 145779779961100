// NOTE: https://qiita.com/saba_can00/items/bdefb28a1873658cf5d9
export const STATIC_PAGE_SLUG = {
  beginners: "beginners",
  faq: "faq",
  term: "kiyaku",
  privacy: "privacy",
  memberPremium: "member_premium",
  referral: "referral",
  survey: "survey",
  surveyKodate: "survey_kodate",
  surveyMansion: "survey_mansion",
} as const;

type TypeOfStaticPageSlug = typeof STATIC_PAGE_SLUG;
export type TAppStaticPageSlug =
  TypeOfStaticPageSlug[keyof TypeOfStaticPageSlug];

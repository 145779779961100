import React, { useContext } from 'react'
import { SiteContext } from '../../../providers/SiteProvider';

const ArrowRightButtonOrange = () => {
  const mainColor = useContext(SiteContext).mainColor;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <mask id="mask0_108_2114" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
            <rect width="22" height="22" fill="#D9D9D9"/>
        </mask>
        <g mask="url(#mask0_108_2114)">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.29289 3.29289C5.68342 2.90237 6.31658 2.90237 6.70711 3.29289L10.7071 7.29289C11.0976 7.68342 11.0976 8.31658 10.7071 8.70711L6.70711 12.7071C6.31658 13.0976 5.68342 13.0976 5.29289 12.7071C4.90237 12.3166 4.90237 11.6834 5.29289 11.2929L8.58579 8L5.29289 4.70711C4.90237 4.31658 4.90237 3.68342 5.29289 3.29289Z" fill={mainColor}/>
        </g>
    </svg>
  )
}

export default ArrowRightButtonOrange;

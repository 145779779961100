import STUtils from "../../assets/styles/utility.module.scss";
import STMemberEntryApplyCard from "../../assets/styles/organisms/memberEntryApplyCard.module.scss";
import ButtonLink from "../atoms/ButtonLink";
import STButton from "../../assets/styles/atoms/button.module.scss";
import { routes } from "../../router/Router";

interface Props {
  message: {
    title: string;
    text: string;
  };
}
const MemberIdentificationCard = ({ message }: Props) => {
  return (
    <div className={STUtils.container_form}>
      <h1 className={STMemberEntryApplyCard.title}>{message.title}</h1>
      <section>
        <h2 className={STMemberEntryApplyCard.text}>{message.text}</h2>
        <ButtonLink
          url={routes.top.path}
          text="TOPページ"
          buttonClassName={`${STMemberEntryApplyCard.btn} ${STButton.tertiary}`}
        />
      </section>
    </div>
  );
};

export default MemberIdentificationCard;

import STTimeRangeList from "../../assets/styles/molecules/timeRangeList.module.scss";
import { TAppTimeRange } from "../../types/TAppTimeRange";
import TimeRangeButton from "../atoms/TimeRangeButton";

type Props = {
  timeRanges: TAppTimeRange[];
  selected: TAppTimeRange;
  setDateTimeSelected: (selected: boolean) => void;
  setTimeRange: (ranges: TAppTimeRange) => void;
};

const TimeRangeList: React.FC<Props> = (props: Props) => {
  const hundleClick = (timeRange: TAppTimeRange) => {
    props.setTimeRange(timeRange);
    props.setDateTimeSelected(true);
  };

  const isSelected = (timeRange: TAppTimeRange): boolean => {
    return props.selected.time === timeRange.time;
  };

  return (
    <div className={STTimeRangeList.time_range}>
      <ol className={STTimeRangeList.list}>
        {props.timeRanges.map((timeRange, index) => (
          <TimeRangeButton
            key={index}
            timeRange={timeRange}
            isSelected={isSelected(timeRange)}
            setTimeRange={hundleClick}
          />
        ))}
      </ol>
    </div>
  );
};

export default TimeRangeList;

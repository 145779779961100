import { TAppFormError } from "../../../types/TAppFormError";
import Text from "../../atoms/form/Text";
import TableRow from "../../atoms/TableRow";
import STSingleTextRow from "../../../assets/styles/molecules/formTableRow/singleTextRow.module.scss";
import AlartMessages from "../../atoms/AlartMessages";
import validateFormRow from "../../../utils/form/validateFormRow";

type Props<T> = {
  title: string;
  subTitle?: string;
  placeholder?: string;
  defaultValue?: string;
  required?: boolean;
  formKey: T;
  errors: TAppFormError<T>[];
  setErrors: (errors: TAppFormError<T>[]) => void;
  hundleOnChange: (value: string) => void;
  isDisplay?: boolean;
  canChange?: boolean;
  maxLength?: number;
  minLength?: number;
  pattern?: RegExp;
  patternText?: string;
};

const SingleTextRow = <T,>({
  title,
  subTitle,
  placeholder,
  defaultValue,
  required = false,
  formKey,
  errors,
  setErrors,
  hundleOnChange,
  isDisplay = true,
  canChange = true,
  maxLength,
  minLength,
  pattern,
  patternText,
}: Props<T>) => {
  const hundleChange = (input: string) => {
    validateFormRow<T>({
      input,
      errors,
      setErrors,
      formKey,
      maxLength,
      minLength,
      pattern,
      patternText,
      required,
    });
    hundleOnChange(input);
  };
  if (!isDisplay) return <></>;
  return (
    <TableRow title={title} subTitle={subTitle} required={required}>
      <span>
        <Text
          className={canChange ? STSingleTextRow.form : STSingleTextRow.disable}
          name={title}
          defaultValue={defaultValue}
          placeholder={placeholder}
          onChange={(input) => hundleChange(input)}
        />
      </span>
      {AlartMessages<T>({
        title: title,
        errors: errors,
        keys: [formKey],
      })}
    </TableRow>
  );
};

export default SingleTextRow;

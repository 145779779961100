import * as React from "react";
import { SVGProps } from "react";

const SvgNext = (props: SVGProps<SVGSVGElement> & { color?: string }) => (
  <svg
    width={20}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5 16.107 11.107 10 5 3.88 6.88 2l8 8-8 8L5 16.107Z"
      fill={props.color ?? "#337E62"}
    />
  </svg>
);

export default SvgNext;

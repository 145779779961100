import {
  Box,
  Divider,
  Drawer,
  IconButton,
  Typography,
  makeStyles,
} from "@material-ui/core";
import Image from "../atoms/Image";
import PAGE_CONFIG from "../../config/page";
import { Close } from "@material-ui/icons";
import { useContext, useState } from "react";
import getImageUrl from "../../utils/getImageUrl";
import { IoShareOutline } from "react-icons/io5";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import { getSubdomain } from "../../utils/getSubdomain";
import { SiteContext } from "../../providers/SiteProvider";
import { useIsMobile } from "../../hooks/useIsMobile";
import { useOS } from "../../hooks/useOS";

const useStyles = makeStyles(() => ({
  drawer: {
    backgroundColor: "#fff",
  },
  title: {
    color: "#333",
    fontWeight: 600,
    fontSize: "15px",
    marginBottom: "2px",
  },
  popupDescription: {
    fontSize: "12px",
    lineHeight: "15px",
    color: "#333",
    whiteSpace: "pre-wrap",
    wordBreak: "break-word",
    alignItems: "center",
  },
  descriptionTitle: {
    fontSize: "15px",
    color: "#333",
    whiteSpace: "pre-wrap",
    paddingBottom: "12px",
  },
  closeIconButton: {
    padding: 0,
    position: "absolute",
    right: 12,
  },
  appIcon: {
    marginRight: "16px",
    width: 52,
    height: 52,
    borderRadius: "8px",
    objectFit: 'contain'
  },
  description: {
    color: "#333333",
    fontSize: "12px",
    fontWeight: 700,
  },
  icon: {
    width: 20,
    height: 20,
  },
  showDescriptionButton: {
    backgroundColor: "#fff",
    marginTop: "20px",
    fontSize: "18px",
    justifyContent: "flex-start",
    borderRadius: "8px",
    padding: "12px 16px",
    border: "1px solid rgb(197,189,196)",
  },
  backdrop: {
    backgroundColor: "rgba(0, 0, 0, 0.4)",
  },
  divider: {
    backgroundColor: "rgb(197,189,196)",
  },
  drawerContent: {
    display: "flex",
    padding: "20px",
    alignItems: "start",
    borderRadius: "8px 8px 0 0",
  },
  drawerInnerContent: {
    padding: "12px 24px",
  },
  hideDescriptionPadding: {
    paddingTop: "12px",
    paddingBottom: "12px",
  },
  flexColumnGap: {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
  },
  flexRowAlignCenter: {
    display: "flex",
    alignItems: "center",
  },
}));

type Props = {
  popupOpen: boolean;
  onPopupClose: () => void;
};

const key = window.location.search.split('=')[1]?.startsWith('site') ? window.location.search.split('=')[1] : '';

export const HomePopup = ({ popupOpen, onPopupClose }: Props) => {
  const { homePopupDescription, name: siteName } = useContext(SiteContext);

  const classes = useStyles();

  const [showDescription, setShowDescription] = useState(true);
  const subdomain = getSubdomain(window.location.hostname);

  const { isSafari } = useIsMobile();
  const {isWindows, isAndroid} = useOS();
  const site = useContext(SiteContext);

  const appIconImageUrl = !key ? 
    getImageUrl(
      subdomain,
      PAGE_CONFIG.IMAGE_URL_PATH.SITE,
      (isWindows || isAndroid) ? PAGE_CONFIG.FAVICON : PAGE_CONFIG.APPLE_TOUCH_ICON
    ) :
    (isWindows || isAndroid) ? site.favicoPC : site.favicoIOS;

  const onClose = () => {
    localStorage.setItem("isViewedHomePopup", "true");
    onPopupClose();
  };

  return (
    <>
      <Drawer
        anchor="bottom"
        open={popupOpen}
        onClose={onClose}
        BackdropProps={{ className: classes.backdrop }}
        PaperProps={{ className: classes.drawer }}
      >
        <Box className={classes.drawerContent}>
          <Image
            imageUrl={appIconImageUrl}
            alt="favicon.ico"
            className={classes.appIcon}
          />
          <Box>
            <Typography className={classes.title}>{siteName}</Typography>
            <Typography noWrap className={classes.popupDescription}>
              {homePopupDescription}
            </Typography>
          </Box>
          <IconButton className={classes.closeIconButton} onClick={onClose}>
            <Close fontSize="small" />
          </IconButton>
        </Box>
        <Divider className={classes.divider} />

        <Box
          className={`${classes.drawerInnerContent} ${
            !showDescription && classes.hideDescriptionPadding
          }`}
        >
          {showDescription && (
            <Box>
              <Typography className={classes.descriptionTitle}>
                【ホーム画面への追加方法】
              </Typography>
              {isSafari ? (
                <Box className={classes.flexColumnGap}>
                  <Box className={classes.flexRowAlignCenter}>
                    <Typography className={classes.description}>
                      1)　画面上や画面下にある、ナビゲーションバーで
                      <IoShareOutline
                        className={classes.icon}
                        color="#517bfc"
                      />
                      を押す
                    </Typography>
                  </Box>

                  <Box className={classes.flexRowAlignCenter}>
                    <Typography className={classes.description}>
                      2) 「ホーム画面に追加」 を押す
                    </Typography>
                  </Box>
                </Box>
              ) : (
                <Box className={classes.flexColumnGap}>
                  <Box className={classes.flexRowAlignCenter}>
                    <Typography className={classes.description}>
                      1) 画面上や画面下にあるメニュー
                      <PiDotsThreeOutlineVerticalFill
                        className={classes.icon}
                        color="#666"
                      />
                      を開く
                    </Typography>
                  </Box>

                  <Box className={classes.flexRowAlignCenter}>
                    <Typography className={classes.description}>
                      2)「ホーム画面に追加」を押す
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>
          )}
          <div
            onClick={() => setShowDescription((prev) => !prev)}
            className={classes.showDescriptionButton}
          >
            <Typography style={{ textAlign: "left" }}>
              {!showDescription ? "設定方法を表示する " : "設定方法を閉じる "}
            </Typography>
          </div>
        </Box>
      </Drawer>
    </>
  );
};

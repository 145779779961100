import client from "../../api/client";
import TApiResult from "../../types/api/TApiResult";
import { AxiosError } from "axios";
import getErrorProperty from "./config/getErrorProperty";
import { TMemberDetailResponse } from "../../api/member/detail";
import { getAuth } from "firebase/auth";
import tokenNotFound from "./config/tokenNotFound";

export type TApiMemberDetailResult = {
  result?: TMemberDetailResponse;
};

export const fetchMemberDetail = async (): Promise<
  TApiResult & TApiMemberDetailResult
> => {
  const auth = getAuth();
  const token = await auth.currentUser?.getIdToken();
  if (!token) return tokenNotFound;
  return client()
    .member.detail.get({
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      return {
        isSuccess: true,
        status: 200,
        message: "",
        result: res.body,
      };
    })
    .catch((e: AxiosError) => {
      const { status, message } = getErrorProperty(e, "fetchMemberDetail");
      return {
        isSuccess: false,
        status: status,
        message: message,
      };
    });
};

import getImageUrl from "../../utils/getImageUrl";
import PAGE_CONFIG from "../../config/page";
import styled from "styled-components";
import { getSubdomain } from "../../utils/getSubdomain";

const subdomain = getSubdomain(window.location.hostname);
const Container = styled.div`
  @media only screen and (max-width: 799px) {
    background-image: url(${getImageUrl(
        subdomain,
        PAGE_CONFIG.IMAGE_URL_PATH.SITE,
        PAGE_CONFIG.BACKGROUND_LOGIN_SP
      )}),
      url(${getImageUrl(
        subdomain,
        PAGE_CONFIG.IMAGE_URL_PATH.SITE,
        PAGE_CONFIG.BACKGROUND_LOGIN
      )});
    min-height: auto;
  }
  background-image: url(${getImageUrl(
    subdomain,
    PAGE_CONFIG.IMAGE_URL_PATH.SITE,
    PAGE_CONFIG.BACKGROUND_LOGIN
  )});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
  height: 100vh;
`;

const LoginBackground: React.FC = () => {
  return <Container />;
};

export default LoginBackground;

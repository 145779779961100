import {
  FieldErrors,
  FieldValues,
  Path,
  UseFormRegister,
} from "react-hook-form";
import Label from "../../atoms/form/reactHookForms/Label";
import InputField from "../../atoms/form/reactHookForms/InputField";
import ErrorMessage from "../../atoms/form/reactHookForms/ErrorMessage";
import OptionMessage from "./OptionMessage";

type Props<T extends FieldValues> = {
  label: string;
  formName: Path<T>;
  register: UseFormRegister<T>;
  errors: FieldErrors<T>;
  placeholder: string;
  required?: boolean;
  isReadOnly?: boolean;
  defaultValue?: string;
  optionInformation?: string;
};

const TextForm = <T extends FieldValues>(props: Props<T>) => {
  const {
    label,
    formName,
    register,
    errors,
    required,
    placeholder,
    isReadOnly = false,
    defaultValue,
    optionInformation,
  } = props;

  const isErrors = !!errors[formName];
  const errorMessage = errors[formName]?.message as string;

  return (
    <>
      <Label label={label} htmlFor={formName} required={required} />
      {optionInformation !== undefined && (
        <OptionMessage information={optionInformation} />
      )}
      <InputField<T>
        formName={formName}
        register={register}
        isErrors={isErrors}
        placeholder={placeholder}
        defaultValue={defaultValue}
        isReadOnly={isReadOnly}
      />
      {isErrors && <ErrorMessage name={formName} message={errorMessage} />}
    </>
  );
};

export default TextForm;

import React, { useEffect } from "react";
import { Dialog, DialogContent } from "@material-ui/core";
import STVideoModal from "../../assets/styles/organisms/videoModal.module.scss";

interface ModalProps {
  onClose: () => void;
  EmbedComponent: any;
  isModalOpen: boolean;
}

const VideoModal: React.FC<ModalProps> = ({
  onClose,
  EmbedComponent,
  isModalOpen,
}) => {
  useEffect(() => {
    // Instagramのスクリプト再実行
    if (
      EmbedComponent &&
      EmbedComponent.props.content &&
      EmbedComponent.props.content.includes("instagram.com")
    ) {
      if ((window as any).instgrm) {
        (window as any).instgrm.Embeds.process();
      }
    }
  }, [EmbedComponent, isModalOpen]);
  return (
    <Dialog
      open={isModalOpen}
      onClose={onClose}
      className={STVideoModal.dialog}
    >
      <DialogContent className={STVideoModal.content}>
        {EmbedComponent}
      </DialogContent>
    </Dialog>
  );
};

export default VideoModal;

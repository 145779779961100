import { Box, makeStyles, Button, Link } from "@material-ui/core"
import Image from "../atoms/Image";
import { TBannerSettingResponse } from "../../api/banner_setting";
const useStyles = makeStyles((theme) => ({
  detailButton: {
    fontWeight: "bold",
    borderRadius: "100px",
    margin: '32px auto auto auto',
    width: '200px',
    fontSize: '16px',
    padding: '12px 50px',
    background: '#f50057',
    ' &:hover': {
      opacity: '0.6'
    }
  },
  text: {
    width: '223px',
    fontSize: '14px',
    margin: 'auto',
    padding: '16px 0 0 0',
    textAlign: 'center',
    whiteSpace: 'pre-wrap'
  },

  closeIcon: {
    width: '20px',
    height: '20px',
    position: 'absolute',
    top: '4px',
    right: '4px'
  },
  closeButton: {
    color: "#2D3748",
    fontSize: '14px',
    fontWeight: 500,
    margin: '32px auto auto auto',
    "&:hover": {
      background: "none"
    },
  },
  bannerImg: {
    maxWidth: '300px',
    width: '100%',
    height: 'auto',
    objectFit: 'cover',
    cursor: 'pointer'
  }
}));
type Props = {
  banner: TBannerSettingResponse,
  handleClosePopup: () => void,
  saveInvisibleDate: () => void,
  updateInvisibleUpdatedAt: () => void,
}
const PopupBanner = ({
  banner,
  handleClosePopup,
  saveInvisibleDate,
  updateInvisibleUpdatedAt
}: Props) => {
  const classes = useStyles()

  const handlePopupClose = () => {
    //設定情報の変更がない限り永久非表示
    updateInvisibleUpdatedAt()
    handleClosePopup()
  };
  const openDetailPage = () => {
    //24時間非表示
    saveInvisibleDate()
    handleClosePopup()
  }

  if(!banner.img_url){
    return <></>
  }

  return (
    <>
      <Image imageUrl={banner.img_url} alt={'ポップアップ画像'} className={classes.bannerImg} shownDefault={true} />
      <Box style={{ paddingBottom: "32px" }}>
        {banner.description && <Box className={classes.text}>{banner.description}</Box>}
        <Box textAlign={"center"} display={"flex"} flexDirection={"column"}>
          {banner.link_url &&
              <Link
                className={classes.detailButton}
                color="secondary"
                href={banner.link_url}
                style={{ color: '#fff', textDecoration: 'none', fontWeight: '700', fontSize: '14px'}}
                target="_blank"
                onClick={openDetailPage}
              >
                詳細を見る
              </Link>
            }
          <Button className={classes.closeButton} onClick={handlePopupClose}>閉じる</Button>
        </Box>
      </Box>
    </>
  )

}
export default PopupBanner
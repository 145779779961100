import CONSTANTS from "../constants";

export const validateEmail = (email: string, needApply: boolean = false) => {
  let emailErrorMessage = "";
  let apply = true;

  // eslint-disable-next-line no-control-regex
  if (/[^\x00-\x7F]+/.test(email)) {
    emailErrorMessage = "半角のみで入力してください";
    apply = false;
  } else if (
    email &&
    !new RegExp(CONSTANTS.PATTERN.EMAIL_NOT_RFC).test(email)
  ) {
    emailErrorMessage = "メールアドレスの形式で入力してください";
    apply = false;
  } else if (email && email.length > CONSTANTS.MAX_LENGTH.EMAIL) {
    emailErrorMessage = "255文字以下で入力してください";
    apply = false;
  }

  return needApply ? { emailErrorMessage, apply } : { emailErrorMessage };
};

import { ReactElement } from "react";
import PAGE_CONFIG from "../../config/page";
import getImageUrl from "../../utils/getImageUrl";
import { getSubdomain } from "../../utils/getSubdomain";
import { useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core';

type Props = {
  hasBackground: boolean;
  children: ReactElement[] | ReactElement;
};

const subdomain = getSubdomain(window.location.hostname);

const topBgImageUrl = getImageUrl(
  subdomain,
  PAGE_CONFIG.IMAGE_URL_PATH.SITE,
  PAGE_CONFIG.BACKGROUND_MYPAGE_1
);
const bottomBgImageUrl = getImageUrl(
  subdomain,
  PAGE_CONFIG.IMAGE_URL_PATH.SITE,
  PAGE_CONFIG.BACKGROUND_MYPAGE_2
);
const topSpBgImageUrl = getImageUrl(
  subdomain,
  PAGE_CONFIG.IMAGE_URL_PATH.SITE,
  PAGE_CONFIG.BACKGROUND_MYPAGE_1_SP
);
const bottomSpBgImageUrl = getImageUrl(
  subdomain,
  PAGE_CONFIG.IMAGE_URL_PATH.SITE,
  PAGE_CONFIG.BACKGROUND_MYPAGE_2_SP
);

const useStyles = makeStyles((theme) => ({
  backgroundDiv: {
    backgroundImage: `url(${topBgImageUrl}), url(${bottomBgImageUrl})`,
    backgroundSize: '500px auto, 400px auto',
    backgroundPosition: 'top 120px left, bottom 120px right',
    backgroundRepeat: 'no-repeat',
    minHeight: '100vh',
    "@media (max-width: 799px)": {
      backgroundSize: '200px auto, 170px auto',
      backgroundPosition: 'top 50px left, bottom 170px right',
    },
  },
  backgroundDivSP: {
    "@media (max-width: 799px)": {
      backgroundImage: `url(${topSpBgImageUrl}), url(${bottomSpBgImageUrl})`,
    },
  },
  backgroundDivTop: {
    "@media (max-width: 799px)": {
      backgroundImage: `url(${topSpBgImageUrl}), url(${bottomBgImageUrl})`,
    },
  },
  backgroundDivBottom: {
    "@media (max-width: 799px)": {
      backgroundImage: `url(${topBgImageUrl}), url(${bottomSpBgImageUrl})`,
    },
  },
}));

const MypageBackground: React.FC<Props> = ({
  hasBackground,
  children,
}: Props) => {
  const [loadedTop, setLoadedTop] = useState(false);
  const [loadedBottom, setLoadedBottom] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    const imgTop = new Image();
    imgTop.src = topSpBgImageUrl;
    imgTop.onload = () => setLoadedTop(true);
    imgTop.onerror = () => setLoadedTop(false);
    const imgBottom = new Image();
    imgBottom.src = bottomSpBgImageUrl;
    imgBottom.onload = () => setLoadedBottom(true);
    imgBottom.onerror = () => setLoadedBottom(false);
  }, []);

  const getStyleClass = () => {
    let styleClass = classes.backgroundDiv + ' '
    if (loadedTop && loadedBottom) {
      styleClass += classes.backgroundDivSP
    } else if(loadedTop && !loadedBottom){
      styleClass += classes.backgroundDivTop
    } else if(!loadedTop && loadedBottom){
      styleClass += classes.backgroundDivBottom
    }
    return styleClass
  }

  return (
    <>{hasBackground ? <>{children}</> : <div className={getStyleClass()}>{children}</div>}</>
  )
};
  
export default MypageBackground;

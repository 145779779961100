import { AxiosError } from "axios";
import client from "../../api/client";
import TApiResult from "../../types/api/TApiResult";
import getErrorProperty from "./config/getErrorProperty";
import { TAppFormError } from "../../types/TAppFormError";
import { TAppEventInvitationFormItem } from "../../types/TAppEventInvitationFormItem";

export type TApiMemberRegisterResult = {
  result?: {
    res: {};
  };
};

export type TApiReservationValidateErrors = {
  validateErrors: TAppFormError<TAppEventInvitationFormItem>[];
};

export const postReservation = async (param: {
  eventId: number;
  introducerName: string;
  introducerRelated: string;
  sei: string;
  mei: string;
  kanaSei: string;
  kanaMei: string;
  tel: string;
  email: string;
  zipcode: string;
  prefName: string;
  cityName: string;
  address: string;
  adultCount: string;
  childCount: string;
  note: string;
  freeItem: string;
  location: string;
  status: boolean;
  reservationDate: string | null;
  reservationTime: string | null;
}): Promise<TApiResult & TApiReservationValidateErrors> => {
  return client()
    .reservations.post({
      body: {
        event_id: param.eventId,
        sei: param.sei,
        mei: param.mei,
        kana_sei: param.kanaSei,
        kana_mei: param.kanaMei,
        tel: param.tel,
        email: param.email,
        zipcode: param.zipcode,
        pref_name: param.prefName,
        city_name: param.cityName,
        address: param.address,
        adult_count: Number(param.adultCount),
        children_count: Number(param.childCount),
        location: param.location,
        free_item: param.freeItem,
        note: param.note,
        introducer_name: param.introducerName,
        introducer_related: param.introducerRelated,
        status: true,
        reservation_date: param.reservationDate,
        reservation_time: param.reservationTime,
      },
    })
    .then((res) => {
      return {
        isSuccess: true,
        status: 200,
        message: "",
        validateErrors: [],
      };
    })
    .catch((e: AxiosError) => {
      const { status, message, errorMessages } = getErrorProperty(
        e,
        "postReservation"
      );
      if (!errorMessages) {
        return {
          isSuccess: false,
          status: status,
          message: message,
          validateErrors: [],
        };
      }

      const validateErrors: TAppFormError<TAppEventInvitationFormItem>[] =
        errorMessages.details.map((d) => {
          return {
            key: d.key as TAppEventInvitationFormItem,
            messages: d.values,
          };
        });

      return {
        isSuccess: false,
        status: status,
        message: "",
        validateErrors: validateErrors,
      };
    });
};

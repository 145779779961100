import { TAppFormError } from "../../../types/TAppFormError";
import TableRow from "../../atoms/TableRow";
import STMultiPullDownRow from "../../../assets/styles/molecules/formTableRow/multiPullDownRow.module.scss";
import { selectBoxDateOption } from "../../../types/form/selectBoxDateOption";
import AlartMessages from "../../atoms/AlartMessages";
import validateFormRow from "../../../utils/form/validateFormRow";
import { useEffect, useState } from "react";

type Props<T> = {
  title: string;
  subTitle?: string;
  defaultValue?: Date;
  options: selectBoxDateOption;
  required?: boolean;
  formKey: T;
  errors: TAppFormError<T>[];
  setErrors: (errors: TAppFormError<T>[]) => void;
  hundleOnChange: (value: Date) => void;
  isDisplay?: boolean;
  canChange?: boolean;
};

const MultiPullDownRow = <T,>({
  title,
  subTitle,
  defaultValue,
  options,
  required = false,
  formKey,
  errors,
  setErrors,
  hundleOnChange,
  isDisplay = true,
  canChange = true,
}: Props<T>) => {
  let default_year = defaultValue ? defaultValue.getFullYear().toString() : "";
  let default_month = defaultValue
    ? (defaultValue.getMonth() + 1).toString()
    : "";
  let default_day = defaultValue ? defaultValue.getDate().toString() : "";

  const [birthYear, setBirthYear] = useState<string>(default_year);
  const [birthMonth, setBirthMonth] = useState<string>(default_month);
  const [birthDay, setBirthDay] = useState<string>(default_day);

  useEffect(() => {
    setBirthYear(default_year);
    setBirthMonth(default_month);
    setBirthDay(default_day);
  }, [defaultValue]);

  const isValidDate = (year: string, month: string, date: string) => {
    return year !== "" && month !== "" && date !== "";
  };

  const selectBirthYear: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
    setBirthYear(e.target.value);
    if (!isValidDate(e.target.value, birthMonth, birthDay)) return;
    hundleChange(new Date(`${e.target.value}/${birthMonth}/${birthDay}`));
  };
  const selectBirthMonth: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
    setBirthMonth(e.target.value);
    if (!isValidDate(birthYear, e.target.value, birthDay)) return;
    hundleChange(new Date(`${birthYear}/${e.target.value}/${birthDay}`));
  };
  const selectBirthDay: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
    setBirthDay(e.target.value);

    if (!isValidDate(birthYear, birthMonth, e.target.value)) return;
    hundleChange(new Date(`${birthYear}/${birthMonth}/${e.target.value}`));
  };

  const hundleChange = (inputDate: Date | null) => {
    if (!inputDate) return;
    const input = inputDate.toDateString();
    validateFormRow<T>({
      input,
      errors,
      setErrors,
      formKey,
      required,
    });
    hundleOnChange(inputDate);
  };

  if (!isDisplay) return <></>;
  return (
    <TableRow key={title} title={title} subTitle={subTitle} required={required}>
      <label>
        a
        <select
          onChange={selectBirthYear}
          value={birthYear}
          className={
            canChange ? STMultiPullDownRow.form : STMultiPullDownRow.disable
          }
        >
          {!defaultValue && <option key="-1"></option>}
          {options.years.map((year, year_index) => (
            <option key={year_index}>{year}</option>
          ))}
        </select>
        <span className={STMultiPullDownRow.unit}>年</span>
      </label>
      <label>
        <select
          onChange={selectBirthMonth}
          value={birthMonth}
          className={
            canChange ? STMultiPullDownRow.form : STMultiPullDownRow.disable
          }
        >
          {!defaultValue && <option key="-1"></option>}
          {options.months.map((month, month_index) => (
            <option key={month_index}>{month}</option>
          ))}
        </select>
        <span className={STMultiPullDownRow.unit}>月</span>
      </label>
      <label>
        <select
          onChange={selectBirthDay}
          value={birthDay}
          className={
            canChange ? STMultiPullDownRow.form : STMultiPullDownRow.disable
          }
        >
          {!defaultValue && <option key="-1"></option>}
          {options.days.map((day, day_index) => (
            <option key={day_index}>{day}</option>
          ))}
        </select>
        <span className={STMultiPullDownRow.unit}>日</span>
      </label>
      {AlartMessages<T>({
        title: title,
        errors: errors,
        keys: [formKey],
      })}
    </TableRow>
  );
};

export default MultiPullDownRow;

import TApiResult from "../../../types/api/TApiResult";
import { TApiMemberUpdateValidateErrors } from "../postMemberUpdate";

const tokenNotFoundWithValidateErrors: Promise<
  TApiResult & TApiMemberUpdateValidateErrors
> = new Promise((resolve, reject) =>
  resolve({
    isSuccess: false,
    status: 401,
    message: "tokenNotFound",
    validateErrors: [],
  })
);

export default tokenNotFoundWithValidateErrors;

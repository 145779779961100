import React from "react";
import STMemberEntryFrom from "../../assets/styles/organisms/memberEntryForm.module.scss";
import TextForm from "../molecules/reactHookForms/TextForm";
import PasswordForm from "../molecules/reactHookForms/PasswordForm";
import SelectForm from "../molecules/reactHookForms/SelectForm";
import { TAppPrefectureNames } from "../../types/TAppPrefectureNames";
import { SelectBoxOption } from "../../types/form/selectBoxOption";
import DualInputForm from "../molecules/reactHookForms/DualInputForm";
import TextFormWithButton from "../molecules/reactHookForms/TextFormWithButton";
import { days, months, years } from "../atoms/SelectDate";
import { DatePullDownRow } from "../molecules/formTableRow/DatePullDownRow";

export const FieldComponent: React.FC<any> = ({
  field,
  register,
  errors,
  setValue,
  trigger,
  getValues,
  handlePostCodeBtnClick,
  props,
}) => {
  const options: SelectBoxOption[] = TAppPrefectureNames.map((v, i) => {
    return { id: i, value: v, title: v };
  });
  switch (field.physical_name) {
    case "sei_mei":
      const isNameField =
        field.logical_name.includes("（") && field.logical_name.includes("）");
      const parentName = isNameField
        ? field.logical_name.split("（")[0]
        : field.logical_name;
      const childrenNames = isNameField
        ? field.logical_name.split("（")[1].split("）")[0].split("・")
        : ["姓", "名"];
      return (
        <DualInputForm
          label={{
            parent: parentName,
            children: {
              first: childrenNames[0], // "姓"
              second: childrenNames[1], // "名"
            },
          }}
          formName={{
            first: "sei",
            second: "mei",
          }}
          register={register}
          errors={errors}
          className={STMemberEntryFrom.member_entry_form_container}
          required={field.is_required}
          placeholder={{
            first: "田中",
            second: "太郎",
          }}
        />
      );

    case "kana_sei_mei":
      const isKanaField =
        field.logical_name.includes("（") && field.logical_name.includes("）");
      const kanaParent = isKanaField
        ? field.logical_name.split("（")[0]
        : field.logical_name;
      const kanaChildren = isKanaField
        ? field.logical_name.split("（")[1].split("）")[0].split("・")
        : ["", ""];
      return (
        <DualInputForm
          label={{
            parent: kanaParent,
            children: {
              first: kanaChildren[0], // "姓"
              second: kanaChildren[1], // "名"
            },
          }}
          formName={{
            first: "kanaSei",
            second: "kanaMei",
          }}
          register={register}
          errors={errors}
          className={STMemberEntryFrom.member_entry_form_container}
          required={field.is_required}
          placeholder={{
            first: "たなか",
            second: "たろう",
          }}
        />
      );
    case "contractor_sei_mei":
      const isContractorField =
        field.logical_name.includes("（") && field.logical_name.includes("）");
      const contractorParent = isContractorField
        ? field.logical_name.split("（")[0]
        : field.logical_name;
      const contractorChildren = isContractorField
        ? field.logical_name.split("（")[1].split("）")[0].split("・")
        : ["", ""];
      return (
        <DualInputForm
          label={{
            parent: contractorParent,
            children: {
              first: contractorChildren[0], // "姓"
              second: contractorChildren[1], // "名"
            },
          }}
          formName={{
            first: "contractorSei",
            second: "contractorMei",
          }}
          register={register}
          errors={errors}
          required={field.is_required}
          className={STMemberEntryFrom.member_entry_form_container}
          placeholder={{
            first: "田中",
            second: "太郎",
          }}
          optionInformation="ご本人様と異なる場合のみ、ご入力ください。"
        />
      );
    case "email":
      return (
        <div className={STMemberEntryFrom.member_entry_form_container}>
          <TextForm
            required={field.is_required}
            label={field.logical_name}
            formName="email"
            register={register}
            errors={errors}
            placeholder="test@example.com"
            defaultValue={props.email}
          />
        </div>
      );
    case "tel":
      return (
        <div className={STMemberEntryFrom.member_entry_form_container}>
          <TextForm
            label={field.logical_name}
            formName="tel"
            register={register}
            errors={errors}
            required={field.is_required}
            placeholder="00000000000"
            optionInformation="ハイフンなしで入力してください。"
          />
        </div>
      );
    case "zipcode":
      return (
        <div className={STMemberEntryFrom.member_entry_form_container}>
          <TextFormWithButton
            label={field.logical_name}
            formName="zipcode"
            register={register}
            errors={errors}
            required={field.is_required}
            placeholder="0000000"
            optionInformation="新住所がお決まりの方は新住所をご入力ください。"
            onClick={handlePostCodeBtnClick}
            buttonName="住所検索"
          />
        </div>
      );
    case "pref_name":
      return (
        <div className={STMemberEntryFrom.member_entry_form_container}>
          <SelectForm
            label={field.logical_name}
            formName="prefName"
            register={register}
            options={options}
            errors={errors}
            required={field.is_required}
            placeholder="東京都"
          />
        </div>
      );
    case "city_name":
      return (
        <div className={STMemberEntryFrom.member_entry_form_container}>
          <TextForm
            label={field.logical_name}
            formName="cityName"
            register={register}
            errors={errors}
            required={field.is_required}
            placeholder="入力例市"
          />
        </div>
      );

    case "address":
      return (
        <div className={STMemberEntryFrom.member_entry_form_container}>
          <TextForm
            label={field.logical_name}
            formName="address"
            register={register}
            errors={errors}
            required={field.is_required}
            placeholder="入力例町1-2-3"
          />
        </div>
      );
    case "instagram_ownerinput":
      return (
        <div className={STMemberEntryFrom.member_entry_form_container}>
          <TextForm
            label={field.logical_name}
            formName="instagramOwnerinput"
            register={register}
            errors={errors}
            required={field.is_required}
            placeholder="instagram"
          />
        </div>
      );
    case "birthday":
      return (
        <div className={STMemberEntryFrom.member_entry_form_container}>
          <DatePullDownRow
            label={field.logical_name}
            formName="birthday"
            register={register}
            errors={errors}
            required={field.is_required}
            options={{ years, months, days }}
          />
        </div>
      );
    case "password":
      return (
        <>
          <div className={STMemberEntryFrom.member_entry_form_container}>
            <PasswordForm
              label={field.logical_name}
              formName="password"
              register={register}
              errors={errors}
              required={field.is_required}
              placeholder="●●●●●●●●●●●●"
              onChange={() => trigger("passwordConfirm")}
            />
            <div
              className={STMemberEntryFrom.member_entry_form_password_message}
            >
              <p>以下を満たすパスワードをご入力ください。</p>
              <ul>
                <li>半角英数字記号（スペースを除く）</li>
                <li>8文字以上20文字以下</li>
                <li>英字と数字が1文字以上含まれる</li>
              </ul>
            </div>
          </div>
          <div className={STMemberEntryFrom.member_entry_form_container}>
            <PasswordForm
              label={`確認用${field.logical_name}`}
              formName="passwordConfirm"
              register={register}
              errors={errors}
              required={field.is_required}
              placeholder="●●●●●●●●●●●●"
              optionInformation="もう一度パスワードを入力してください。"
            />
          </div>
        </>
      );
    default:
      return null;
  }
};

import { AxiosError } from "axios";
import { getAuth } from "firebase/auth";
import client from "../../api/client";
import TApiResult from "../../types/api/TApiResult";
import getErrorProperty from "./config/getErrorProperty";
import tokenNotFound from "./config/tokenNotFound";

export const postLike = async (
  contentId: number,
  likeFlg: boolean
): Promise<TApiResult> => {
  const auth = getAuth();
  const token = await auth.currentUser?.getIdToken();
  if (!token) return tokenNotFound;

  return await client()
    .likes.post({
      body: {
        content_id: contentId,
        like_flg: likeFlg,
        page_url: `/information/${contentId}`,
      },
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      return {
        isSuccess: true,
        status: 200,
        message: "",
        result: res.body,
      };
    })
    .catch((e: AxiosError) => {
      const { status, message } = getErrorProperty(e, "postLike");
      return {
        isSuccess: false,
        status: status,
        message: message,
      };
    });
};

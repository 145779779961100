import { Helmet } from "react-helmet";

type Props = {
  path: string;
  imageUrl?: string;
};

const Ogp: React.FC<Props> = (props: Props) => {
  return (
    <>
      {props.imageUrl && (
        <Helmet
          meta={[
            {
              property: "og:image",
              content: props.imageUrl,
            },
          ]}
        />
      )}
      <Helmet
        meta={[
          { property: "og:type", content: "article" },
          { property: "og:locale", content: "ja_JP" },
          {
            property: "og:url",
            content: `https://${window.location.hostname}${props.path}`,
          },
        ]}
      />
    </>
  );
};

export default Ogp;

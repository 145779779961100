import { Location } from "history";
import { useEffect } from "react";
import { getAuth } from "firebase/auth";
import CONSTANTS from "../utils/constants";
import { TAppUserSession, isTAppUserSession } from "../types/TAppUserSession";
import { useAuth } from "./useAuth";
import { addHours } from "date-fns";

const getUserSesson = (): TAppUserSession | undefined => {
  try {
    const userSessionItem = localStorage.getItem(CONSTANTS.USER_SESSION_KEY);
    const userSession =
      userSessionItem === null ? undefined : JSON.parse(userSessionItem);
    return userSession;
  } catch {
    // NOTE: JSON parse error
    return undefined;
  }
};

const removeUserSession = () => {
  localStorage.removeItem(CONSTANTS.USER_SESSION_KEY);
};

const updateUserSession = (isLimited: boolean) => {
  const newExpire = addHours(new Date(), 2).getTime();
  const newUserSession: TAppUserSession = {
    isLimited: isLimited,
    expire: newExpire,
  };
  localStorage.setItem(
    CONSTANTS.USER_SESSION_KEY,
    JSON.stringify(newUserSession)
  );
};

const isExpired = (expire: number | null): boolean => {
  const currentTime = new Date().getTime();
  return expire !== null && expire < currentTime;
};

const useUserSession = <T>(location: Location<T>) => {
  const auth = getAuth();
  const { logout } = useAuth();
  const userSession = getUserSesson();
  useEffect(() => {
    (async () => {
      const token = await auth.currentUser?.getIdToken();
      if (!userSession) {
        if (token) logout();
        return;
      }
      if (!token) return removeUserSession();

      if (!isTAppUserSession(userSession)) {
        removeUserSession();
        return logout();
      }
      if (!userSession.isLimited) return;
      if (isExpired(userSession.expire)) {
        removeUserSession();
        return logout();
      }
      updateUserSession(userSession.isLimited);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
};

export default useUserSession;

import BaseHeadNoindex from "../templates/BaseHeadNoindex";
import STUtils from "../../assets/styles/utility.module.scss";
import { getSubdomain } from "../../utils/getSubdomain";
import StaticInfoCard from "../organisms/StaticInfoCard";
import { getAuth } from "firebase/auth";
import { routes } from "../../router/Router";
import { useHistory, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { fetchContentDetailFixed } from "../../utils/api/fetchContentDetailFixed";
import TAppStaticInfoArticle from "../../types/TAppStaticInfoArticle";

const StaticDetail: React.FC = () => {
  const subdomain = getSubdomain(window.location.hostname);
  const auth = getAuth();
  const urlParams = useParams<{ slug: string }>();
  const [infoArticle, setInfoArticle] = useState<TAppStaticInfoArticle>();
  const history = useHistory();
  useEffect(() => {
    fetchContentDetailFixed(urlParams.slug).then((res) => {
      if (!res.isSuccess || !res.result) return history.push(routes.error.path);
      const newInfoArticle = new TAppStaticInfoArticle(
        res.result.title,
        res.result.content,
        auth.currentUser?.uid
      );
      setInfoArticle(newInfoArticle);
      window.scrollTo(0, 0);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlParams, history]);

  return !infoArticle ? (
    <></>
  ) : (
    <BaseHeadNoindex
      title={infoArticle.title}
      login={Boolean(auth.currentUser)}
      subdomain={subdomain}
    >
      <section className={STUtils.container_large}>
        <StaticInfoCard slug={urlParams.slug} />
      </section>
    </BaseHeadNoindex>
  );
};

export default StaticDetail;

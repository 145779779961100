import STFile from "../../assets/styles/organisms/file.module.scss";
import { getSubdomain } from "../../utils/getSubdomain";
import { routes } from "../../router/Router";
import { useParams } from "react-router-dom";
import BaseHead from "../templates/BaseHead";
import { Box, Card, Typography } from "@material-ui/core";
import { StylesProvider } from '@material-ui/core/styles';
import { useEffect, useState } from "react";
import axios from "axios";
import { getAuth } from "firebase/auth";
import tokenNotFound from "../../utils/api/config/tokenNotFound";
import TopBackground from "../organisms/TopBackground";
import { postMemberAccessLog } from "../../utils/api/postMemberAccessLog";
import { ACCESS_LOG_ACTION } from "../../types/TAppAccessLogAction";
import BottomNavigationBar from "../organisms/BottomNavigationBar";
import { FileList } from "../organisms/FileList";
import { FolderData } from "../../types/FolderData";
import { FileUploadResponse } from "../../types/FileUploadResponse";

export const FolderPage: React.FC = () => {
  const subdomain = getSubdomain(window.location.hostname);
  const { folderId } = useParams<{ folderId: string }>();
  const [folder, setFolder] = useState<FolderData>();

  const getUploads = async () => {
    const auth = getAuth();
    const token = await auth.currentUser?.getIdToken();
    if (!token) return tokenNotFound;
    try {
      const url = `${process.env.REACT_APP_API_ENDPOINT}/uploads/list`;
      const { data } = await axios.get<FileUploadResponse>(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const folder = data.data.folders.find(
        (folder) => folder.id === Number(folderId)
      );
      setFolder(folder);
      postMemberAccessLog(ACCESS_LOG_ACTION.mypageFolder, document.referrer);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getUploads();
  }, []);

  return (
    <StylesProvider injectFirst>
      <BaseHead
        title={routes.mypage.title}
        login
        subdomain={subdomain}
        hasBackground
      >
        <TopBackground />
        <Box display="flex" justifyContent="center" mt={4} mx={2}>
          <Card className={STFile.card} >
            {folder ? (
              <>
                <Typography className={STFile.card_header}>
                  {folder.name}
                </Typography>
                {folder.files.length === 0 ? (
                  <FileList
                    folderName="共有されているファイルはありません。"
                    files={[]}
                  />
                ) : (
                  <FileList files={folder.files} />
                )}
              </>
            ) : (
              <Typography className={STFile.card_header}>
                データを取得中です...
              </Typography>
            )}
          </Card>
        </Box>
        <BottomNavigationBar />
      </BaseHead>
    </StylesProvider>
  );
};

import TApiResult from "../../../types/api/TApiResult";

const tokenNotFound: Promise<TApiResult> = new Promise((resolve, reject) =>
  resolve({
    isSuccess: false,
    status: 401,
    message: "tokenNotFound",
  })
);

export default tokenNotFound;

import { TAppFormError } from "../../types/TAppFormError";

const validateFormRow = <T>(params: {
  input: string;
  errors: TAppFormError<T>[];
  setErrors: (errors: TAppFormError<T>[]) => void;
  formKey: T;
  maxLength?: number;
  minLength?: number;
  pattern?: RegExp;
  patternText?: string;
  required?: boolean;
  matchValue?: string;
  matchMessage?: string;
}) => {
  const otherKeyErrors = params.errors.filter(
    (error) => params.formKey !== error.key
  );

  const messages = [];
  if (params.maxLength && params.maxLength < params.input.length) {
    messages.push(`${params.maxLength}文字以下で入力してください`);
  }
  // 任意項目の場合は0文字を許容する必要がある
  if (params.minLength && params.input.length !== 0 && params.minLength > params.input.length) {
    messages.push(`${params.minLength}文字以上で入力してください`);
  }
  if (
    params.input.length > 0 &&
    params.pattern &&
    !params.pattern.test(params.input)
  ) {
    messages.push(`${params.patternText}で入力してください`);
  }
  if (params.required && params.input.length === 0) {
    messages.push(`必須項目です`);
  }
  if (params.matchValue && params.input !== params.matchValue) {
    messages.push(`${params.matchMessage}が一致しません。`);
  }

  const newThisKeyErrors = {
    key: params.formKey,
    messages: messages,
  };
  params.setErrors([...otherKeyErrors, newThisKeyErrors]);
};
export default validateFormRow;

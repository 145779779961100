// NOTE: https://qiita.com/saba_can00/items/bdefb28a1873658cf5d9
export const ACCESS_LOG_ACTION = {
  login: "ログイン",
  topPage: "トップページ",
  contentDetail: "コンテンツ閲覧",
  pointRequest: "ポイント申請",
  rewardRequest: "リワード申請",
  pointHistory: "ポイント履歴",
  rewardHistory: "リワード履歴",
  contact: "お問い合わせ",
  memberEdit: "会員情報変更",
  mypage: "マイページ閲覧",
  mypageFiles: "ファイルページ閲覧",
  mypageFolder: "フォルダページ閲覧",
} as const;

type TypeOfAccessLogAction = typeof ACCESS_LOG_ACTION;
export type TAppAccessLogAction =
  TypeOfAccessLogAction[keyof TypeOfAccessLogAction];

import { Link } from "react-router-dom";
import STAppPagenation from "../../assets/styles/molecules/appPagenation.module.scss";
import PAGE_CONFIG from "../../config/page";

type Props = {
  urlPath: string;
  className: string;
  total: number;
  current: number;
  start: number;
  end: number;
};

const Pagenation: React.FC<Props> = (props: Props) => {
  const prevClickable = (): boolean => {
    return props.current !== 1;
  };
  const nextClickable = (): boolean => {
    return props.current < props.end;
  };
  const range = Array.from(
    { length: props.end - props.start + 1 },
    (_, i) => props.start + i
  );
  const getUrlPath = (path: string):string => {
    if (path.includes('?')) {
      return `${path}&`;
    }
    return `${path}?`;
  }

  return (
    <div className={`${props.className} ${STAppPagenation.app_pagenation}`}>
      <Link
        className={`${STAppPagenation.app_pagenation_prev_link} ${
          PAGE_CONFIG.STATIC_CLASSES.NEXT_PREV_LINK
        } ${prevClickable() ? "" : STAppPagenation.disable}`}
        to={`${getUrlPath(props.urlPath)}page=${props.current - 1}`}
      >
        ＜
      </Link>
      <span>
        {range.map((i) => (
          <span
            key={i}
            className={`${STAppPagenation.app_pagenation_prev_number} ${
              PAGE_CONFIG.STATIC_CLASSES.PAGENATION_BTN
            } ${
              props.current === i
                ? STAppPagenation.btn_current
                : ""
            }`}
          >
            <Link
              className={STAppPagenation.app_pagenation_prev_number_link}
              to={`${getUrlPath(props.urlPath)}page=${i}`}
            >
              {i}
            </Link>
          </span>
        ))}
      </span>
      <Link
        className={`${STAppPagenation.app_pagenation_next_link} ${
          PAGE_CONFIG.STATIC_CLASSES.NEXT_PREV_LINK
        } ${nextClickable() ? "" : STAppPagenation.disable}`}
        to={`${getUrlPath(props.urlPath)}page=${props.current + 1}`}
      >
        ＞
      </Link>
    </div>
  );
};

export default Pagenation;

import STUtils from "../../assets/styles/utility.module.scss";
import ErrorCard from "../organisms/ErrorCard";
import BaseHeadNoindex from "../templates/BaseHeadNoindex";
import LoginBackground from "../organisms/LoginBackground";
import { getSubdomain } from "../../utils/getSubdomain";
import { routes } from "../../router/Router";

const Error: React.FC = () => {
  const subdomain = getSubdomain(window.location.hostname);
  return (
    <BaseHeadNoindex
      title={routes.error.title}
      login={false}
      subdomain={subdomain}
      hasBackground={true}
      showFooter={false}
    >
      <LoginBackground />
      <div className={STUtils.container_large}>
        <ErrorCard />
      </div>
    </BaseHeadNoindex>
  );
};

export default Error;

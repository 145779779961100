/**
 * リボンの色の濃さから文字の色を黒か白に変更する処理
 *
 * @param hex
 * @returns string
 */
export const getContrastTextColor = (hex: string | undefined): string => {
  if (hex === undefined) {
    throw new Error("no color!");
  }
  if (hex.length !== 7 || hex[0] !== "#") {
    throw new Error("Invalid hex color!");
  }
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  // 光度を計算
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
  // 光度が0.5より大きい場合、文字色を黒にする
  // それ以外の場合、文字色を白にする
  return luminance > 0.5 ? "#000000" : "#FFFFFF";
};

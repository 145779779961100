import client from "../../api/client";
import TApiResult from "../../types/api/TApiResult";
import { AxiosError } from "axios";
import getErrorProperty from "./config/getErrorProperty";
import { TCategoriesResponse } from "../../api/category";
import { getAuth } from "firebase/auth";
import tokenNotFound from "./config/tokenNotFound";

export type TApiCategoriesResult = {
  result?: TCategoriesResponse;
};

export const fetchCategories = async (
  parentFlg?: boolean
): Promise<TApiResult & TApiCategoriesResult> => {
  const auth = getAuth();
  const token = await auth.currentUser?.getIdToken();
  if (!token) return tokenNotFound;
  return await client()
    .category
    .get({
      query: {
        parent_flg: parentFlg
      },
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      return {
        isSuccess: true,
        status: 200,
        message: "",
        result: res.body,
      };
    })
    .catch((e: AxiosError) => {
      const { status, message } = getErrorProperty(e, "fetchCategory");
      return {
        isSuccess: false,
        status: status,
        message: message,
      };
    });
};

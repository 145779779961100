import STAppCalendarHeader from "../../assets/styles/atoms/appCalendarHeader.module.scss";
import SvgNext from "./icons/Next";
import SvgPrev from "./icons/Prev";

type Props = {
  month: number;
  isPrevClickable: boolean;
  hundlePrevCick: () => void;
  isNextClickable: boolean;
  hundleNextCick: () => void;
};

const AppCalendarHeader: React.FC<Props> = (props: Props) => {
  return (
    <div
      className={`${STAppCalendarHeader.header}`}
    >
      <SvgPrev
        className={`${STAppCalendarHeader.arrow_left} ${
          props.isPrevClickable
            ? STAppCalendarHeader.clickable
            : STAppCalendarHeader.arrow_disable
        }`}
        onClick={() => props.hundlePrevCick()}
      />
      {props.month}月
      <SvgNext
        className={`${STAppCalendarHeader.arrow_right} ${
          props.isNextClickable
            ? STAppCalendarHeader.clickable
            : STAppCalendarHeader.arrow_disable
        }`}
        onClick={() => props.hundleNextCick()}
      />
    </div>
  );
};

export default AppCalendarHeader;

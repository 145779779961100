import STEventInvitationEntryForm from "../../assets/styles/organisms/eventInvitationEntryForm.module.scss";
import { EventEntryForm } from "../../types/form/eventEntryForm";
import { SelectBoxOption } from "../../types/form/selectBoxOption";
import { TAppTimeRange } from "../../types/TAppTimeRange";
import { TAppEvent } from "../../types/TAppEvent";
import getFormattedDatetime from "../../utils/getFormattedDatetime";
import { TAppEventFromSeting } from "../../types/TAppEventFormSetting";
import STButton from "../../assets/styles/atoms/button.module.scss";
import Button from "../atoms/Button";
import { useEffect, useState } from "react";
import SingleTextRow from "../molecules/formTableRow/SingleTextRow";
import { TAppFormError } from "../../types/TAppFormError";
import DoubleTextRow from "../molecules/formTableRow/DoubleTextRow";
import PullDownRow from "../molecules/formTableRow/PullDownRow";
import getAddressData from "../../utils/getAddressData";
import SingleTextWithButtonRow from "../molecules/formTableRow/SingleTextWithButtonRow";
import SingleNumberRow from "../molecules/formTableRow/SingleNumberRow";
import SingleTextAreaRow from "../molecules/formTableRow/SingleTextAreaRow";
import SvgStep2 from "../atoms/icons/Step2";
import STUtils from "../../assets/styles/utility.module.scss";
import SvgStep2Sp from "../atoms/icons/Step2Sp";
import { TAppPrefectureNames } from "../../types/TAppPrefectureNames";
import {
  EVENT_INVITATION_FORM_ITEM,
  TAppEventInvitationFormItem,
} from "../../types/TAppEventInvitationFormItem";
import CONSTANTS from "../../utils/constants";
import { Link } from "react-router-dom";
import { routes } from "../../router/Router";
import AppCheckBox from "../atoms/form/AppCheckBox";
type Props = {
  event: TAppEvent;
  date: string;
  timeRange: TAppTimeRange;
  eventFormSetting: TAppEventFromSeting;
  form: EventEntryForm;
  formErrors: TAppFormError<TAppEventInvitationFormItem>[];
  privacyPolicyLink?: string;
  setFormErrors: (errors: TAppFormError<TAppEventInvitationFormItem>[]) => void;
  setForm: (date: EventEntryForm) => void;
  setFormEntried: (entried: boolean) => void;
  setDateTimeSelected: (selected: boolean) => void;
};

const EventInvitationEntryForm: React.FC<Props> = ({
  event,
  date,
  timeRange,
  eventFormSetting,
  form,
  formErrors,
  privacyPolicyLink,
  setFormErrors,
  setForm,
  setFormEntried,
  setDateTimeSelected,
}) => {
  const [canApply, setCanApply] = useState(false);
  const [agree, setAgree] = useState(false);
  useEffect(() => {
    const newCanApply = validate(form, agree);
    setCanApply(newCanApply);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formErrors]);

  const options: SelectBoxOption[] = TAppPrefectureNames.map((v, i) => {
    return { id: i, value: v, title: v };
  });

  const relatedOptions: SelectBoxOption[] = eventFormSetting.relate.map(
    (relate, index) => {
      return { id: index, value: relate, title: relate };
    }
  );

  const freeItemOptions: SelectBoxOption[] = eventFormSetting.freeItem.options
    .filter((option) => option !== "")
    .map((option, index) => {
      return { id: index, value: option, title: option };
    });

  const locationOptions: SelectBoxOption[] = eventFormSetting.place.map(
    (option, index) => {
      return { id: index, value: option, title: option };
    }
  );

  const hundleBack = () => {
    setDateTimeSelected(false);
  };

  const hundleAgree = (newAgree: boolean) => {
    setAgree(newAgree);
    const newCanApply = validate(form, newAgree);
    setCanApply(newCanApply);
  };

  const validate = (newForm: EventEntryForm, newAgree: boolean): boolean => {
    const items = (
      Object.keys(
        EVENT_INVITATION_FORM_ITEM
      ) as (keyof typeof EVENT_INVITATION_FORM_ITEM)[]
    ).map((key) => {
      return { key, value: EVENT_INVITATION_FORM_ITEM[key] };
    });

    // フォームのエラーチェック
    const hasError = formErrors.some((error) => {
      return Boolean(error.messages?.length);
    });

    return (
      eventFormSetting.formItems.every((formItem) => {
        if (!formItem.required) return true;
        return items.every((item) => {
          return formItem.name === item.value ? newForm[item.key] !== "" : true;
        });
      }) &&
      newAgree &&
      !hasError
    );
  };

  const hundleChange = (newForm: EventEntryForm): void => {
    const newCanApply = validate(newForm, agree);
    setCanApply(newCanApply);
    setForm(newForm);
  };

  const hundleClick = () => {
    if (!canApply) return;
    setFormEntried(true);
  };

  const isDisplay = (key: TAppEventInvitationFormItem[]) => {
    return Boolean(
      eventFormSetting.formItems.find((item) => key.includes(item.name))
    );
  };

  const isRequired = (key: TAppEventInvitationFormItem[]) => {
    return Boolean(
      eventFormSetting.formItems.find((item) => key.includes(item.name))
        ?.required
    );
  };

  const hundlePostCodeBtnClick = (postCode: string) => {
    getAddressData(
      postCode,
      (prefecture: string, city: string, area: string, street: string) => {
        const address = `${area}${street}`.trim();
        hundleChange({
          ...form,
          ...{
            prefName: prefecture,
            cityName: city,
            address,
          },
        });
      }
    );
  };

  return (
    <>
      {event.schedule_setting_flg ? (
        <div className={STEventInvitationEntryForm.step_container}>
          <SvgStep2
            className={`${STEventInvitationEntryForm.step_content} ${STEventInvitationEntryForm.second_step_content} ${STUtils.dn_sp}`}
            viewBox={"0 0 880 34"}
          />
          <SvgStep2Sp
            className={`${STEventInvitationEntryForm.step_content} ${STEventInvitationEntryForm.second_step_content} ${STUtils.dn_pc}`}
            viewBox={"0 0 275 34"}
          />
        </div>
      ) : null}
      <div
        className={STEventInvitationEntryForm.event_tiile}
        style={!event.schedule_setting_flg ? { marginTop: "0" } : {}}
      >
        <div className={STEventInvitationEntryForm.event_tiile_inner}>
          <p>イベント：{event.title}</p>
          {event.schedule_setting_flg ? (
            <p>
              予約日時：
              {`${getFormattedDatetime(date, "YYYY年MM月DD日(ddd)")} ${
                timeRange.time
              }`}
            </p>
          ) : null}
        </div>
      </div>
      <h1 className={STEventInvitationEntryForm.title}>
        {event.schedule_setting_flg ? "Step2. " : null} お客様情報の入力
      </h1>
      <p className={STEventInvitationEntryForm.discription}>
        下記に必須項目をご入力のうえ「確認画面へ」ボタンを選択してください。
      </p>

      <table className={STEventInvitationEntryForm.form_table}>
        <tbody>
          <DoubleTextRow<TAppEventInvitationFormItem>
            isDisplay={isDisplay([
              EVENT_INVITATION_FORM_ITEM.sei,
              EVENT_INVITATION_FORM_ITEM.mei,
            ])}
            leftForm={{
              defaultValue: form.sei,
              placeholder: "姓",
              formKey: EVENT_INVITATION_FORM_ITEM.sei,
              hundleOnChange: (input) => hundleChange({ ...form, sei: input }),
            }}
            rightForm={{
              defaultValue: form.mei,
              placeholder: "名",
              formKey: EVENT_INVITATION_FORM_ITEM.mei,
              hundleOnChange: (input) => hundleChange({ ...form, mei: input }),
            }}
            title="名前"
            required={isRequired([
              EVENT_INVITATION_FORM_ITEM.sei,
              EVENT_INVITATION_FORM_ITEM.mei,
            ])}
            errors={formErrors}
            setErrors={setFormErrors}
            maxLength={255}
            pattern={CONSTANTS.PATTERN.ONLY_CALACTOR}
            patternText="文字または英数字"
          />
          <DoubleTextRow<TAppEventInvitationFormItem>
            isDisplay={isDisplay([
              EVENT_INVITATION_FORM_ITEM.kanaSei,
              EVENT_INVITATION_FORM_ITEM.kanaMei,
            ])}
            leftForm={{
              defaultValue: form.kanaSei,
              placeholder: "せい",
              formKey: EVENT_INVITATION_FORM_ITEM.kanaSei,
              hundleOnChange: (input) =>
                hundleChange({ ...form, kanaSei: input }),
            }}
            rightForm={{
              defaultValue: form.kanaMei,
              placeholder: "めい",
              formKey: EVENT_INVITATION_FORM_ITEM.kanaMei,
              hundleOnChange: (input) =>
                hundleChange({ ...form, kanaMei: input }),
            }}
            title="ふりがな"
            required={isRequired([
              EVENT_INVITATION_FORM_ITEM.kanaSei,
              EVENT_INVITATION_FORM_ITEM.kanaMei,
            ])}
            errors={formErrors}
            setErrors={setFormErrors}
            maxLength={255}
            pattern={CONSTANTS.PATTERN.ONLY_HIRAGANA}
            patternText="ひらがな"
          />
          <SingleTextRow<TAppEventInvitationFormItem>
            isDisplay={isDisplay([EVENT_INVITATION_FORM_ITEM.introducerName])}
            defaultValue={form.introducerName}
            title="ご紹介者様のお名前"
            placeholder="山田 太郎"
            required={isRequired([EVENT_INVITATION_FORM_ITEM.introducerName])}
            formKey={EVENT_INVITATION_FORM_ITEM.introducerName}
            setErrors={setFormErrors}
            maxLength={255}
            pattern={CONSTANTS.PATTERN.ONLY_CALACTOR}
            patternText="文字または英数字"
            errors={formErrors}
            hundleOnChange={(input) =>
              hundleChange({ ...form, introducerName: input })
            }
          />
          <PullDownRow<TAppEventInvitationFormItem>
            isDisplay={isDisplay([
              EVENT_INVITATION_FORM_ITEM.introducerRelated,
            ])}
            defaultValue={form.introducerRelated}
            options={relatedOptions}
            title="ご紹介者様とのご関係"
            required={isRequired([
              EVENT_INVITATION_FORM_ITEM.introducerRelated,
            ])}
            formKey={EVENT_INVITATION_FORM_ITEM.introducerRelated}
            maxLength={255}
            setErrors={setFormErrors}
            errors={formErrors}
            hundleOnChange={(input) =>
              hundleChange({ ...form, introducerRelated: input })
            }
          />
          <SingleTextRow<TAppEventInvitationFormItem>
            isDisplay={isDisplay([EVENT_INVITATION_FORM_ITEM.email])}
            defaultValue={form.email}
            title="メールアドレス"
            placeholder="example@example.com"
            required={isRequired([EVENT_INVITATION_FORM_ITEM.email])}
            formKey={EVENT_INVITATION_FORM_ITEM.email}
            errors={formErrors}
            setErrors={setFormErrors}
            hundleOnChange={(input) => hundleChange({ ...form, email: input })}
            minLength={3}
            maxLength={255}
            pattern={CONSTANTS.PATTERN.EMAIL}
            patternText="メールアドレスの形式"
          />
          <SingleTextRow<TAppEventInvitationFormItem>
            isDisplay={isDisplay([EVENT_INVITATION_FORM_ITEM.tel])}
            defaultValue={form.tel}
            title="電話番号"
            subTitle="(ハイフンなし)"
            placeholder="09012345678"
            required={isRequired([EVENT_INVITATION_FORM_ITEM.tel])}
            formKey={EVENT_INVITATION_FORM_ITEM.tel}
            errors={formErrors}
            setErrors={setFormErrors}
            hundleOnChange={(input) => hundleChange({ ...form, tel: input })}
            pattern={CONSTANTS.PATTERN.ONLY_NUMBER}
            patternText="半角数字"
            maxLength={11}
            minLength={10}
          />
          <SingleTextWithButtonRow<TAppEventInvitationFormItem>
            isDisplay={isDisplay([EVENT_INVITATION_FORM_ITEM.zipcode])}
            defaultValue={form.zipcode}
            title="郵便番号"
            subTitle="(ハイフンなし)"
            placeholder="1234567"
            required={isRequired([EVENT_INVITATION_FORM_ITEM.zipcode])}
            formKey={EVENT_INVITATION_FORM_ITEM.zipcode}
            errors={formErrors}
            setErrors={setFormErrors}
            hundleButtonClick={() => hundlePostCodeBtnClick(form.zipcode)}
            buttonText={
              <p className={STEventInvitationEntryForm.post_code_btn}>
                郵便番号から
                <br className={STUtils.dn_pc} />
                住所入力
              </p>
            }
            hundleOnChange={(input) =>
              hundleChange({ ...form, zipcode: input })
            }
            pattern={CONSTANTS.PATTERN.ONLY_NUMBER}
            patternText="半角数字"
            maxLength={7}
          />
          <PullDownRow<TAppEventInvitationFormItem>
            isDisplay={isDisplay([EVENT_INVITATION_FORM_ITEM.prefName])}
            defaultValue={form.prefName}
            options={options}
            title="都道府県"
            required={isRequired([EVENT_INVITATION_FORM_ITEM.prefName])}
            setErrors={setFormErrors}
            formKey={EVENT_INVITATION_FORM_ITEM.prefName}
            errors={formErrors}
            hundleOnChange={(input) =>
              hundleChange({ ...form, prefName: input })
            }
            maxLength={255}
          />
          <SingleTextRow<TAppEventInvitationFormItem>
            isDisplay={isDisplay([EVENT_INVITATION_FORM_ITEM.cityName])}
            defaultValue={form.cityName}
            title="市区町村"
            placeholder="〇〇市"
            required={isRequired([EVENT_INVITATION_FORM_ITEM.cityName])}
            formKey={EVENT_INVITATION_FORM_ITEM.cityName}
            errors={formErrors}
            setErrors={setFormErrors}
            hundleOnChange={(input) =>
              hundleChange({ ...form, cityName: input })
            }
            maxLength={255}
            pattern={CONSTANTS.PATTERN.ONLY_CALACTOR}
            patternText="文字または英数字"
          />
          <SingleTextRow<TAppEventInvitationFormItem>
            isDisplay={isDisplay([EVENT_INVITATION_FORM_ITEM.address])}
            defaultValue={form.address}
            title="町名・番地"
            placeholder="〇〇町12番地34号"
            required={isRequired([EVENT_INVITATION_FORM_ITEM.address])}
            formKey={EVENT_INVITATION_FORM_ITEM.address}
            errors={formErrors}
            setErrors={setFormErrors}
            hundleOnChange={(input) =>
              hundleChange({ ...form, address: input })
            }
            maxLength={255}
            pattern={CONSTANTS.PATTERN.ONLY_CALACTOR}
            patternText="文字または英数字"
          />
          <SingleNumberRow<TAppEventInvitationFormItem>
            isDisplay={isDisplay([EVENT_INVITATION_FORM_ITEM.adultCount])}
            defaultValue={form.adultCount}
            title="大人の人数"
            required={isRequired([EVENT_INVITATION_FORM_ITEM.adultCount])}
            formKey={EVENT_INVITATION_FORM_ITEM.adultCount}
            errors={formErrors}
            setErrors={setFormErrors}
            hundleOnChange={(input) =>
              hundleChange({ ...form, adultCount: input })
            }
            pattern={CONSTANTS.PATTERN.ONLY_NUMBER}
            patternText="半角数字"
            maxLength={11}
          />
          <SingleNumberRow<TAppEventInvitationFormItem>
            isDisplay={isDisplay([EVENT_INVITATION_FORM_ITEM.childCount])}
            defaultValue={form.childCount}
            title="子供の人数"
            required={isRequired([EVENT_INVITATION_FORM_ITEM.childCount])}
            formKey={EVENT_INVITATION_FORM_ITEM.childCount}
            errors={formErrors}
            setErrors={setFormErrors}
            hundleOnChange={(input) =>
              hundleChange({ ...form, childCount: input })
            }
            pattern={CONSTANTS.PATTERN.ONLY_NUMBER}
            patternText="半角数字"
            maxLength={11}
          />
          <PullDownRow<TAppEventInvitationFormItem>
            isDisplay={isDisplay([EVENT_INVITATION_FORM_ITEM.location])}
            defaultValue={form.location}
            options={locationOptions}
            title="開催場所"
            required={isRequired([EVENT_INVITATION_FORM_ITEM.location])}
            setErrors={setFormErrors}
            formKey={EVENT_INVITATION_FORM_ITEM.location}
            errors={formErrors}
            hundleOnChange={(input) =>
              hundleChange({ ...form, location: input })
            }
            maxLength={255}
          />
          {freeItemOptions.length > 0 ? (
            <PullDownRow<TAppEventInvitationFormItem>
              isDisplay={isDisplay([EVENT_INVITATION_FORM_ITEM.freeItem])}
              defaultValue={form.freeItem}
              options={freeItemOptions}
              title={eventFormSetting.freeItem.title}
              required={isRequired([EVENT_INVITATION_FORM_ITEM.freeItem])}
              setErrors={setFormErrors}
              formKey={EVENT_INVITATION_FORM_ITEM.freeItem}
              errors={formErrors}
              hundleOnChange={(input) =>
                hundleChange({ ...form, freeItem: input })
              }
              maxLength={255}
            />
          ) : (
            <SingleTextRow<TAppEventInvitationFormItem>
              isDisplay={isDisplay([EVENT_INVITATION_FORM_ITEM.freeItem])}
              defaultValue={form.freeItem}
              title={eventFormSetting.freeItem.title}
              required={isRequired([EVENT_INVITATION_FORM_ITEM.freeItem])}
              formKey={EVENT_INVITATION_FORM_ITEM.freeItem}
              errors={formErrors}
              setErrors={setFormErrors}
              hundleOnChange={(input) =>
                hundleChange({ ...form, freeItem: input })
              }
              maxLength={255}
            />
          )}
          <SingleTextAreaRow<TAppEventInvitationFormItem>
            isDisplay={isDisplay([EVENT_INVITATION_FORM_ITEM.note])}
            defaultValue={form.note}
            title="その他"
            placeholder="備考"
            required={isRequired([EVENT_INVITATION_FORM_ITEM.note])}
            formKey={EVENT_INVITATION_FORM_ITEM.note}
            errors={formErrors}
            hundleOnChange={(input) => hundleChange({ ...form, note: input })}
          />
        </tbody>
      </table>
      <div className={STEventInvitationEntryForm.agreement}>
        <AppCheckBox
          id="AgreeCheck"
          className={`${STEventInvitationEntryForm.agreement_checkbox} ${STEventInvitationEntryForm.event_form_checkbox}`}
          checked={agree}
          value="true"
          onChange={() => hundleAgree(!agree)}
        />

        <label
          htmlFor="AgreeCheck"
          className={STEventInvitationEntryForm.agreement_label}
        >
          {privacyPolicyLink ? (
            <a
              href={privacyPolicyLink}
              target="_blank"
              rel="noreferrer noopener"
              className={STEventInvitationEntryForm.agreement_text_link}
            >
              プライバシーポリシー
            </a>
          ) : (
            <Link
              to={privacyPolicyLink ? privacyPolicyLink : routes.privacy.path}
              target="_blank"
              className={STEventInvitationEntryForm.agreement_text_link}
            >
              プライバシーポリシー
            </Link>
          )}
          について確認の上、同意します。
        </label>
      </div>
      <div className={STEventInvitationEntryForm.button_list}>
        {event.schedule_setting_flg ? (
          <Button
            className={`${STEventInvitationEntryForm.button} ${STButton.tertiary}`}
            clickAction={hundleBack}
            text="日時を修正"
          />
        ) : null}
        <Button
          className={`${STEventInvitationEntryForm.button} ${
            canApply
              ? `${STButton.primary} `
              : `disabled ${STEventInvitationEntryForm.button_disable}`
          }`}
          clickAction={hundleClick}
          text="確認画面へ"
        />
      </div>
    </>
  );
};

export default EventInvitationEntryForm;

import STButton from "../../assets/styles/atoms/button.module.scss";
import STUtils from "../../assets/styles/utility.module.scss";
import STResetPasswordApplyCard from "../../assets/styles/organisms/resetPasswordApplyCard.module.scss";
import ButtonLink from "../atoms/ButtonLink";
import { routes } from "../../router/Router";

const ResetPasswordCompleteCard: React.FC = () => {
  return (
    <div className={STUtils.container_form}>
      <h1 className={STResetPasswordApplyCard.title}>パスワード変更</h1>
      <p className={STResetPasswordApplyCard.txt}>
        パスワードが変更されました。
      </p>

      <div className={STResetPasswordApplyCard.btn}>
        <ButtonLink
          url={routes.login.path}
          text="ログイン画面へ"
          buttonClassName={`${STButton.tertiary}`}
        />
      </div>
    </div>
  );
};

export default ResetPasswordCompleteCard;

import ArticleCard from "../molecules/ArticleCard";

import STInfoArticleCardList from "../../assets/styles/organisms/infoArticleCardList.module.scss";
import PAGE_CONFIG from "../../config/page";
import { contentSummary } from "../../types/contentSummary";
import { useEffect, useState } from "react";
import {
  fetchContents,
  TApiContentsResult,
} from "../../utils/api/fetchContents";
import { CONTENT_TYPE } from "../../types/TAppContentType";
import TApiResult from "../../types/api/TApiResult";
import { Link, useHistory } from "react-router-dom";
import { routes } from "../../router/Router";
import { CheckSpFlag } from "../../hooks/CheckSpFlag";
import SpContentCard from "../molecules/SpContentCard";

const InfoArticleCardList: React.FC = () => {
  const [contents, setContents] = useState<contentSummary[]>([]);
  const history = useHistory();
  const spFlag = CheckSpFlag();
  const limit_count = spFlag ? 10 : 12; // 画面幅によって取得件数を変更
  useEffect(() => {
    fetchContents(0, limit_count, [
      CONTENT_TYPE.information,
      CONTENT_TYPE.video,
    ]).then((res: TApiResult & TApiContentsResult) => {
      if (!res.isSuccess || !res.result) return history.push(routes.error.path);
      const newContents: contentSummary[] = res.result.contents.map((res) => {
        return {
          id: res.id,
          contentTypeId: res.content_type_id,
          title: res.title,
          createdAt: res.post_date,
          linkUrl:
            res.content_type_id === 5
              ? routes.videoDetail.path(`${res.id}`)
              : routes.informationDetail.path(`${res.id}`),
          content: res.content,
          imageUrl: res.img_url,
          goodCount: res.like_count,
          categoryId: res.category_id
        };
      });
      setContents(newContents);
    });
  }, [spFlag]);
  return (
    <div className={STInfoArticleCardList.info_container}>
      <section className={STInfoArticleCardList.info_article_card_list}>
        <div
          className={`${STInfoArticleCardList.info_article_card_list_title} ${PAGE_CONFIG.STATIC_CLASSES.SUBJECT}`}
        >
          コンテンツ一覧
        </div>
        <div
          className={STInfoArticleCardList.info_article_card_list_article_list}
        >
          {contents.map((content) => {
            const CommonCardProps = {
              key: content.id,
              title: content.title,
              contentTypeId: content.contentTypeId,
              className: STInfoArticleCardList.info_article_card_list_article,
              content: content.content,
              linkUrl: content.linkUrl,
              thumbnailUrl: content.imageUrl ? content.imageUrl : "",
              thumbnailAlt: content.title,
              createdAt: content.createdAt,
              goodCount: content.goodCount,
            };

            return spFlag ? (
              <SpContentCard {...CommonCardProps} />
            ) : (
              <ArticleCard {...CommonCardProps} />
            );
          })}
        </div>
        <div className={STInfoArticleCardList.info_article_card_list_more}>
          <Link
            to={routes.informations.path}
            className={STInfoArticleCardList.info_article_card_list_more_link}
          >
            全て表示＞
          </Link>
        </div>
      </section>
    </div>
  );
};

export default InfoArticleCardList;

import client from "../../api/client";
import TApiResult from "../../types/api/TApiResult";
import { AxiosError } from "axios";
import getErrorProperty from "./config/getErrorProperty";
import { TContentsResponse } from "../../api/contents";
import { TAppContentType } from "../../types/TAppContentType";
import { getAuth } from "firebase/auth";

export type TApiContentsResult = {
  result?: TContentsResponse;
};

export const fetchContents = async (
  offset: number,
  limit?: number,
  content_type_id?: TAppContentType[],
  category_id?: number,
  category_slug?: string,
  title?: string,
  slug?: string,
  recommended_flg?: boolean,
  category_flg?: boolean
): Promise<TApiResult & TApiContentsResult> => {
  const slugQuery = slug && { slug: slug };
  if (title === '') title = undefined;
  const bodyQuery = {
    content_type_id: content_type_id,
    category_id: category_id,
    category_slug: category_slug,
    title: title,
    skip: offset,
    limit: limit,
    recommended_flg: recommended_flg,
    content: title,
    category_flg: category_flg,
  };
  const query = {
    query: { ...bodyQuery, ...slugQuery },
  };

  const auth = getAuth();
  const token = await auth.currentUser?.getIdToken();
  const authHeader = token && { headers: { Authorization: `Bearer ${token}` } };
  return await client()
    .contents.get({ ...query, ...authHeader })
    .then((res) => {
      return {
        isSuccess: true,
        status: 200,
        message: "",
        result: res.body,
      };
    })
    .catch((e: AxiosError) => {
      const { status, message } = getErrorProperty(e, "fetchContents");
      return {
        isSuccess: false,
        status: status,
        message: message,
      };
    });
};

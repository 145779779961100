import {
  FieldErrors,
  FieldValues,
  Path,
  UseFormRegister,
} from "react-hook-form";
import PAGE_CONFIG from "../../../config/page";
import STTextFromWithButton from "../../../assets/styles/molecules/reactHookForms/textFormWithButton.module.scss";
import Label from "../../atoms/form/reactHookForms/Label";
import OptionMessage from "./OptionMessage";
import InputField from "../../atoms/form/reactHookForms/InputField";
import ErrorMessage from "../../atoms/form/reactHookForms/ErrorMessage";

type Props<T extends FieldValues> = {
  label: string;
  formName: Path<T>;
  register: UseFormRegister<T>;
  errors: FieldErrors<T>;
  placeholder: string;
  buttonName: string;
  onClick: () => void;
  required?: boolean;
  isReadOnly?: boolean;
  defaultValue?: string;
  optionInformation?: string;
};

const TextFormWithButton = <T extends FieldValues>(props: Props<T>) => {
  const {
    label,
    formName,
    register,
    errors,
    placeholder,
    buttonName,
    onClick,
    required,
    isReadOnly,
    defaultValue,
    optionInformation,
  } = props;

  const isErrors = !!errors[formName];
  const errorMessage = errors[formName]?.message as string;

  return (
    <>
      <div className={STTextFromWithButton.textForm}>
        <Label label={label} htmlFor={formName} required={required} />
        {optionInformation !== undefined &&
          <OptionMessage information={optionInformation} />
        }
        <div className={STTextFromWithButton.formArea}>
          <InputField<T>
            formName={formName}
            register={register}
            isErrors={isErrors}
            placeholder={placeholder}
            defaultValue={defaultValue}
            isReadOnly={isReadOnly}
          />
          <button
            type="button"
            className={`${STTextFromWithButton.button}`}
            onClick={onClick}
          >
            <span className={STTextFromWithButton.button_label}>{buttonName}</span>
          </button>
        </div>
        {isErrors &&
          <ErrorMessage name={formName} message={errorMessage} />
        }
      </div>
    </>
  );
};

export default TextFormWithButton;

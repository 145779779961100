import { SelectBoxOption } from "../../../types/form/selectBoxOption";
import STSelectBox from "../../../assets/styles/atoms/selectBox.module.scss";

type Props = {
  className: string;
  name: string;
  defaultValue?: string;
  options: SelectBoxOption[];
  onChange: (value: string) => void;
};

const SelectBox: React.FC<Props> = (props: Props) => {
  const hundleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    props.onChange(e.target.value);
  };
  const defaultValueKey = 0;

  return (
    <span className={STSelectBox.selectbox}>
      <select
        name={props.name}
        value={props.defaultValue}
        className={`${props.className} ${STSelectBox.selectbox}`}
        onChange={hundleChange}
      >
        {!props.defaultValue && (
          <option key={defaultValueKey}>選択してください。</option>
        )}
        {props.options.map(({ id, value, title }) => (
          <option key={id} value={value}>
            {title}
          </option>
        ))}
      </select>
    </span>
  );
};

export default SelectBox;

import React from 'react';
import STCategoryCard from "../../assets/styles/molecules/categoryCard.module.scss";

type Props = {
    id: number;
    title: string;
    isActive: boolean;
    handleChangeCategory: (id: number) => void;
}

const CategoryCardNew: React.FC<Props> = (props: Props) => {

  const handleClickActive = () => {
    props.handleChangeCategory(props.id)
  }

  return (
    <div className={!props.isActive ? STCategoryCard.card_block_new : STCategoryCard.card_block_active} onClick={handleClickActive}>
      <h4>{props.title}</h4>
    </div>
  )
}

export default CategoryCardNew;

import { TAppFormError } from "../../types/TAppFormError";

const filterMessageFormError = <T>(
  key: T,
  message: string,
  formErrors: TAppFormError<T>[]
): TAppFormError<T>[] => {
  return formErrors.map((error) => {
    if (error.key !== key) {
      return error;
    }
    const filtered = error.messages.filter((msg) => msg !== message);
    return { key: key, messages: filtered };
  });
};

export default filterMessageFormError;

import client from "../../api/client";
import TApiResult from "../../types/api/TApiResult";
import { AxiosError } from "axios";
import getErrorProperty from "./config/getErrorProperty";
import { TMemberRegistrationFormSettingResponse } from "../../api/member_registration_form_setting";

export type TMemberRegistrationFormSettingResult = {
  result?: TMemberRegistrationFormSettingResponse;
};

export const fetchMemberRegistrationFormSetting = async (): Promise<
  TApiResult & TMemberRegistrationFormSettingResult
> => {
  return client()
    .member_registration_form_setting.get()
    .then((res) => {
      return {
        isSuccess: true,
        status: 200,
        message: "",
        result: res.body,
      };
    })
    .catch((e: AxiosError) => {
      const { status, message } = getErrorProperty(
        e,
        "fetchMemberRegistrationFormSettings"
      );
      return {
        isSuccess: false,
        status: status,
        message: message,
      };
    });
};

import ArticleCard from "../molecules/ArticleCard";
import { useContext, useEffect, useState } from "react";
import { CONTENT_TYPE } from "../../types/TAppContentType";
import TApiResult from "../../types/api/TApiResult";
import { useHistory } from "react-router-dom";
import { routes } from "../../router/Router";
import { contentSummary } from "../../types/contentSummary";
import STRecommendContentsList from "../../assets/styles/organisms/recommendContentsList.module.scss";
import {
  fetchContents,
  TApiContentsResult,
} from "../../utils/api/fetchContents";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import "swiper/swiper.scss"; // core Swiper
import "swiper/modules/navigation/navigation.scss"; // Navigation module
import "swiper/modules/pagination/pagination.scss"; // Pagination module
import { Navigation, Pagination, Autoplay } from "swiper";
import STSwiper from "../../assets/styles/molecules/swiper.module.scss";
import PAGE_CONFIG from "../../config/page";
import { SiteContext } from "../../providers/SiteProvider";
import { useSwiperSettings } from "../../hooks/useSwiperSettings";

const RecommendContentsList: React.VFC = () => {
  const [informations, setInformations] = useState<contentSummary[]>([]);
  const history = useHistory();
  const newUiVersion = useContext(SiteContext).newUIVersion;
  const backgroundColorNew = useContext(SiteContext).backgroundColor;

  const breakpoints = {
    default: {
      slidesPerView: 3,
      slidesPerGroup: newUiVersion ? 1 : 3,
      centeredSlides: false,
      loopThreshold: 3,
    },
    sp: {
      slidesPerView: 1.2,
      slidesPerGroup: 1,
      centeredSlides: true,
      loopThreshold: 1,
    },
  };

  const { shouldLoop, slidesPerView, slidesPerGroup, centeredSlides } =
    useSwiperSettings(informations.length, breakpoints);

  useEffect(() => {
    fetchContents(
      0,
      12,
      [CONTENT_TYPE.information, CONTENT_TYPE.news],
      undefined,
      undefined,
      undefined,
      undefined,
      true
    ).then((res: TApiResult & TApiContentsResult) => {
      if (!res.isSuccess || !res.result) return history.push(routes.error.path);
      const newInformations: contentSummary[] = res.result.contents.map(
        (res) => {
          return {
            id: res.id,
            title: res.title,
            createdAt: res.post_date,
            linkUrl: routes.informationDetail.path(`${res.id}`),
            imageUrl: res.img_url,
            goodCount: res.like_count,
            categoryId: res.category_id,
          };
        }
      );
      setInformations(newInformations);
    });
  }, []);

  if (informations.length === 0 || shouldLoop === undefined) return <></>;

  return (
    <section
      className={STRecommendContentsList.root}
      style={{
        background: newUiVersion ? backgroundColorNew : "var(--sub-color)",
      }}
    >
      <div
        className={
          !newUiVersion
            ? STRecommendContentsList.wrap
            : STRecommendContentsList.wrap_new
        }
      >
        {!newUiVersion && (
          <h1
            className={`${STRecommendContentsList.title} ${PAGE_CONFIG.STATIC_CLASSES.SUBJECT}`}
          >
            おすすめ記事
          </h1>
        )}
        <div className={STRecommendContentsList.swiper}>
          <Swiper
            modules={[Navigation, Pagination, Autoplay]}
            loop={shouldLoop}
            spaceBetween={15}
            slidesPerView={slidesPerView}
            slidesPerGroup={slidesPerGroup}
            centeredSlides={centeredSlides}
            loopFillGroupWithBlank={!shouldLoop}
            autoplay={
              newUiVersion
                ? {
                    delay: 4000,
                    disableOnInteraction: false,
                  }
                : false
            }
            pagination={
              shouldLoop
                ? {
                    clickable: true,
                    el: `.${STSwiper.pagination}`,
                    bulletActiveClass: newUiVersion
                      ? STSwiper.pagination_bullet_active_new
                      : STSwiper.pagination_bullet_active,
                    bulletClass: STSwiper.pagination_bullet,
                  }
                : {}
            }
            navigation={
              shouldLoop
                ? {
                    nextEl: `.${STSwiper.button_next}`,
                    prevEl: `.${STSwiper.button_prev}`,
                  }
                : {}
            }
          >
            {informations.map((article) => (
              <SwiperSlide key={`slide-${article.id}`}>
                <ArticleCard
                  key={article.id}
                  title={article.title}
                  className={
                    !newUiVersion
                      ? STRecommendContentsList.recommend_contents
                      : STRecommendContentsList.recommend_contents_new
                  }
                  linkUrl={article.linkUrl}
                  thumbnailUrl={article.imageUrl ? article.imageUrl : ""}
                  thumbnailAlt={article.title}
                  createdAt={article.createdAt}
                  goodCount={article.goodCount}
                  isRecommended={true}
                />
              </SwiperSlide>
            ))}
          </Swiper>
          <div className={STSwiper.pagination}></div>
          {!newUiVersion && (
            <>
              <div className={STSwiper.button_prev}></div>
              <div className={STSwiper.button_next}></div>
            </>
          )}
        </div>
      </div>
    </section>
  );
};
export default RecommendContentsList;

import { useContext } from "react";
import { Link } from "react-router-dom";
import { SiteContext } from "../../providers/SiteProvider";
import SvgArrowRightButton from "./icons/ArrowRightButton";

type Props = {
  url: string;
  text: string;
  buttonClassName?: string;
  linkClassName?: string;
  external?: boolean;
};

const ButtonLink: React.FC<Props> = (props: Props) => {
  const site = useContext(SiteContext);
  const newUiVersion = site.newUIVersion;
  return (
    <div className={props.buttonClassName}>
      {props.external ?
      <a href={props.url} className={props.linkClassName}>
        {props.text}
      </a>
      :
      <Link to={props.url} className={props.linkClassName}>
        {props.text}
      </Link>
      }
    </div>
  );
};

export default ButtonLink;

import BaseHead from "../templates/BaseHead";
import STUtils from "../../assets/styles/utility.module.scss";
import InfoArticleDetail from "../organisms/InfoArticleDetail";
import { getSubdomain } from "../../utils/getSubdomain";
import { useHistory, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { TAppInfoArticle } from "../../types/TAppInfoArticle";
import { fetchContentPreviewDetail } from "../../utils/api/fetchContentPreviewDetail";
import { routes } from "../../router/Router";
import { getAuth } from "firebase/auth";
import VideoDetail from "../organisms/VideoDetail";
import { CheckSpFlag } from "../../hooks/CheckSpFlag";

const ContentPreview: React.FC = () => {
  const subdomain = getSubdomain(window.location.hostname);
  const urlParams = useParams<{ key: string }>();

  const history = useHistory();
  const auth = getAuth();
  const [infoArticle, setInfoArticle] = useState<TAppInfoArticle>();
  const [isClicked, setIsClicked] = useState<boolean>(false);
  const spFlag = CheckSpFlag();

  useEffect(() => {
    fetchContentPreviewDetail(urlParams.key).then((res) => {
      if (!res.result || !res.isSuccess) return history.push(routes.error.path);
      const eyeCatchImaegeUrl =
        res.result.img_url !== null
          ? res.result.img_url !== ""
            ? res.result.img_url
            : undefined
          : undefined;
      const newInfoArticle = {
        id: res.result.key,
        uid: auth.currentUser?.uid ? auth.currentUser?.uid : null,
        title: res.result.title,
        content: res.result.content,
        eyeCatchImaegeUrl: eyeCatchImaegeUrl,
        postDate: res.result.post_date,
        categoryName: res.result.category_name,
        categoryId: res.result.category_id,
        likeCount: res.result.like_count,
        contentTypeId: res.result.content_type_id,
      };
      setInfoArticle(newInfoArticle);
      setIsClicked(res.result.like_flg);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlParams.key, isClicked, history]);

  if (!infoArticle) return <></>;

  return (
    <BaseHead
      title={routes.contentPreview.title}
      login={true}
      subdomain={subdomain}
      hasBackground={infoArticle.contentTypeId === 5}
      customMainStyle={
        infoArticle.contentTypeId === 5
          ? {
              paddingTop: spFlag ? "6vh" : "15vh",
              paddingBottom: "0px",
              backgroundColor: "#fef2ef",
            }
          : {}
      }
    >
      <section className={STUtils.container_large}>
        {infoArticle.contentTypeId === 5 ? (
          <VideoDetail
            video={{ content: infoArticle.content }}
            spFlag={spFlag}
          />
        ) : (
          <InfoArticleDetail
            infoArticle={infoArticle}
            isClicked={isClicked}
            isPreview={true}
            setInfoArticle={setInfoArticle}
            setIsClicked={setIsClicked}
          />
        )}
      </section>
    </BaseHead>
  );
};

export default ContentPreview;

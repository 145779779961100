export const getSubdomain = (hostname: string): string => {
  const parts = hostname.split(".");

  if (parts[1] === "localhost") return "lib";

  // NOTE(naotoshin72): サブドメインが存在しない(yyy.com)場合は空を返す
  if (parts.length < 3) return "";

  // NOTE(naotoshin72): サブドメインが存在する(xxx.yyy.com)場合はxxxを返す
  const subdomain = parts.slice(0, 1).join(".");
  return subdomain;
};

import parse, { DOMNode } from "html-react-parser";
import { useState } from "react";

type Props = {
  clickAction: Function;
  id?: string;
  lineUrl?: string;
  shareUrl?: string;
};

const LineShareButton = ({ clickAction, lineUrl, shareUrl }: Props) => {
  const [isHover, setIsHover] = useState(false);
  return (
    <button
      onClick={() => {
        clickAction(shareUrl);
        window.open(lineUrl);
      }}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      style={{
        width: "230px",
        borderRadius: "5px",
        filter: "drop-shadow(0 0 0.2rem green)",
        cursor: "pointer",
        display: "inline-block",
        padding: "5px",
        margin: "0 5px",
      }}
      key="0"
    >
      <img
        src={isHover ? "/wide-default-hover.png" : "/wide-default.png"}
        style={{ verticalAlign: "middle" }}
        alt="LINEで送る"
      />
    </button>
  );
};

const createOptions = ({ id, clickAction }: Props) => {
  const options = {
    replace: (domNode: DOMNode) => {
      if (
        domNode.type === "text" &&
        (domNode as any).data.includes("{{シェアボタン(")
      ) {
        const TextData = (domNode as any).data;
        const tmp = TextData.substring(TextData.indexOf("{{シェアボタン(") + 9);
        const Url = tmp.substring(0, tmp.indexOf(")}}"));
        const contentUrl = `https://line.me/R/msg/text/${Url ? Url : ""}${
          id ? `?referrer_id=${id}` : ""
        }`;

        const beforeText = TextData.substring(
          0,
          TextData.indexOf(`{{シェアボタン(${Url})}}`)
        );
        const afterText = TextData.substring(TextData.indexOf(")}}") + 3);

        return (
          <>
            {beforeText}
            <LineShareButton
              id={id}
              clickAction={clickAction}
              lineUrl={contentUrl}
              shareUrl={Url}
            />
            {afterText}
          </>
        );
      }
    },
  };
  return options;
};

export const contentCreateHtml = (
  content: string,
  id: string,
  clickAction: Function
) => {
  const options = createOptions({ id, clickAction });
  const htmlJsx = parse(content, options);
  return htmlJsx;
};

import BaseHead from "../templates/BaseHead";
import STUtils from "../../assets/styles/utility.module.scss";
import LoginBackground from "../organisms/LoginBackground";
import { getSubdomain } from "../../utils/getSubdomain";
import { useEffect, useState } from "react";
import ResetPasswordForm from "../organisms/ResetPasswordForm";
import {
  fetchPasswordReset,
  TApiPasswordResetResult,
} from "../../utils/api/fetchPasswordReset";
import TApiResult from "../../types/api/TApiResult";
import { useHistory, useParams } from "react-router-dom";
import { routes } from "../../router/Router";
import ResetPasswordCompleteCard from "../organisms/ResetPasswordCompleteCard";

const ResetPassword: React.FC = () => {
  const urlParams = useParams<{ id: string; datetimeHash: string }>();
  const subdomain = getSubdomain(window.location.hostname);
  const [isApplied, setIsApplied] = useState(false);
  const [mailAddress, setMailAddress] = useState("");
  const history = useHistory();

  useEffect(() => {
    fetchPasswordReset(urlParams.id, urlParams.datetimeHash).then(
      (res: TApiResult & TApiPasswordResetResult) => {
        if (!res.isSuccess || !res.result)
          return history.push(routes.error.path);
        setMailAddress(res.result.email);
      }
    );
  }, []);

  return (
    <BaseHead
      title={routes.resetPassword.title}
      login={false}
      subdomain={subdomain}
      hasBackground={true}
    >
      <LoginBackground />
      <section className={STUtils.container_large}>
        {isApplied ? (
          <ResetPasswordCompleteCard />
        ) : (
          <ResetPasswordForm email={mailAddress} setIsApplied={setIsApplied} />
        )}
      </section>
    </BaseHead>
  );
};

export default ResetPassword;

import { TReservationSettingTargetDateOption } from "../api/reservation_settings";

export default function getGroupCalendarTarget(
  list: TReservationSettingTargetDateOption[],
  keyGetter: (values: TReservationSettingTargetDateOption) => string
) {
  const map = new Map<string, TReservationSettingTargetDateOption[]>();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

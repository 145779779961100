import STErrorCard from "../../assets/styles/organisms/errorCard.module.scss";
import { Link } from "react-router-dom";
import { routes } from "../../router/Router";

const ErrorCard: React.FC = () => {
  return (
    <div className={STErrorCard.errorCard}>
      <h1 className={STErrorCard.title}>エラーです。</h1>
      <div className={STErrorCard.body}>
        <p className={STErrorCard.text}>
          指定されたページもしくはデータが存在しません。再度お試しください。
        </p>
        <p className={STErrorCard.text}>
          解決しない場合は、担当者までご連絡ください
        </p>
      </div>
      <p>
        <Link to={routes.top.path} className={STErrorCard.link}>
          トップページへ
        </Link>
      </p>
    </div>
  );
};

export default ErrorCard;

import React from "react";
import STTextArea from "../../../assets/styles/atoms/form/textArea.module.scss";

type Props = {
  className: string;
  rows: number;
  cols: number;
  name: string;
  defaultValue?: string;
  placeholder?: string;
  onChange: (value: string) => void;
};

const TextArea: React.FC<Props> = (props: Props) => {
  const hundleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    props.onChange(e.target.value);
  };

  return (
    <textarea
      className={`${STTextArea.text} ${props.className}`}
      placeholder={props.placeholder}
      value={props.defaultValue}
      rows={props.rows}
      cols={props.cols}
      onChange={hundleChange}
    />
  );
};

export default TextArea;

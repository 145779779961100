import { TAppFormError } from "../../../types/TAppFormError";
import TableRow from "../../atoms/TableRow";
import STSingleCheckBoxRow from "../../../assets/styles/molecules/formTableRow/singleCheckBoxRow.module.scss";
import AlartMessages from "../../atoms/AlartMessages";
import validateFormRow from "../../../utils/form/validateFormRow";
import AppCheckBox from "../../atoms/form/AppCheckBox";
import Label from "../../atoms/form/Label";

type Props<T> = {
  title: string;
  subTitle?: string;
  label: string;
  defaultValue?: number;
  required?: boolean;
  formKey: T;
  errors: TAppFormError<T>[];
  setErrors: (errors: TAppFormError<T>[]) => void;
  hundleOnChange: (value: number) => void;
  isDisplay?: boolean;
  canChange?: boolean;
};

const SingleCheckBoxRow = <T,>({
  title,
  subTitle,
  label,
  defaultValue,
  required = false,
  formKey,
  errors,
  setErrors,
  hundleOnChange,
  isDisplay = true,
  canChange = true,
}: Props<T>) => {
  const hundleChange = (input: string) => {
    validateFormRow<T>({
      input,
      errors,
      setErrors,
      formKey,
      required,
    });
    hundleOnChange(Number(input));
  };

  if (!isDisplay) return <></>;
  return (
    <TableRow title={title} subTitle={subTitle} required={required}>
      <span>
        <AppCheckBox
          id={title}
          className={
            canChange
              ? `${STSingleCheckBoxRow.form}`
              : STSingleCheckBoxRow.disable
          }
          checked={defaultValue === 1}
          value="1"
          onChange={(input) => hundleChange(input)}
        />
        <Label
          id=""
          className={STSingleCheckBoxRow.label}
          for={title}
          value={label}
        />
      </span>
      {AlartMessages<T>({
        title: title,
        errors: errors,
        keys: [formKey],
      })}
    </TableRow>
  );
};

export default SingleCheckBoxRow;

import BaseHead from "../templates/BaseHead";
import STUtils from "../../assets/styles/utility.module.scss";
import { getSubdomain } from "../../utils/getSubdomain";
import LoginBackground from "../organisms/LoginBackground";
import { useEffect, useState } from "react";
import MailAddressConfirmForm from "../organisms/MailAddressConfirmForm";
import { postPasswordResetMail } from "../../utils/api/postPasswordResetMail";
import MailAddressConfirmApplyCard from "../organisms/MailAddressConfirmApplyCard";
import { routes } from "../../router/Router";

const ResetPasswordMailAddressConfirm: React.FC = () => {
  const [isApplied, setIsApplied] = useState(false);
  const subdomain = getSubdomain(window.location.hostname);
  const [pageTitle, setPageTitle] = useState<string>(
    routes.resetPasswordMailAddressConfirm.title
  );
  useEffect(() => {
    if (!isApplied) return;
    setPageTitle("メール送信完了");
  }, [isApplied]);

  return (
    <BaseHead
      title={pageTitle}
      login={false}
      subdomain={subdomain}
      hasBackground={true}
    >
      <LoginBackground />
      <section className={STUtils.container_large}>
        {isApplied ? (
          <MailAddressConfirmApplyCard action="パスワード変更" />
        ) : (
          <MailAddressConfirmForm
            setIsApplied={setIsApplied}
            postRequest={postPasswordResetMail}
          />
        )}
      </section>
    </BaseHead>
  );
};
export default ResetPasswordMailAddressConfirm;

import { AxiosError } from "axios";
import { getAuth } from "firebase/auth";
import client from "../../api/client";
import TApiResult from "../../types/api/TApiResult";
import getErrorProperty from "./config/getErrorProperty";

export const postMemberAccessLog = async (
  actionName: string,
  refferer: string,
  url?: string
): Promise<TApiResult> => {
  const auth = getAuth();
  const token = await auth.currentUser?.getIdToken();
  if (!token)
    return {
      isSuccess: false,
      status: 401,
      message: "token is not found",
    };
  return client()
    .member.accesslog.post({
      body: {
        access_url: url ?? window.location.href,
        action_name: actionName,
        referrer: refferer,
      },
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      return {
        isSuccess: true,
        status: 200,
        message: "",
      };
    })
    .catch((e: AxiosError) => {
      const { status, message } = getErrorProperty(e, "postMemberAccessLog");
      return {
        isSuccess: false,
        status: status,
        message: message,
      };
    });
};

import Image from "../atoms/Image";
import STPointHistoryPointCard from "../../assets/styles/organisms/pointHistoryPointCard.module.scss";
import STUtils from "../../assets/styles/utility.module.scss";
import { TAppRankSummary } from "../../types/TAppRankSummary";
import PAGE_CONFIG from "../../config/page";
import { useContext } from "react";
import { SiteContext } from "../../providers/SiteProvider";

type Props = {
  rank?: TAppRankSummary;
};

const PointHistoryPointCard: React.FC<Props> = (props: Props) => {
  const site = useContext(SiteContext);
  return (
    <div className={STPointHistoryPointCard.point_history_point_card}>
      <h1 className={STPointHistoryPointCard.point_history_point_card_title}>
        {site.isPoint ? "ポイント履歴" : "リワード履歴"}
      </h1>
      {props.rank && (
        <div className={STPointHistoryPointCard.point_history_point_and_medal}>
          <div
            className={STPointHistoryPointCard.point_history_point_card_point}
          >
            <div
              className={STPointHistoryPointCard.point_history_point_card_medal}
            >
              <div
                className={
                  STPointHistoryPointCard.point_history_point_card_medal_img
                }
              >
                <Image
                  imageUrl={`${PAGE_CONFIG.CDN_ENDPOINT}/${props.rank.icon}`}
                  alt={props.rank.name}
                  className={STUtils.img_fit}
                />
              </div>
              <div
                className={
                  STPointHistoryPointCard.point_history_point_card_medal_txt
                }
              >
                {props.rank.name}
              </div>
            </div>
          </div>
          <div
            className={
              STPointHistoryPointCard.point_history_point_card_point_txt
            }
          >
            {!site.isPoint && (
              <p
                className={
                  STPointHistoryPointCard.point_history_point_card_point_label
                }
              >
                Rewards score
              </p>
            )}
            <div
              className={
                STPointHistoryPointCard.point_history_point_card_point_label_point
              }
            >
              {props.rank.currentPeriodPoint}
              {site.isPoint && <small className="">&nbsp;pt</small>}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PointHistoryPointCard;

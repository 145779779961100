type Props = {
  src: string;
  alt: string;
  className: string;
};

const Icon: React.FC<Props> = (props: Props) => {
  return <img src={props.src} alt={props.alt} className={props.className} />;
};

export default Icon;

import { useHistory } from "react-router-dom";
import { TApiTrackingRequestBody } from "../../api/tracking";
import STStaticInfoArticleCard from "../../assets/styles/molecules/staticInfoArticleCard.module.scss";
import "../../resources/CKEEditor.css";
import { postTracking } from "../../utils/api/postTrackingRequest";
import { routes } from "../../router/Router";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { contentCreateHtml } from "../JsxParse";

type Props = {
  title: string;
  content: string;
  uid?: string | undefined;
};

const StaticInfoArticleCard: React.FC<Props> = (props: Props) => {
  const [cookies, setCookie] = useCookies(["ambLpView"]);
  const [uuid, setUuid] = useState(cookies.ambLpView ? cookies.ambLpView : "");
  const [load, setLoad] = useState(false);

  const history = useHistory();
  useEffect(() => {
    const date = new Date();
    const str = date
      .toISOString() //2022-02-05T21:00:00.000Z
      .replace(/[^0-9]/g, ""); //20220205210000000
    const chars = "abcdefghijklmnopqrstuvwxyz";
    let rdmStr = "";
    for (var i = 0; i < 8; i++) {
      rdmStr += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    if (!uuid) {
      setCookie("ambLpView", `${str}-${rdmStr}`, {
        maxAge: 60 * 60 * 24 * 400,
        path: "/",
      });
      setUuid(`${str}-${rdmStr}`);
    } else {
      setCookie("ambLpView", uuid, { maxAge: 60 * 60 * 24 * 400, path: "/" });
    }
    setLoad(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (load) {
      const trackingView: TApiTrackingRequestBody = {
        uid: null,
        referrer_uid: null,
        session_uuid: uuid,
        url: window.location.href,
        tracking_flg: 2,
        track_event_name: "",
        click_tag: "",
        referrer: document.referrer,
      };
      postTracking(trackingView).then((res) => {
        if (!res.isSuccess) return history.push(routes.error.path);
      });
      setLoad(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [load]);
  const clickActions = (shareUrl: string) => {
    //計測
    const trackingContent: TApiTrackingRequestBody = {
      uid: null,
      referrer_uid: null,
      session_uuid: uuid,
      url: window.location.href,
      tracking_flg: 1,
      track_event_name: "シェアボタンクリック",
      click_tag: "click",
      referrer: document.referrer,
      share_url: shareUrl,
    };
    postTracking(trackingContent).then((res) => {
      if (!res.isSuccess) return history.push(routes.error.path);
    });
  };
  return (
    <div className={STStaticInfoArticleCard.static_card}>
      <h1 className={STStaticInfoArticleCard.title}>{props.title}</h1>
      <section className={STStaticInfoArticleCard.body}>
        {props.content.indexOf("{{シェアボタン", 0) > 0 ? (
          <div className="ck-content">
            {contentCreateHtml(props.content, "", clickActions)}
          </div>
        ) : (
          <div
            className="ck-content"
            dangerouslySetInnerHTML={{ __html: props.content }}
          />
        )}
      </section>
    </div>
  );
};

export default StaticInfoArticleCard;

import { ReactNode } from "react";
import TStylePx from "../../types/style/px";
import setBold from "../../utils/style/setBold";

type Props = {
  children: ReactNode;
  className: string;
  fontSize: TStylePx;
  isBold?: boolean;
};

const TextLabel: React.FC<Props> = ({
  children,
  className,
  fontSize,
  isBold = false,
}) => {
  return <p className={`${className} ${setBold(isBold)}`}>{children}</p>;
};

export default TextLabel;

import type { AspidaClient } from "aspida";
import { dataToURLString } from "aspida";
import type { Methods as Methods0 } from "./banner_setting";
import type { Methods as Methods1 } from "./category";
import type { Methods as Methods2 } from "./category/_idOrSlug@string";
import type { Methods as Methods3 } from "./contact_details";
import type { Methods as Methods4 } from "./contents";
import type { Methods as Methods5 } from "./contents/_idOrSlug@string";
import type { Methods as Methods6 } from "./contents/fixed/_idOrslug@string";
import type { Methods as Methods7 } from "./contents/preview/_key@string";
import type { Methods as Methods8 } from "./event_required";
import type { Methods as Methods9 } from "./events/_id@number";
import type { Methods as Methods10 } from "./events/preview/_key@string";
import type { Methods as Methods11 } from "./likes";
import type { Methods as Methods12 } from "./member/accesslog";
import type { Methods as Methods13 } from "./member/detail";
import type { Methods as Methods14 } from "./member/link_user_line_account";
import type { Methods as Methods15 } from "./member/register";
import type { Methods as Methods16 } from "./member/register/_id@string/_datetime_hash@string";
import type { Methods as Methods17 } from "./member/register/status";
import type { Methods as Methods18 } from "./member/identification_send";
import type { Methods as Methods19 } from "./member/update";
import type { Methods as Methods20 } from "./member_registration_form_setting";
import type { Methods as Methods21 } from "./menu_setting";
import type { Methods as Methods22 } from "./message_setting";
import type { Methods as Methods23 } from "./password/reset/_id@string/_datetime_hash@string";
import type { Methods as Methods24 } from "./password/reset-mail";
import type { Methods as Methods25 } from "./password/update";
import type { Methods as Methods26 } from "./point/autogrant";
import type { Methods as Methods27 } from "./point/history";
import type { Methods as Methods28 } from "./point/request";
import type { Methods as Methods29 } from "./popup_setting";
import type { Methods as Methods30 } from "./reservation_settings";
import type { Methods as Methods31 } from "./reservations";
import type { Methods as Methods32 } from "./site";
import type { Methods as Methods33 } from "./tracking";
import type { Methods as Methods34 } from "./ranks";
import type { Methods as Methods35 } from "./member/identification/_id@string/_datetime_hash@string";

const api = <T>({ baseURL, fetch }: AspidaClient<T>) => {
  const prefix = (baseURL === undefined ? "" : baseURL).replace(/\/$/, "");
  const PATH0 = "/banner_setting";
  const PATH1 = "/category";
  const PATH2 = "/contact_details";
  const PATH3 = "/contents";
  const PATH4 = "/contents/fixed";
  const PATH5 = "/contents/preview";
  const PATH6 = "/event_required";
  const PATH7 = "/events";
  const PATH8 = "/events/preview";
  const PATH9 = "/likes";
  const PATH10 = "/member/accesslog";
  const PATH11 = "/member/detail";
  const PATH12 = "/member/link_user_line_account";
  const PATH13 = "/member/register";
  const PATH14 = "/member/register/status";
  const PATH15 = "/member/identification_send";
  const PATH16 = "/member/update";
  const PATH17 = "/member_registration_form_setting";
  const PATH18 = "/menu_setting";
  const PATH19 = "/message_setting";
  const PATH20 = "/password/reset";
  const PATH21 = "/password/reset-mail";
  const PATH22 = "/password/update";
  const PATH23 = "/point/autogrant";
  const PATH24 = "/point/history";
  const PATH25 = "/point/request";
  const PATH26 = "/popup_setting";
  const PATH27 = "/reservation_settings";
  const PATH28 = "/reservations";
  const PATH29 = "/site";
  const PATH30 = "/tracking";
  const PATH31 = "/ranks";
  const PATH32 = "/site/preview";
  const PATH33 = "/member/identification";
  const GET = "GET";
  const POST = "POST";

  return {
    banner_setting: {
      get: (option?: { config?: T | undefined } | undefined) =>
        fetch<Methods0["get"]["resBody"]>(prefix, PATH0, GET, option).json(),
      $get: (option?: { config?: T | undefined } | undefined) =>
        fetch<Methods0["get"]["resBody"]>(prefix, PATH0, GET, option)
          .json()
          .then((r) => r.body),
      $path: () => `${prefix}${PATH0}`,
    },
    category: {
      _idOrSlug: (val1: string) => {
        const prefix1 = `${PATH1}/${val1}`;

        return {
          get: (option: {
            headers: Methods2["get"]["reqHeaders"];
            config?: T | undefined;
          }) =>
            fetch<Methods2["get"]["resBody"]>(
              prefix,
              prefix1,
              GET,
              option
            ).json(),
          $get: (option: {
            headers: Methods2["get"]["reqHeaders"];
            config?: T | undefined;
          }) =>
            fetch<Methods2["get"]["resBody"]>(prefix, prefix1, GET, option)
              .json()
              .then((r) => r.body),
          $path: () => `${prefix}${prefix1}`,
        };
      },
      get: (option: {
        query?: Methods1["get"]["query"] | undefined;
        headers: Methods1["get"]["reqHeaders"];
        config?: T | undefined;
      }) =>
        fetch<Methods1["get"]["resBody"]>(prefix, PATH1, GET, option).json(),
      $get: (option: {
        query?: Methods1["get"]["query"] | undefined;
        headers: Methods1["get"]["reqHeaders"];
        config?: T | undefined;
      }) =>
        fetch<Methods1["get"]["resBody"]>(prefix, PATH1, GET, option)
          .json()
          .then((r) => r.body),
      $path: (
        option?:
          | { method?: "get" | undefined; query: Methods1["get"]["query"] }
          | undefined
      ) =>
        `${prefix}${PATH1}${
          option && option.query ? `?${dataToURLString(option.query)}` : ""
        }`,
    },
    contact_details: {
      post: (option: {
        body: Methods3["post"]["reqBody"];
        headers?: Methods3["post"]["reqHeaders"] | undefined;
        config?: T | undefined;
      }) =>
        fetch<Methods3["post"]["resBody"]>(prefix, PATH2, POST, option).json(),
      $post: (option: {
        body: Methods3["post"]["reqBody"];
        headers?: Methods3["post"]["reqHeaders"] | undefined;
        config?: T | undefined;
      }) =>
        fetch<Methods3["post"]["resBody"]>(prefix, PATH2, POST, option)
          .json()
          .then((r) => r.body),
      $path: () => `${prefix}${PATH2}`,
    },
    contents: {
      _idOrSlug: (val1: string) => {
        const prefix1 = `${PATH3}/${val1}`;

        return {
          get: (
            option?:
              | {
                  headers?: Methods5["get"]["reqHeaders"] | undefined;
                  config?: T | undefined;
                }
              | undefined
          ) =>
            fetch<Methods5["get"]["resBody"]>(
              prefix,
              prefix1,
              GET,
              option
            ).json(),
          $get: (
            option?:
              | {
                  headers?: Methods5["get"]["reqHeaders"] | undefined;
                  config?: T | undefined;
                }
              | undefined
          ) =>
            fetch<Methods5["get"]["resBody"]>(prefix, prefix1, GET, option)
              .json()
              .then((r) => r.body),
          $path: () => `${prefix}${prefix1}`,
        };
      },
      fixed: {
        _idOrslug: (val2: string) => {
          const prefix2 = `${PATH4}/${val2}`;

          return {
            get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods6["get"]["resBody"]>(
                prefix,
                prefix2,
                GET,
                option
              ).json(),
            $get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods6["get"]["resBody"]>(prefix, prefix2, GET, option)
                .json()
                .then((r) => r.body),
            $path: () => `${prefix}${prefix2}`,
          };
        },
      },
      preview: {
        _key: (val2: string) => {
          const prefix2 = `${PATH5}/${val2}`;

          return {
            get: (
              option?:
                | {
                    headers?: Methods7["get"]["reqHeaders"] | undefined;
                    config?: T | undefined;
                  }
                | undefined
            ) =>
              fetch<Methods7["get"]["resBody"]>(
                prefix,
                prefix2,
                GET,
                option
              ).json(),
            $get: (
              option?:
                | {
                    headers?: Methods7["get"]["reqHeaders"] | undefined;
                    config?: T | undefined;
                  }
                | undefined
            ) =>
              fetch<Methods7["get"]["resBody"]>(prefix, prefix2, GET, option)
                .json()
                .then((r) => r.body),
            $path: () => `${prefix}${prefix2}`,
          };
        },
      },
      get: (
        option?:
          | {
              query?: Methods4["get"]["query"] | undefined;
              headers?: Methods4["get"]["reqHeaders"] | undefined;
              config?: T | undefined;
            }
          | undefined
      ) => fetch<Methods4["get"]["resBody"]>(prefix, PATH3, GET, option).json(),
      $get: (
        option?:
          | {
              query?: Methods4["get"]["query"] | undefined;
              headers?: Methods4["get"]["reqHeaders"] | undefined;
              config?: T | undefined;
            }
          | undefined
      ) =>
        fetch<Methods4["get"]["resBody"]>(prefix, PATH3, GET, option)
          .json()
          .then((r) => r.body),
      $path: (
        option?:
          | { method?: "get" | undefined; query: Methods4["get"]["query"] }
          | undefined
      ) =>
        `${prefix}${PATH3}${
          option && option.query ? `?${dataToURLString(option.query)}` : ""
        }`,
    },
    event_required: {
      get: (
        option?:
          | {
              query?: Methods8["get"]["query"] | undefined;
              config?: T | undefined;
            }
          | undefined
      ) => fetch<Methods8["get"]["resBody"]>(prefix, PATH6, GET, option).json(),
      $get: (
        option?:
          | {
              query?: Methods8["get"]["query"] | undefined;
              config?: T | undefined;
            }
          | undefined
      ) =>
        fetch<Methods8["get"]["resBody"]>(prefix, PATH6, GET, option)
          .json()
          .then((r) => r.body),
      $path: (
        option?:
          | { method?: "get" | undefined; query: Methods8["get"]["query"] }
          | undefined
      ) =>
        `${prefix}${PATH6}${
          option && option.query ? `?${dataToURLString(option.query)}` : ""
        }`,
    },
    events: {
      _id: (val1: number) => {
        const prefix1 = `${PATH7}/${val1}`;

        return {
          get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods9["get"]["resBody"]>(
              prefix,
              prefix1,
              GET,
              option
            ).json(),
          $get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods9["get"]["resBody"]>(prefix, prefix1, GET, option)
              .json()
              .then((r) => r.body),
          $path: () => `${prefix}${prefix1}`,
        };
      },
      preview: {
        _key: (val2: string) => {
          const prefix2 = `${PATH8}/${val2}`;

          return {
            get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods10["get"]["resBody"]>(
                prefix,
                prefix2,
                GET,
                option
              ).json(),
            $get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods10["get"]["resBody"]>(prefix, prefix2, GET, option)
                .json()
                .then((r) => r.body),
            $path: () => `${prefix}${prefix2}`,
          };
        },
      },
    },
    likes: {
      post: (option: {
        body: Methods11["post"]["reqBody"];
        headers: Methods11["post"]["reqHeaders"];
        config?: T | undefined;
      }) =>
        fetch<Methods11["post"]["resBody"]>(prefix, PATH9, POST, option).json(),
      $post: (option: {
        body: Methods11["post"]["reqBody"];
        headers: Methods11["post"]["reqHeaders"];
        config?: T | undefined;
      }) =>
        fetch<Methods11["post"]["resBody"]>(prefix, PATH9, POST, option)
          .json()
          .then((r) => r.body),
      $path: () => `${prefix}${PATH9}`,
    },
    member: {
      accesslog: {
        post: (option: {
          body: Methods12["post"]["reqBody"];
          headers: Methods12["post"]["reqHeaders"];
          config?: T | undefined;
        }) =>
          fetch<Methods12["post"]["resBody"]>(
            prefix,
            PATH10,
            POST,
            option
          ).json(),
        $post: (option: {
          body: Methods12["post"]["reqBody"];
          headers: Methods12["post"]["reqHeaders"];
          config?: T | undefined;
        }) =>
          fetch<Methods12["post"]["resBody"]>(prefix, PATH10, POST, option)
            .json()
            .then((r) => r.body),
        $path: () => `${prefix}${PATH10}`,
      },
      detail: {
        get: (option: {
          headers: Methods13["get"]["reqHeaders"];
          config?: T | undefined;
        }) =>
          fetch<Methods13["get"]["resBody"]>(
            prefix,
            PATH11,
            GET,
            option
          ).json(),
        $get: (option: {
          headers: Methods13["get"]["reqHeaders"];
          config?: T | undefined;
        }) =>
          fetch<Methods13["get"]["resBody"]>(prefix, PATH11, GET, option)
            .json()
            .then((r) => r.body),
        $path: () => `${prefix}${PATH11}`,
      },
      link_user_line_account: {
        post: (option: {
          body: Methods14["post"]["reqBody"];
          headers: Methods14["post"]["reqHeaders"];
          config?: T | undefined;
        }) =>
          fetch<Methods14["post"]["resBody"]>(
            prefix,
            PATH12,
            POST,
            option
          ).json(),
        $post: (option: {
          body: Methods14["post"]["reqBody"];
          headers: Methods14["post"]["reqHeaders"];
          config?: T | undefined;
        }) =>
          fetch<Methods14["post"]["resBody"]>(prefix, PATH12, POST, option)
            .json()
            .then((r) => r.body),
        $path: () => `${prefix}${PATH12}`,
      },
      register: {
        _id: (val2: string) => {
          const prefix2 = `${PATH13}/${val2}`;

          return {
            _datetime_hash: (val3: string) => {
              const prefix3 = `${prefix2}/${val3}`;

              return {
                get: (option?: { config?: T | undefined } | undefined) =>
                  fetch<Methods16["get"]["resBody"]>(
                    prefix,
                    prefix3,
                    GET,
                    option
                  ).json(),
                $get: (option?: { config?: T | undefined } | undefined) =>
                  fetch<Methods16["get"]["resBody"]>(
                    prefix,
                    prefix3,
                    GET,
                    option
                  )
                    .json()
                    .then((r) => r.body),
                $path: () => `${prefix}${prefix3}`,
              };
            },
          };
        },
        status: {
          post: (option: {
            body: Methods17["post"]["reqBody"];
            config?: T | undefined;
          }) =>
            fetch<Methods17["post"]["resBody"]>(
              prefix,
              PATH14,
              POST,
              option
            ).json(),
          $post: (option: {
            body: Methods17["post"]["reqBody"];
            config?: T | undefined;
          }) =>
            fetch<Methods17["post"]["resBody"]>(prefix, PATH14, POST, option)
              .json()
              .then((r) => r.body),
          $path: () => `${prefix}${PATH14}`,
        },
        post: (option: {
          body: Methods15["post"]["reqBody"];
          config?: T | undefined;
        }) =>
          fetch<Methods15["post"]["resBody"]>(
            prefix,
            PATH13,
            POST,
            option
          ).json(),
        $post: (option: {
          body: Methods15["post"]["reqBody"];
          config?: T | undefined;
        }) =>
          fetch<Methods15["post"]["resBody"]>(prefix, PATH13, POST, option)
            .json()
            .then((r) => r.body),
        $path: () => `${prefix}${PATH13}`,
      },
      identification_send: {
        post: (option: {
          body: Methods18["post"]["reqBody"];
          config?: T | undefined;
        }) =>
          fetch<Methods18["post"]["resBody"]>(
            prefix,
            PATH15,
            POST,
            option
          ).json(),
        $post: (option: {
          body: Methods18["post"]["reqBody"];
          config?: T | undefined;
        }) =>
          fetch<Methods18["post"]["resBody"]>(prefix, PATH15, POST, option)
            .json()
            .then((r) => r.body),
        $path: () => `${prefix}${PATH15}`,
      },
      update: {
        post: (option: {
          body: Methods19["post"]["reqBody"];
          headers: Methods19["post"]["reqHeaders"];
          config?: T | undefined;
        }) =>
          fetch<Methods19["post"]["resBody"]>(
            prefix,
            PATH16,
            POST,
            option
          ).json(),
        $post: (option: {
          body: Methods19["post"]["reqBody"];
          headers: Methods19["post"]["reqHeaders"];
          config?: T | undefined;
        }) =>
          fetch<Methods19["post"]["resBody"]>(prefix, PATH16, POST, option)
            .json()
            .then((r) => r.body),
        $path: () => `${prefix}${PATH16}`,
      },
      identification: {
        _id: (val2: string) => {
          const prefix2 = `${PATH33}/${val2}`;

          return {
            _datetime_hash: (val3: string) => {
              const prefix3 = `${prefix2}/${val3}`;
              return {
                post: (
                  option?:
                    | {
                        body: Methods35["post"]["reqBody"];
                        config?: T | undefined;
                      }
                    | undefined
                ) =>
                  fetch<Methods35["post"]["resBody"]>(
                    prefix,
                    prefix3,
                    POST,
                    option
                  ).json(),
                $post: (
                  option?:
                    | {
                        body: Methods35["post"]["reqBody"];
                        config?: T | undefined;
                      }
                    | undefined
                ) =>
                  fetch<Methods35["post"]["resBody"]>(
                    prefix,
                    prefix3,
                    POST,
                    option
                  )
                    .json()
                    .then((r) => r.body),
                $path: () => `${prefix}${prefix3}`,
              };
            },
          };
        },
      },
    },
    member_registration_form_setting: {
      get: (option?: { config?: T | undefined } | undefined) =>
        fetch<Methods20["get"]["resBody"]>(prefix, PATH17, GET, option).json(),
      $get: (option?: { config?: T | undefined } | undefined) =>
        fetch<Methods20["get"]["resBody"]>(prefix, PATH17, GET, option)
          .json()
          .then((r) => r.body),
      $path: () => `${prefix}${PATH17}`,
    },
    menu_setting: {
      get: (
        option?:
          | {
              query?: Methods21["get"]["query"] | undefined;
              config?: T | undefined;
            }
          | undefined
      ) =>
        fetch<Methods21["get"]["resBody"]>(prefix, PATH18, GET, option).json(),
      $get: (
        option?:
          | {
              query?: Methods21["get"]["query"] | undefined;
              config?: T | undefined;
            }
          | undefined
      ) =>
        fetch<Methods21["get"]["resBody"]>(prefix, PATH18, GET, option)
          .json()
          .then((r) => r.body),
      $path: (
        option?:
          | { method?: "get" | undefined; query: Methods21["get"]["query"] }
          | undefined
      ) =>
        `${prefix}${PATH18}${
          option && option.query ? `?${dataToURLString(option.query)}` : ""
        }`,
    },
    message_setting: {
      get: (
        option?:
          | {
              query?: Methods22["get"]["query"] | undefined;
              config?: T | undefined;
            }
          | undefined
      ) =>
        fetch<Methods22["get"]["resBody"]>(prefix, PATH19, GET, option).json(),
      $get: (
        option?:
          | {
              query?: Methods22["get"]["query"] | undefined;
              config?: T | undefined;
            }
          | undefined
      ) =>
        fetch<Methods22["get"]["resBody"]>(prefix, PATH19, GET, option)
          .json()
          .then((r) => r.body),
      $path: (
        option?:
          | { method?: "get" | undefined; query: Methods22["get"]["query"] }
          | undefined
      ) =>
        `${prefix}${PATH19}${
          option && option.query ? `?${dataToURLString(option.query)}` : ""
        }`,
    },
    password: {
      reset: {
        _id: (val2: string) => {
          const prefix2 = `${PATH20}/${val2}`;

          return {
            _datetime_hash: (val3: string) => {
              const prefix3 = `${prefix2}/${val3}`;

              return {
                get: (option?: { config?: T | undefined } | undefined) =>
                  fetch<Methods23["get"]["resBody"]>(
                    prefix,
                    prefix3,
                    GET,
                    option
                  ).json(),
                $get: (option?: { config?: T | undefined } | undefined) =>
                  fetch<Methods23["get"]["resBody"]>(
                    prefix,
                    prefix3,
                    GET,
                    option
                  )
                    .json()
                    .then((r) => r.body),
                $path: () => `${prefix}${prefix3}`,
              };
            },
          };
        },
      },
      reset_mail: {
        post: (option: {
          body: Methods24["post"]["reqBody"];
          config?: T | undefined;
        }) =>
          fetch<Methods24["post"]["resBody"]>(
            prefix,
            PATH21,
            POST,
            option
          ).json(),
        $post: (option: {
          body: Methods24["post"]["reqBody"];
          config?: T | undefined;
        }) =>
          fetch<Methods24["post"]["resBody"]>(prefix, PATH21, POST, option)
            .json()
            .then((r) => r.body),
        $path: () => `${prefix}${PATH21}`,
      },
      update: {
        post: (option: {
          body: Methods25["post"]["reqBody"];
          config?: T | undefined;
        }) =>
          fetch<Methods25["post"]["resBody"]>(
            prefix,
            PATH22,
            POST,
            option
          ).json(),
        $post: (option: {
          body: Methods25["post"]["reqBody"];
          config?: T | undefined;
        }) =>
          fetch<Methods25["post"]["resBody"]>(prefix, PATH22, POST, option)
            .json()
            .then((r) => r.body),
        $path: () => `${prefix}${PATH22}`,
      },
    },
    point: {
      autogrant: {
        post: (option: {
          body: Methods26["post"]["reqBody"];
          headers: Methods26["post"]["reqHeaders"];
          config?: T | undefined;
        }) =>
          fetch<Methods26["post"]["resBody"]>(
            prefix,
            PATH23,
            POST,
            option
          ).json(),
        $post: (option: {
          body: Methods26["post"]["reqBody"];
          headers: Methods26["post"]["reqHeaders"];
          config?: T | undefined;
        }) =>
          fetch<Methods26["post"]["resBody"]>(prefix, PATH23, POST, option)
            .json()
            .then((r) => r.body),
        $path: () => `${prefix}${PATH23}`,
      },
      history: {
        get: (option: {
          query?: Methods27["get"]["query"] | undefined;
          headers: Methods27["get"]["reqHeaders"];
          config?: T | undefined;
        }) =>
          fetch<Methods27["get"]["resBody"]>(
            prefix,
            PATH24,
            GET,
            option
          ).json(),
        $get: (option: {
          query?: Methods27["get"]["query"] | undefined;
          headers: Methods27["get"]["reqHeaders"];
          config?: T | undefined;
        }) =>
          fetch<Methods27["get"]["resBody"]>(prefix, PATH24, GET, option)
            .json()
            .then((r) => r.body),
        $path: (
          option?:
            | { method?: "get" | undefined; query: Methods27["get"]["query"] }
            | undefined
        ) =>
          `${prefix}${PATH24}${
            option && option.query ? `?${dataToURLString(option.query)}` : ""
          }`,
      },
      request: {
        post: (option: {
          body: Methods28["post"]["reqBody"];
          headers: Methods28["post"]["reqHeaders"];
          config?: T | undefined;
        }) =>
          fetch<Methods28["post"]["resBody"]>(
            prefix,
            PATH25,
            POST,
            option
          ).json(),
        $post: (option: {
          body: Methods28["post"]["reqBody"];
          headers: Methods28["post"]["reqHeaders"];
          config?: T | undefined;
        }) =>
          fetch<Methods28["post"]["resBody"]>(prefix, PATH25, POST, option)
            .json()
            .then((r) => r.body),
        get: (option: {
          headers: Methods28["get"]["reqHeaders"];
          config?: T | undefined;
        }) =>
          fetch<Methods28["get"]["resBody"]>(
            prefix,
            PATH25,
            GET,
            option
          ).json(),
        $get: (option: {
          headers: Methods28["get"]["reqHeaders"];
          config?: T | undefined;
        }) =>
          fetch<Methods28["get"]["resBody"]>(prefix, PATH25, GET, option)
            .json()
            .then((r) => r.body),
        $path: () => `${prefix}${PATH25}`,
      },
    },
    popup_setting: {
      get: (option: {
        headers: Methods29["get"]["reqHeaders"];
        config?: T | undefined;
      }) =>
        fetch<Methods29["get"]["resBody"]>(prefix, PATH26, GET, option).json(),
      $get: (option: {
        headers: Methods29["get"]["reqHeaders"];
        config?: T | undefined;
      }) =>
        fetch<Methods29["get"]["resBody"]>(prefix, PATH26, GET, option)
          .json()
          .then((r) => r.body),
      $path: () => `${prefix}${PATH26}`,
    },
    reservation_settings: {
      get: (
        option?:
          | {
              query?: Methods30["get"]["query"] | undefined;
              config?: T | undefined;
            }
          | undefined
      ) =>
        fetch<Methods30["get"]["resBody"]>(prefix, PATH27, GET, option).json(),
      $get: (
        option?:
          | {
              query?: Methods30["get"]["query"] | undefined;
              config?: T | undefined;
            }
          | undefined
      ) =>
        fetch<Methods30["get"]["resBody"]>(prefix, PATH27, GET, option)
          .json()
          .then((r) => r.body),
      $path: (
        option?:
          | { method?: "get" | undefined; query: Methods30["get"]["query"] }
          | undefined
      ) =>
        `${prefix}${PATH27}${
          option && option.query ? `?${dataToURLString(option.query)}` : ""
        }`,
    },
    reservations: {
      post: (option: {
        body: Methods31["post"]["reqBody"];
        config?: T | undefined;
      }) =>
        fetch<Methods31["post"]["resBody"]>(
          prefix,
          PATH28,
          POST,
          option
        ).json(),
      $post: (option: {
        body: Methods31["post"]["reqBody"];
        config?: T | undefined;
      }) =>
        fetch<Methods31["post"]["resBody"]>(prefix, PATH28, POST, option)
          .json()
          .then((r) => r.body),
      $path: () => `${prefix}${PATH28}`,
    },
    site: {
      get: (option?: { config?: T | undefined } | undefined) =>
        fetch<Methods32["get"]["resBody"]>(prefix, PATH29, GET, option).json(),
      $get: (option?: { config?: T | undefined } | undefined) =>
        fetch<Methods32["get"]["resBody"]>(prefix, PATH29, GET, option)
          .json()
          .then((r) => r.body),
      $path: () => `${prefix}${PATH29}`,
      preview: {
        _key: (val2: string) => {
          const prefix2 = `${PATH32}/${val2}`;
          return {
            get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods32["get"]["resBody"]>(
                prefix,
                prefix2,
                GET,
                option
              ).json(),
          };
        },
      },
    },
    tracking: {
      post: (option: {
        body: Methods33["post"]["reqBody"];
        headers: Methods33["post"]["reqHeaders"];
        config?: T | undefined;
      }) =>
        fetch<Methods33["post"]["resBody"]>(
          prefix,
          PATH30,
          POST,
          option
        ).json(),
      $post: (option: {
        body: Methods33["post"]["reqBody"];
        headers: Methods33["post"]["reqHeaders"];
        config?: T | undefined;
      }) =>
        fetch<Methods33["post"]["resBody"]>(prefix, PATH30, POST, option)
          .json()
          .then((r) => r.body),
      $path: () => `${prefix}${PATH30}`,
    },
    ranks: {
      get: (option: {
        headers: Methods34["get"]["reqHeaders"];
        config?: T | undefined;
      }) =>
        fetch<Methods34["get"]["resBody"]>(prefix, PATH31, GET, option).json(),
      $get: (option: {
        headers: Methods34["get"]["reqHeaders"];
        config?: T | undefined;
      }) =>
        fetch<Methods34["get"]["resBody"]>(prefix, PATH31, GET, option)
          .json()
          .then((r) => r.body),
      $path: () => `${prefix}${PATH31}`,
    },
  };
};

export type ApiInstance = ReturnType<typeof api>;
export default api;

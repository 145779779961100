import { getAuth } from "firebase/auth";
import { TRanksResponse } from "../../api/ranks";
import TApiResult from "../../types/api/TApiResult";
import tokenNotFound from "./config/tokenNotFound";
import client from "../../api/client";
import { AxiosError } from "axios";
import getErrorProperty from "./config/getErrorProperty";


export type TApiRanksResult = {
    result?: TRanksResponse;
};

export const fetchRanks = async () : Promise<
    TApiResult & TApiRanksResult
> => {
    const auth = getAuth();
    const token = await auth.currentUser?.getIdToken();
    if (!token) return tokenNotFound;
    return client()
        .ranks.get({
            headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => {
            return {
                isSuccess: true,
                status: 200,
                message: "",
                result: res.body,
            };
            })
            .catch((e: AxiosError) => {
            const { status, message } = getErrorProperty(e, "fetchMemberDetail");
            return {
                isSuccess: false,
                status: status,
                message: message,
            };
        });
}

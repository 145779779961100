import { useState, useEffect, useCallback } from "react";
import { Dialog, makeStyles } from "@material-ui/core";
import axios from "axios";
import { getAuth } from "firebase/auth";
import useSaveHistoryToLocalStorage from "./useSaveHistoryToLocalStorage";
import { TPopupSettingResponse } from "../api/popup_setting";
import { fetchPopupSettings } from "../utils/api/fetchPopupSettings";
const useStyles = makeStyles((theme) => ({
  paper: {
    overflow: "initial",
  },
}));

const useModal = (
  shouldOpenInitially: boolean
): {
  modalComponent: React.FC;
  handleOpenPopup: () => void;
  handleClosePopup: () => void;
  handlePlayedScratch: (scratchId: number) => any;
  handleOpenBanner: () => void;
  handleCloseBanner: () => void;
  isOpenClickScratchBanner: boolean
  isOpen: boolean
} => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(shouldOpenInitially);
  const [isOpenClickScratchBanner, setIsOpenClickScratchBanner] = useState(false)
  const [popupSetting, setPopupSetting] = useState<TPopupSettingResponse>();
  const handleOpenPopup = () => setIsOpen(true)
  const handleClosePopup = () => setIsOpen(false)
  const handleOpenBanner = () => setIsOpenClickScratchBanner(true);
  const handleCloseBanner = () => setIsOpenClickScratchBanner(false);
  const { saveInvisibleDate } = useSaveHistoryToLocalStorage()

  useEffect(() => {
    fetchPopupSettings().then((res) => {
      if (!res.isSuccess || !res.result) return;
      setPopupSetting(res.result);
    });
  }, []);
  const auth = getAuth();
  const handlePlayedScratch = async (scratchId: number) => {
    const token = await auth.currentUser?.getIdToken();
    try {
      const playedScratchResponse = await axios.post(
        `${process.env.REACT_APP_GAMIFICATIONS_API_ENDPOINT}`,
        {
          "id": scratchId
        }, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
      );
      return playedScratchResponse
    } catch (error) {
      console.log('error', error)
    }
  }
  const ModalComponent: React.FC = ({ children }) => {
    return (
      <Dialog
        open={isOpen}
        onClose={handleCloseOutOfModal}
        classes={{ paper: classes.paper }}
      >
        {children}
      </Dialog>
    );
  };
  const handleCloseOutOfModal = useCallback(() => {
    setIsOpen(false)
    setIsOpenClickScratchBanner(false);
    saveInvisibleDate(popupSetting)
  }, [popupSetting])

  return {
    modalComponent: ModalComponent,
    handleOpenPopup,
    handleClosePopup,
    handlePlayedScratch,
    handleOpenBanner,
    handleCloseBanner,
    isOpenClickScratchBanner,
    isOpen,
  };
};
export default useModal;

import BaseHead from "../templates/BaseHead";
import STUtils from "../../assets/styles/utility.module.scss";
import { getSubdomain } from "../../utils/getSubdomain";
import { useContext, useEffect, useState } from "react";
import { fetchContentDetail } from "../../utils/api/fetchContentDetail";
import { useHistory, useParams } from "react-router-dom";
import { TAppInfoArticle } from "../../types/TAppInfoArticle";
import { routes } from "../../router/Router";
import { postMemberAccessLog } from "../../utils/api/postMemberAccessLog";
import { ACCESS_LOG_ACTION } from "../../types/TAppAccessLogAction";
import Ogp from "../atoms/Ogp";
import { getAuth } from "firebase/auth";
import VideoDetail from "../organisms/VideoDetail";
import { CheckSpFlag } from "../../hooks/CheckSpFlag";
import { SiteContext } from "../../providers/SiteProvider";
import BottomNavigationBar from "../organisms/BottomNavigationBar";

const Video: React.FC = () => {
  const urlParams = useParams<{ id: string }>();
  const subdomain = getSubdomain(window.location.hostname);
  const history = useHistory();
  const auth = getAuth();
  const spFlag = CheckSpFlag();

  const [video, setVideo] = useState<TAppInfoArticle>();
  const newUiVersion = useContext(SiteContext).newUIVersion;

  useEffect(() => {
    fetchContentDetail(urlParams.id).then((res) => {
      if (!res.result || !res.isSuccess) return history.push(routes.error.path);
      const eyeCatchImaegeUrl =
        res.result.img_url !== null
          ? res.result.img_url !== ""
            ? res.result.img_url
            : undefined
          : undefined;
      const video = {
        id: String(res.result.id),
        uid: auth.currentUser?.uid ? auth.currentUser?.uid : null,
        title: res.result.title,
        content: res.result.content,
        eyeCatchImaegeUrl: eyeCatchImaegeUrl,
        postDate: res.result.post_date,
        categoryName: res.result.category_name,
        categoryId: res.result.category_id,
        likeCount: res.result.like_count,
      };
      setVideo(video);
      postMemberAccessLog(ACCESS_LOG_ACTION.contentDetail, document.referrer);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlParams.id]);

  if (!video) return <></>;
  return (
    <BaseHead
      title={video.title}
      login={false}
      subdomain={subdomain}
      hasBackground={true}
      hasNavigationBar={true}
      customMainStyle={{
        paddingTop: spFlag ? "6vh" : "15vh",
        paddingBottom: "0px",
        backgroundColor: "var(--sub-color)",
      }}
    >
      <Ogp
        path={routes.informationDetail.path(video.id)}
        imageUrl={video.eyeCatchImaegeUrl}
      />
      <VideoDetail video={video} spFlag={spFlag} />
      { newUiVersion &&
          <div>
            <BottomNavigationBar />
          </div>
      }
    </BaseHead>
  );
};

export default Video;

import BaseHead from "../templates/BaseHead";
import STUtils from "../../assets/styles/utility.module.scss";
import MemberEntryForm from "../organisms/MemberEntryForm";
import LoginBackground from "../organisms/LoginBackground";
import { getSubdomain } from "../../utils/getSubdomain";
import { useState } from "react";
import MemberEntryApplyCard from "../organisms/MemberEntryApplyCard";
import { routes } from "../../router/Router";
import { Customer } from "../../api/member/register/_id@string/_datetime_hash@string";

const MemberEntryNomal = (): JSX.Element => {
  const subdomain = getSubdomain(window.location.hostname);
  const [customer] = useState<Customer>({});
  const [isApplied, setIsApplied] = useState(false);
  const [, setSigninData] = useState({ email: null, Password: null });
  return (
    <BaseHead
      title={routes.memberEntry.title}
      login={false}
      subdomain={subdomain}
      hasBackground={true}
    >
      <LoginBackground />
      <section className={STUtils.container_large}>
        {isApplied ? (
          <MemberEntryApplyCard />
        ) : (
          <MemberEntryForm
            customer={customer}
            setIsApplied={setIsApplied}
            setSigninData={setSigninData}
          />
        )}
      </section>
    </BaseHead>
  );
};

export default MemberEntryNomal;

import "./assets/styles/reset.module.scss";
import "./assets/styles/body.module.scss";
import { Router } from "./router/Router";
import { SiteProvider } from "./providers/SiteProvider";
import { BrowserRouter } from "react-router-dom";
import { LoginUserProvider } from "./providers/LoginUserProvider";

function App() {
  return (
    <SiteProvider>
      <LoginUserProvider>
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      </LoginUserProvider>
    </SiteProvider>
  );
}

export default App;

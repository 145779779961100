import { TAppTimeRange } from "../../types/TAppTimeRange";
import STTimeRangeButton from "../../assets/styles/atoms/timeRangeButton.module.scss";

type Props = {
  timeRange: TAppTimeRange;
  isSelected: boolean;
  setTimeRange: (select: TAppTimeRange) => void;
};

const TimeRangeButton: React.FC<Props> = (props: Props) => {
  const hudleClick = () => {
    if (props.timeRange.isFull) return;
    props.setTimeRange(props.timeRange);
  };

  return (
    <li
      className={`
        ${STTimeRangeButton.button} 
        ${
          props.timeRange.isFull
            ? STTimeRangeButton.button_fullhouse
            : props.isSelected
            ? STTimeRangeButton.event_calendar_time_range_selected
            : ""
        }
      `}
      onClick={() => hudleClick()}
    >
      {props.timeRange.time}
      {props.timeRange.isFull && (
        <p className={STTimeRangeButton.full_house_txt}>満席</p>
      )}
    </li>
  );
};
export default TimeRangeButton;

import { EventEntryForm } from "../../types/form/eventEntryForm";
import { TAppEvent } from "../../types/TAppEvent";
import { TAppTimeRange } from "../../types/TAppTimeRange";
import STEventInvitationEntryConfirmCard from "../../assets/styles/organisms/eventInvitationEntryConfirmCard.module.scss";
import getFormattedDatetime from "../../utils/getFormattedDatetime";
import Button from "../atoms/Button";
import STButton from "../../assets/styles/atoms/button.module.scss";
import TextTableRow from "../molecules/TextTableRow";
import { TAppEventFromSeting } from "../../types/TAppEventFormSetting";
import SvgStep3 from "../atoms/icons/Step3";
import SvgStep3Sp from "../atoms/icons/Step3Sp";
import STUtils from "../../assets/styles/utility.module.scss";
import {
  postReservation,
  TApiReservationValidateErrors,
} from "../../utils/api/postReservation";
import { TAppFormError } from "../../types/TAppFormError";
import TApiResult from "../../types/api/TApiResult";
import {
  EVENT_INVITATION_FORM_ITEM,
  TAppEventInvitationFormItem,
} from "../../types/TAppEventInvitationFormItem";
import { TApiTrackingRequestBody } from "../../api/tracking";
import { postTracking } from "../../utils/api/postTrackingRequest";
import { useHistory } from "react-router-dom";
import { routes } from "../../router/Router";
import { TApiPointAutograntRequestBody } from "../../api/point/autogrant";
import { postPointAutogrant } from "../../utils/api/postPointAutograntRequest";
import useMultipleClickPreventer from "../molecules/UseMultipleClickPreventer";

type Props = {
  event: TAppEvent;
  form: EventEntryForm;
  date: string;
  timeRange: TAppTimeRange;
  eventFormSetting: TAppEventFromSeting;
  setApply: (entry: boolean) => void;
  setFormEntried: (entry: boolean) => void;
  setFormErrors: (errors: TAppFormError<TAppEventInvitationFormItem>[]) => void;
  referrerId: string;
  uuid: string;
  url: string;
};

const EventInvitationEntryConfirmCard: React.FC<Props> = ({
  event,
  form,
  date,
  timeRange,
  eventFormSetting,
  setApply,
  setFormEntried,
  setFormErrors,
  referrerId,
  uuid,
  url,
}) => {
  const history = useHistory();
  const isDisplay = (key: TAppEventInvitationFormItem[]) => {
    return Boolean(
      eventFormSetting.formItems.find((item) => key.includes(item.name))
    );
  };

  const hundleSubmit = useMultipleClickPreventer(() => {
    const referrer = url.replace("event_invitation", "events");
    const trackingView: TApiTrackingRequestBody = {
      uid: "",
      referrer_uid: referrerId ? referrerId : "",
      session_uuid: uuid,
      url: url,
      tracking_flg: 1,
      track_event_name: "コンバージョン",
      click_tag: "conversion",
      referrer: referrer,
    };
    postTracking(trackingView).then((res) => {
      if (!res.isSuccess) return history.push(routes.error.path);
    });
    if (referrerId && event.cv_point_grant_flg) {
      const pointData: TApiPointAutograntRequestBody = {
        point_autogrant_event: 2,
        session_uuid: uuid,
        url: url,
        uid: referrerId,
        point_id: null,
      };
      postPointAutogrant(pointData).then((res) => {
        if (!res.isSuccess) return history.push(routes.error.path);
      });
    }
    const params = {
      ...form,
      ...{
        eventId: event.id,
        reservationDate: date !== "" ? date : null,
        reservationTime: timeRange.time !== "" ? timeRange.time : null,
        status: true,
      },
    };
    return postReservation(params).then(
      (res: TApiResult & TApiReservationValidateErrors) => {
        if (!res.isSuccess) {
          setFormErrors(res.validateErrors);
          setFormEntried(false);
        } else {
          setApply(true);
        }
      }
    );
  });

  return (
    <>
      {event.schedule_setting_flg ? (
        <div className={STEventInvitationEntryConfirmCard.step_container}>
          <SvgStep3
            className={`${STEventInvitationEntryConfirmCard.step_content} ${STEventInvitationEntryConfirmCard.third_step_content} ${STUtils.dn_sp}`}
            viewBox={"0 0 880 34"}
          />
          <SvgStep3Sp
            className={`${STEventInvitationEntryConfirmCard.step_content} ${STEventInvitationEntryConfirmCard.third_step_content} ${STUtils.dn_pc}`}
            viewBox={"0 0 275 34"}
          />
        </div>
      ) : null}
      <h1
        className={STEventInvitationEntryConfirmCard.title}
        style={!event.schedule_setting_flg ? { marginTop: "0px" } : {}}
      >
        {event.schedule_setting_flg ? "Step3. " : null}内容確認
      </h1>
      <p className={STEventInvitationEntryConfirmCard.discription}>
        下記内容でよろしければ「送信」ボタンを選択してください。
      </p>
      <div className={STEventInvitationEntryConfirmCard.inner}>
        <table className={STEventInvitationEntryConfirmCard.table}>
          <tbody>
            <TextTableRow
              isDisplay={true}
              title="イベント名"
              body={event.title}
            />
            <TextTableRow
              isDisplay={event.schedule_setting_flg}
              title="予約日時"
              body={`${getFormattedDatetime(date, "YYYY年MM月DD日(ddd)")} ${
                timeRange.time
              }`}
            />
            <TextTableRow
              isDisplay={isDisplay([
                EVENT_INVITATION_FORM_ITEM.sei,
                EVENT_INVITATION_FORM_ITEM.mei,
              ])}
              title="お名前"
              body={`${form.sei} ${form.mei}`}
            />
            <TextTableRow
              isDisplay={isDisplay([
                EVENT_INVITATION_FORM_ITEM.kanaSei,
                EVENT_INVITATION_FORM_ITEM.kanaMei,
              ])}
              title="ふりがな"
              body={`${form.kanaSei} ${form.kanaMei}`}
            />
            <TextTableRow
              isDisplay={isDisplay([EVENT_INVITATION_FORM_ITEM.introducerName])}
              title="ご紹介者様のお名前"
              body={form.introducerName}
            />
            <TextTableRow
              isDisplay={isDisplay([
                EVENT_INVITATION_FORM_ITEM.introducerRelated,
              ])}
              title="ご紹介者様とのご関係"
              body={form.introducerRelated}
            />
            <TextTableRow
              isDisplay={isDisplay([EVENT_INVITATION_FORM_ITEM.email])}
              title="メールアドレス"
              body={form.email}
            />
            <TextTableRow
              isDisplay={isDisplay([EVENT_INVITATION_FORM_ITEM.tel])}
              title="電話番号"
              body={form.tel}
            />
            <TextTableRow
              isDisplay={isDisplay([EVENT_INVITATION_FORM_ITEM.zipcode])}
              title="郵便番号"
              body={form.zipcode}
            />
            <TextTableRow
              isDisplay={isDisplay([EVENT_INVITATION_FORM_ITEM.prefName])}
              title="都道府県"
              body={form.prefName}
            />
            <TextTableRow
              isDisplay={isDisplay([EVENT_INVITATION_FORM_ITEM.cityName])}
              title="市区町村"
              body={form.cityName}
            />
            <TextTableRow
              isDisplay={isDisplay([EVENT_INVITATION_FORM_ITEM.address])}
              title="町名・番地"
              body={form.address}
            />
            <TextTableRow
              isDisplay={isDisplay([EVENT_INVITATION_FORM_ITEM.adultCount])}
              title="大人の人数"
              body={form.adultCount}
            />
            <TextTableRow
              isDisplay={isDisplay([EVENT_INVITATION_FORM_ITEM.childCount])}
              title="子供の人数"
              body={form.childCount}
            />
            <TextTableRow
              isDisplay={isDisplay([EVENT_INVITATION_FORM_ITEM.location])}
              title="開催場所"
              body={form.location}
            />
            <TextTableRow
              isDisplay={isDisplay([EVENT_INVITATION_FORM_ITEM.freeItem])}
              title={eventFormSetting.freeItem.title}
              body={form.freeItem}
            />
            <TextTableRow
              isDisplay={isDisplay([EVENT_INVITATION_FORM_ITEM.note])}
              title="その他"
              body={form.note}
            />
          </tbody>
        </table>
      </div>
      <div className={STEventInvitationEntryConfirmCard.button_list}>
        <Button
          className={`${STEventInvitationEntryConfirmCard.button} ${STButton.tertiary}`}
          clickAction={() => setFormEntried(false)}
          text="入力内容を修正"
        />

        <Button
          className={`${STEventInvitationEntryConfirmCard.button} ${STButton.primary}`}
          clickAction={hundleSubmit}
          text="この内容で送信"
        />
      </div>
    </>
  );
};

export default EventInvitationEntryConfirmCard;

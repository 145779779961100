import { AxiosError } from "axios";
import client from "../../api/client";
import TApiResult from "../../types/api/TApiResult";
import getErrorProperty from "./config/getErrorProperty";
import { TAppFormError } from "../../types/TAppFormError";
import { TAppMemberEntryFormItem } from "../../types/TAppMemberEntryFormItem";

export type TApiMemberRegisterResult = {
  result?: {
    res: {};
  };
};

export type TApiMemberRegisterValidateErrors = {
  validateErrors: TAppFormError<TAppMemberEntryFormItem>[];
};

export const postMemberRegister = async (param: {
  sei: string;
  mei: string;
  kanaSei: string;
  kanaMei: string;
  contractorSei: string;
  contractorMei: string;
  email: string;
  tel: string;
  zipcode: string;
  prefName: string;
  cityName: string;
  address: string;
  instagramOwnerinput: string;
  birthday: string;
  password: string;
  option: string;
  ambassador_id: string;
}): Promise<
  TApiResult & TApiMemberRegisterResult & TApiMemberRegisterValidateErrors
> => {
  return client()
    .member.register.post({
      body: {
        sei: param.sei,
        mei: param.mei,
        kana_sei: param.kanaSei,
        kana_mei: param.kanaMei,
        contractor_sei: param.contractorSei,
        contractor_mei: param.contractorMei,
        tel: param.tel,
        email: param.email,
        password: param.password,
        zipcode: param.zipcode,
        pref_name: param.prefName,
        city_name: param.cityName,
        address: param.address,
        instagram_ownerinput: param.instagramOwnerinput,
        birthday: param.birthday,
        option: param.option,
        ambassador_id: param.ambassador_id,
      },
    })
    .then((res) => {
      if (res.body.details && res.body.details?.length) {
        const validateErrors: TAppFormError<TAppMemberEntryFormItem>[] =
          res.body.details.map((d) => {
            return {
              key: d.key as TAppMemberEntryFormItem,
              messages: d.values,
            };
          });
        return {
          isSuccess: true,
          status: 200,
          message: "",
          result: {
            res: res.body,
          },
          validateErrors: validateErrors,
        };
      }
      return {
        isSuccess: true,
        status: 200,
        message: "",
        result: {
          res: res.body,
        },
        validateErrors: [],
      };
    })
    .catch((e: AxiosError) => {
      const { status, message, errorMessages } = getErrorProperty(
        e,
        "postMemberRegister"
      );
      return {
        isSuccess: false,
        status: status,
        message: message,
        validateErrors: [],
      };
    });
};

import moment from "moment";

const getFormattedDatetime = (datetime: string, format: string) => {
  moment.locale("ja", {
    weekdays: [
      "日曜日",
      "月曜日",
      "火曜日",
      "水曜日",
      "木曜日",
      "金曜日",
      "土曜日",
    ],
    weekdaysShort: ["日", "月", "火", "水", "木", "金", "土"],
  });

  const date = Date.parse(datetime);
  return moment(date).format(format);
};

export default getFormattedDatetime;

import { useCallback, useContext, useEffect, useState } from "react";
import { NaviItem } from "../../types/naviItem";
import Logo from "../molecules/ImageLink";
import NaviMenu from "../molecules/NaviMenu";
import SearchForm from "../molecules/SearchForm";
import STHeader from "../../assets/styles/organisms/header.module.scss";
import STUtils from "../../assets/styles/utility.module.scss";

import PAGE_CONFIG from "../../config/page";
import DrawerMenuButton from "../atoms/DrawerMenuButton";
import DrawerMenu from "./DrawerMenu";

import getImageUrl from "../../utils/getImageUrl";
import { routes } from "../../router/Router";
import { useHistory } from "react-router-dom";
import { SiteContext } from "../../providers/SiteProvider";
import {
  fetchMenuSettings,
  TApiMenuSettingsResult,
} from "../../utils/api/fetchMenuSettings";
import TApiResult from "../../types/api/TApiResult";
import { Box } from "@material-ui/core";
import { HomePopup } from "../atoms/HomePopup";
import { useLocation } from "react-router-dom";
import { useIsMobile } from "../../hooks/useIsMobile";
import { MailAttention } from "../atoms/MailAttention";
import {
  fetchMemberDetail,
  TApiMemberDetailResult,
} from "../../utils/api/fetchMemberDetail";
import { TAppMember } from "../../types/TAppMember";
import { postMemberIdentificationSend } from "../../utils/api/postMemberIdentificationSend";
import Popup from "../atoms/icons/Popup";

type Props = {
  login: boolean;
  subdomain: string;
};

const HEADER_LOCATION_ID = 14;
const key = window.location.search.split("=")[1]?.startsWith("site")
  ? window.location.search.split("=")[1]
  : "";

const Header: React.FC<Props> = (props: Props) => {
  const { enablesHomePopup } = useContext(SiteContext);
  const location = useLocation();
  const site = useContext(SiteContext);
  const [member, setMember] = useState<TAppMember>();
  const [isNotEmailCondirmed, setIsNotEmailConfirmed] = useState(false);
  const [error, setError] = useState("");
  useEffect(() => {
    fetchMemberDetail().then((res: TApiResult & TApiMemberDetailResult) => {
      if (res.isSuccess && res?.result) {
        const newMember: TAppMember = {
          name: `${res.result.sei}${res.result.mei}`,
          rankName: res.result.rank,
          rankImage: res.result.rank_image,
          totalPoint: res.result.current_period_point,
          email: res.result.email,
        };
        if (!res.result.confirmed_email) {
          setIsNotEmailConfirmed(true);
        }
        setMember(newMember);
      }
    });
  }, []);
  // const deFaultNaviItems: NaviItem[] = [
  //   {
  //     id: 1,
  //     locationId: HEADER_LOCATION_ID,
  //     order: 1,
  //     name: "MYページ",
  //     url: routes.top.path,
  //     icon: <SvgMypage viewBox="-1 -3 24 24" height={16} width={16} />,
  //   },
  //   {
  //     id: 2,
  //     locationId: HEADER_LOCATION_ID,
  //     order: 2,
  //     name: site.isPoint ? "ポイント申請" : "リワード申請",
  //     url: routes.pointRequest.path,
  //     icon: <SvgPointApplication viewBox="-4 -4 33 33" height={16} width={16} />,
  //   },
  //   {
  //     id: 3,
  //     locationId: HEADER_LOCATION_ID,
  //     order: 3,
  //     name: site.isPoint ? "ポイント履歴" : "リワード履歴",
  //     url: routes.pointHistory.path,
  //     icon: <SvgPointHistory viewBox="1 -2 24 24" height={16} width={16} />,
  //   },
  //   {
  //     id: 4,
  //     locationId: HEADER_LOCATION_ID,
  //     name: "会員優待特典について",
  //     order: 4,
  //     url: routes.memberPremium.path,
  //     icon: <SvgGift viewBox="-3 -2 24 24" height={16} width={16} />,
  //   },
  //   {
  //     id: 5,
  //     locationId: HEADER_LOCATION_ID,
  //     order: 5,
  //     name: "お知らせ・記事",
  //     url: routes.informations.path,
  //     icon: <SvgArticle viewBox="-3 -1 24 24" height={16} width={16} />,
  //   },
  // ];

  const history = useHistory();
  const [isHeaderShown, setIsHeaderShown] = useState(true);
  const [lastPosition, setLastPosition] = useState(0);
  const [mobile, setMobile] = useState(window.innerWidth < 799);
  const headerHeight = 20;
  const [naviItems, setNaviItems] = useState<NaviItem[]>([]);

  const newUiVersion = site?.newUIVersion;
  const updateMedia = () => {
    setMobile(window.innerWidth < 799);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => {
      window.removeEventListener("resize", updateMedia);
    };
  }, []);
  const scrollEvent = useCallback(() => {
    if (newUiVersion && mobile) return;
    const offset = window.pageYOffset;

    if (offset > headerHeight) {
      setIsHeaderShown(false);
    } else {
      setIsHeaderShown(true);
    }

    if (offset < lastPosition) {
      setIsHeaderShown(true);
    }

    setLastPosition(offset);
  }, [lastPosition]);

  useEffect(() => {
    if (newUiVersion && mobile) return;
    window.addEventListener("scroll", scrollEvent);

    return () => {
      window.removeEventListener("scroll", scrollEvent);
    };
  }, [scrollEvent]);
  /*
  const [scrolled, setScrolled] = useState(false);


  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      setScrolled(currentScrollY > 10);
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => window.removeEventListener("scroll", handleScroll);
  }, [scrolled]);
  */

  const [isOpen, setIsOpen] = useState(false);

  const executeSearch = (query: string) => {
    history.push(`${routes.informations.path}?title=${query}`);
  };

  useEffect(() => {
    fetchMenuSettings(HEADER_LOCATION_ID).then(
      (res: TApiResult & TApiMenuSettingsResult) => {
        if (!res.isSuccess || !res.result) return;
        const naviItems = res.result.map((res) => {
          return {
            id: res.id,
            siteId: res.site_id,
            locationId: res.location_id,
            order: res.order,
            icon: res.icon,
            url: res.url,
            name: res.name,
            locationName: res.location_name,
          };
        });
        const newNaviItems = naviItems.filter((v) => Boolean(v.name));
        newNaviItems.sort((x, y) => {
          return x.order - y.order;
        });
        setNaviItems(newNaviItems);
      }
    );
  }, []);

  const { isMobile } = useIsMobile();

  const [popupOpen, setPopupOpen] = useState(false);
  const isViewedHomePopup = JSON.parse(
    localStorage.getItem("isViewedHomePopup") ?? "false"
  );

  useEffect(() => {
    if (!isViewedHomePopup && enablesHomePopup && isMobile) {
      setPopupOpen(true);
    }
  }, [enablesHomePopup, isMobile, isViewedHomePopup]);

  const isDisplayHomePopup =
    enablesHomePopup && isMobile && location.pathname === routes.top.path;
  const onClickRequestEmailVerification = async () => {
    if (member?.email) {
      const res = await postMemberIdentificationSend(member.email);
      if (!res.isSuccess) {
        setError(res.message);
      }
    }
  };
  return (
    <header
      className={
        newUiVersion
          ? isHeaderShown
            ? STHeader.header_new
            : STHeader.header_new__hide
          : isHeaderShown
          ? STHeader.header
          : STHeader.header__hide
      }
      role="banner"
    >
      <div
        style={{
          background: "#ffffff",
          padding: newUiVersion ? "13px 0" : "26px 0",
        }}
      >
        <div
          className={`${STHeader.header_nav} ${STUtils.container_large} ${
            newUiVersion ? STHeader.header_first_block : ""
          }`}
        >
          <div
            className={
              !newUiVersion ? STHeader.header_logo : STHeader.header_logo_new
            }
          >
            <Logo
              linkUrl={routes.top.path}
              imageUrl={
                !key
                  ? getImageUrl(
                      props.subdomain,
                      PAGE_CONFIG.IMAGE_URL_PATH.SITE,
                      PAGE_CONFIG.LOGO
                    )
                  : site.logo
              }
              className={STHeader.header_logo_img}
              alt={props.subdomain}
            />
          </div>
          {props.login && (
            <>
              <div className={`${STUtils.dn_sp} ${STHeader.header_nav_wrap}`}>
                {!newUiVersion && (
                  <NaviMenu
                    showArrowIcon={false}
                    naviItems={naviItems}
                    className={STHeader.header_nav_menu}
                  />
                )}
                <SearchForm
                  className={STHeader.header_nav_search}
                  executeSearch={executeSearch}
                />
                {newUiVersion && (
                  <a className={STHeader.header_link} href="/contact">
                    お問い合わせ
                  </a>
                )}
              </div>
              <div className={STUtils.dn_pc}>
                <Box display={"flex"} alignItems={"center"} gridGap={"8px"}>
                  <SearchForm
                    className={STHeader.header_nav_search}
                    executeSearch={executeSearch}
                  />
                  <Box display={"flex"} alignItems={"center"} gridGap={"4px"}>
                    {!newUiVersion && (
                      <div
                        style={{ display: "flex", alignItems: "center" }}
                        onClick={() => setPopupOpen((prev) => !prev)}
                      >
                        <Popup />
                      </div>
                    )}

                    <HomePopup
                      popupOpen={popupOpen}
                      onPopupClose={() => {
                        setPopupOpen(false);
                      }}
                    />
                    <DrawerMenuButton
                      isOpen={isOpen}
                      setIsOpen={setIsOpen}
                      subdomain={props.subdomain}
                    />
                  </Box>
                </Box>
                <DrawerMenu
                  showArrowIcon={false}
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  subdomain={props.subdomain}
                  imageUrl={getImageUrl(
                    props.subdomain,
                    PAGE_CONFIG.IMAGE_URL_PATH.SITE,
                    PAGE_CONFIG.LOGO
                  )}
                  naviItems={naviItems}
                />
              </div>
            </>
          )}
        </div>
        {newUiVersion && props.login && (
          <div className={`${STUtils.dn_sp} ${STHeader.nav_new}`}>
            <NaviMenu
              showArrowIcon={false}
              naviItems={naviItems}
              className={STHeader.header_nav_menu}
            />
          </div>
        )}
      </div>
      {location.pathname === "/" && isNotEmailCondirmed && (
        <MailAttention
          onClick={onClickRequestEmailVerification}
          error={error}
        />
      )}
    </header>
  );
};

export default Header;

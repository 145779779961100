import { FieldErrors, FieldValues, Path, UseFormRegister } from "react-hook-form";
import Label from "../../atoms/form/reactHookForms/Label";
import ErrorMessage from "../../atoms/form/reactHookForms/ErrorMessage";
import OptionMessage from "./OptionMessage";
import SelectBox from "../../atoms/form/reactHookForms/SelectBox";
import { SelectBoxOption } from "../../../types/form/selectBoxOption";

type Props<T extends FieldValues> = {
  label: string;
  formName: Path<T>;
  register: UseFormRegister<T>;
  errors: FieldErrors<T>;
  placeholder: string;
  options: SelectBoxOption[];
  required?: boolean;
  isReadOnly?: boolean;
  defaultValue?: string;
  optionInformation?: string;
}

const TextForm = <T extends FieldValues>(props: Props<T>) => {
  const {
    label,
    formName,
    register,
    errors,
    options,
    required,
    defaultValue,
    optionInformation,
  } = props;

  const isErrors = !!errors[formName];
  const errorMessage = errors[formName]?.message as string;

  return (
    <>
      <Label label={label} htmlFor={formName} required={required} />
      {optionInformation !== undefined &&
        <OptionMessage information={optionInformation} />
      }
      <SelectBox<T>
        formName={formName}
        register={register}
        options={options}
        defaultValue={defaultValue}
      />
      {isErrors &&
        <ErrorMessage name={formName} message={errorMessage} />
      }
    </>
  );
};

export default TextForm;

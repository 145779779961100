import { useState, useCallback, useEffect } from "react";

type Breakpoint = {
  slidesPerView: number;
  slidesPerGroup: number;
  centeredSlides: boolean;
  loopThreshold: number;
};

export const useSwiperSettings = (
  itemsLength: number,
  breakpoints: { default: Breakpoint; sp: Breakpoint }
) => {
  const [shouldLoop, setShouldLoop] = useState<boolean | undefined>(undefined);
  const [slidesPerView, setSlidesPerView] = useState<number>(
    breakpoints.default.slidesPerView
  );
  const [slidesPerGroup, setSlidesPerGroup] = useState<number>(
    breakpoints.default.slidesPerGroup
  );
  const [centeredSlides, setCenteredSlides] = useState<boolean>(
    breakpoints.default.centeredSlides
  );

  const updateSwiperSettings = useCallback(() => {
    if (itemsLength === 0) return;
    const windowWidth = window.innerWidth;
    const currentBreakpoint =
      windowWidth < 800 ? breakpoints.sp : breakpoints.default;

    setSlidesPerView(currentBreakpoint.slidesPerView);
    setSlidesPerGroup(currentBreakpoint.slidesPerGroup);
    setCenteredSlides(currentBreakpoint.centeredSlides);
    setShouldLoop(itemsLength > currentBreakpoint.loopThreshold);
  }, [itemsLength, breakpoints]);

  useEffect(() => {
    updateSwiperSettings();
    window.addEventListener("resize", updateSwiperSettings);
    return () => {
      window.removeEventListener("resize", updateSwiperSettings);
    };
  }, [updateSwiperSettings, itemsLength]);

  return { shouldLoop, slidesPerView, slidesPerGroup, centeredSlides };
};

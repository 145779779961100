import TAppStaticInfoArticle from "../../types/TAppStaticInfoArticle";
import { useEffect, useState } from "react";
import "../../resources/CKEEditor.css";
import { useHistory } from "react-router-dom";
import { fetchContentDetailFixed } from "../../utils/api/fetchContentDetailFixed";
import { routes } from "../../router/Router";
import StaticInfoArticleCard from "../molecules/StaticInfoArticleCard";
import { getAuth } from "firebase/auth";

type Props = {
  slug: string;
  article?: TAppStaticInfoArticle;
};

const StaticInfoCard: React.FC<Props> = ({
  slug,
  article = undefined,
}: Props) => {
  const [infoArticle, setInfoArticle] = useState<TAppStaticInfoArticle>();
  const history = useHistory();
  const auth = getAuth();
  useEffect(() => {
    !article
      ? fetchContentDetailFixed(slug).then((res) => {
          if (!res.isSuccess || !res.result)
            return history.push(routes.error.path);
          const newInfoArticle = new TAppStaticInfoArticle(
            res.result.title,
            res.result.content,
            auth.currentUser?.uid
          );
          setInfoArticle(newInfoArticle);
        })
      : setInfoArticle(article);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug, history]);

  return infoArticle ? (
    <StaticInfoArticleCard
      title={infoArticle.title}
      content={infoArticle.content}
      uid={infoArticle.uid}
    />
  ) : (
    <></>
  );
};

export default StaticInfoCard;

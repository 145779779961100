import { useEffect, useState } from "react";

export const CheckSpFlag = () => {
  const width_threshhold = 800; // PCとSP判定の横幅閾値
  const [spFlag, setSpFlag] = useState<boolean>(window.innerWidth < width_threshhold);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const handleResize = () => {
        setSpFlag(window.innerWidth < width_threshhold);
      };

      window.addEventListener("resize", handleResize);
      handleResize();
      return () => window.removeEventListener("resize", handleResize);
    } else {
      return;
    }
  }, []);

  return spFlag;
};
import * as React from "react";
import { SVGProps } from "react";

const SvgSearch = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.0557 1.9438C12.7633 0.650392 11.0573 -0.000967648 9.36344 1.07895e-06C7.66916 -0.000967648 5.96364 0.650392 4.67117 1.9438C3.37776 3.23627 2.72591 4.94226 2.72787 6.63559C2.72641 7.81866 3.05158 9.00323 3.68245 10.053L0.469114 13.2663C-0.156371 13.8918 -0.156371 14.9054 0.469114 15.5309C1.0941 16.1564 2.10823 16.1564 2.73322 15.5309L5.94702 12.3171C6.99631 12.9484 8.18134 13.2731 9.36344 13.2717C11.0572 13.2731 12.7633 12.6218 14.0557 11.3283C15.3486 10.0359 16.0005 8.33034 15.999 6.63556C16.001 4.94226 15.3487 3.23627 14.0557 1.9438ZM12.2437 9.51687C11.4454 10.3147 10.4103 10.7083 9.36344 10.7102C8.31659 10.7083 7.28195 10.3147 6.48263 9.51687C5.6848 8.71758 5.29125 7.68291 5.28928 6.63559C5.29125 5.58924 5.68477 4.55411 6.48263 3.75529C7.28192 2.95746 8.31659 2.56391 9.36344 2.56194C10.4103 2.56391 11.4454 2.95743 12.2442 3.75529C13.0421 4.55411 13.4356 5.58924 13.4376 6.63559C13.4356 7.68295 13.0421 8.71758 12.2437 9.51687Z"
      fill="white"
    />
  </svg>
);

export default SvgSearch;

import * as React from "react";
import { SVGProps } from "react";

const SvgPrev = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.88 3.893 8.773 10l6.107 6.12L13 18l-8-8 8-8 1.88 1.893Z"
      fill="#337E62"
    />
  </svg>
);

export default SvgPrev;

import { useContext, useState } from "react";
import STUtils from "../../assets/styles/utility.module.scss";
import STSearchForm from "../../assets/styles/molecules/searchForm.module.scss"
import Text from "../atoms/form/Text"
import Button from "../atoms/Button"
import IconButton from "../atoms/IconButton"
import SvgSearch from "../atoms/icons/Search";
import PAGE_CONFIG from "../../config/page";
import CONSTANTS from "../../utils/constants";
import { SiteContext } from "../../providers/SiteProvider";

type Props = {
  className: string;
  placeholder?: string;
  executeSearch: (query: string) => void;
};

const SearchForm: React.FC<Props> = (props: Props) => {
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [query, setQuery] = useState<string>('');
  const {newUIVersion} = useContext(SiteContext);
  const handleClick = () => {
    setIsOpened(!isOpened);
  };

  const handleChange = (value: string) => {
    if (value && value.length > CONSTANTS.MAX_LENGTH.SEARCH_FORM_TEXT) {
      setQuery(value.substring(0, CONSTANTS.MAX_LENGTH.SEARCH_FORM_TEXT));
      return;
    }
    setQuery(value);
  }

  const handleSearch = () => {
    setIsOpened(false);
    props.executeSearch(query);
  }

  return (
    <>
      <div className={`${props.className} ${STUtils.dn_pc}`}>
        {/* SP */}
        {!newUIVersion &&
          <div className={STSearchForm.root}>
            <Text
                className={STSearchForm.input}
                name='q'
                defaultValue={query}
                placeholder={newUIVersion ? 'サイト内検索' : props.placeholder}
                onChange={handleChange}
              />
            <Button 
              className={`${STSearchForm.input_button} ${PAGE_CONFIG.STATIC_CLASSES.NAVI_ICON}`}
              buttonClassName={STSearchForm.input_button_inner}
              clickAction={handleSearch}
              text={
                <>
                  <SvgSearch/>
                </>
              }
            />
          </div>
        }
      </div>
      <div className={`${props.className} ${STUtils.dn_sp}`}>
        {/* PC */}
        <div className={STSearchForm.root}>
          {!newUIVersion && 
            <>
              {isOpened && 
                <div className={STSearchForm.wrap}>
                  <Text
                      className={STSearchForm.input}
                      name='q'
                      defaultValue={query}
                      placeholder={props.placeholder}
                      onChange={handleChange}
                    />
                  <Button
                    className={`${STSearchForm.button} ${PAGE_CONFIG.STATIC_CLASSES.NAVI_ICON}`}
                    buttonClassName={STSearchForm.button_inner}
                    clickAction={handleSearch}
                    text={
                      <>
                        <SvgSearch/><span className={STSearchForm.button_text}>検索</span>
                      </>
                    }
                  />
                </div>
              }
              <IconButton className={`${STSearchForm.icon} ${PAGE_CONFIG.STATIC_CLASSES.NAVI_ICON}`} clickAction={handleClick}><SvgSearch /></IconButton>
            </>
          }
          {newUIVersion && 
            <div className={STSearchForm.form_new}>
              <Text
                  className={STSearchForm.input_new}
                  name='q'
                  defaultValue={query}
                  placeholder={newUIVersion ? 'サイト内検索' : props.placeholder}
                  onChange={handleChange}
                />
              <Button
                className={`${STSearchForm.button_new} ${PAGE_CONFIG.STATIC_CLASSES.NAVI_ICON}`}
                buttonClassName={STSearchForm.button_inner}
                clickAction={handleSearch}
                text={
                  <>
                    <SvgSearch/><span className={STSearchForm.button_text}>検索</span>
                  </>
                }
              />
            </div>
          }
        </div>
      </div>
    </>
  );
};

export default SearchForm;

import client from "../../api/client";
import TApiResult from "../../types/api/TApiResult";
import { AxiosError } from "axios";
import getErrorProperty from "./config/getErrorProperty";
import { TPopupSettingResponse } from "../../api/popup_setting";
import { getAuth } from "firebase/auth";
import tokenNotFound from "./config/tokenNotFound";

export type TPopupSettingResult = {
  result?: TPopupSettingResponse;
};

export const fetchPopupSettings = async (): Promise<
  TApiResult & TPopupSettingResult
> => {
  const auth = getAuth();
  const token = await auth.currentUser?.getIdToken();
  if (!token) return tokenNotFound;
  return client()
    .popup_setting.get({
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      return {
        isSuccess: true,
        status: 200,
        message: "",
        result: res.body,
      };
    })
    .catch((e: AxiosError) => {
      const { status, message } = getErrorProperty(e, "fetchMessageSetting");
      return {
        isSuccess: false,
        status: status,
        message: message,
      };
    });
};

import STUtils from "../../assets/styles/utility.module.scss";
import STMailAddressConfirmForm from "../../assets/styles/organisms/mailAddressConfirmForm.module.scss";
import Text from "../atoms/form/Text";
import { useState } from "react";
import Button from "../atoms/Button";
import STButton from "../../assets/styles/atoms/button.module.scss";
import TApiResult from "../../types/api/TApiResult";
import HtmlMessage from "../atoms/HtmlMessage";
import useMultipleClickPreventer from "../molecules/UseMultipleClickPreventer";
import { validateEmail } from "../../utils/form/emailValidate";
import { useLocation } from "react-router-dom";

type Props = {
  setIsApplied: (isApplied: boolean) => void;
  postRequest: (email: string) => Promise<TApiResult>;
  message?: string;
  errorMessage?: string;
  emailErrorMessage?: string;
};

const MailAddressConfirmForm: React.FC<Props> = (props: Props) => {
  const [mailAddress, setMailAddress] = useState("");
  const [errorMessage, setErrorMessage] = useState<string>();
  const [emailErrorMessage, setEmailErrorMessage] = useState<string>();
  const [canApply, setCanApply] = useState(false);
  const location = useLocation();
  const path = location.pathname;

  const hundleInput = (email: string) => {
    let apply = true;
    const { emailErrorMessage } = validateEmail(email);
    setEmailErrorMessage(emailErrorMessage);
    setCanApply(apply);
    setMailAddress(email);
  };

  const hundleSubmit = useMultipleClickPreventer<() => Promise<any>>(() => {
    if (!canApply) return Promise.resolve();
    return props.postRequest(mailAddress).then((res: TApiResult) => {
      if (!res.isSuccess) return setErrorMessage(res.message);
      props.setIsApplied(true);
      return;
    });
  });

  return (
    <div className={STUtils.container_form}>
      <h1 className={STMailAddressConfirmForm.mail_address_confirm_form_title}>
        パスワードの再設定
      </h1>
      <p className={STMailAddressConfirmForm.mail_address_confirm_form_txt}>
        ご登録いただいたメールアドレスを入力し、<br />メールを送信してください。
      </p>
      {errorMessage && (
        <div className={STMailAddressConfirmForm.error_message}>
          {errorMessage}
        </div>
      )}
      <span>
        <Text
          className={STMailAddressConfirmForm.mail_address_confirm_form_input}
          name="email"
          defaultValue={mailAddress}
          placeholder="メールアドレス"
          onChange={(input) => hundleInput(input)}
        />
      </span>
      {emailErrorMessage && (
        <div className={STMailAddressConfirmForm.email_error_message}>
          {emailErrorMessage}
        </div>
      )}
      {props.message && <HtmlMessage message={props.message} />}
      <Button
        className={
          canApply
            ? `${STMailAddressConfirmForm.apply_btn} ${STButton.primary}`
            : `${STMailAddressConfirmForm.apply_btn} ${STButton.disable}`
        }
        clickAction={hundleSubmit}
        text="送信する"
      />
    </div>
  );
};

export default MailAddressConfirmForm;

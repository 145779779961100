import { SelectBoxOption } from "../../types/form/selectBoxOption";
import { TAppPointHistory } from "../../types/TAppPointHistory";
import STPointHistoryList from "../../assets/styles/organisms/pointHistoryList.module.scss";
import Pagenation from "../molecules/AppPagenation";
import STUtils from "../../assets/styles/utility.module.scss";
import TextDate from "../atoms/TextDate";
import TPager from "../../types/TAppPager";
import { format } from "../../types/api/TApiDatetime";
import SelectBox from "../atoms/form/SelectBox";
import { routes } from "../../router/Router";
import { useContext } from "react";
import { SiteContext } from "../../providers/SiteProvider";
import { CheckSpFlag } from "../../hooks/CheckSpFlag";

type Props = {
  pointHistories: TAppPointHistory[];
  pager: TPager;
  year: string;
  years: string[];
  setYear: (year: string) => void;
};

const PointHistoryList: React.FC<Props> = (props: Props) => {
  const options: SelectBoxOption[] = props.years.map((year, index) => {
    return { id: index, value: year, title: year };
  });
  const site = useContext(SiteContext);

  return (
    <div className="point_history_list_container">
      <div className={STPointHistoryList.point_history_list}>
        <div className={STPointHistoryList.year_select}>
          <SelectBox
            className={STPointHistoryList.selectBox}
            name="method"
            defaultValue={props.year}
            options={options}
            onChange={(year) => props.setYear(year)}
          />
        </div>

        <div className={STPointHistoryList.point_history_header}>
          <div className={STPointHistoryList.point_history_body_cell__header}>
            ステータス
          </div>
          <div className={STPointHistoryList.point_history_body_cell__header}>
            申請内容
          </div>
          <div className={STPointHistoryList.point_history_body_cell__header}>
            {CheckSpFlag()  ? (
              site.isPoint ? "ポイント\nスコア" : "リワード\nスコア"
              ):(
              site.isPoint ? "ポイントスコア" : "リワードスコア"
            )}
          </div>
          <div
            className={`${STPointHistoryList.point_history_body_cell__header} ${STUtils.dn_sp}`}
          >
            実施月
          </div>
          <div
            className={`${STPointHistoryList.point_history_body_cell__header} ${STUtils.dn_sp}`}
          >
            申請日
          </div>
        </div>

        {props.pointHistories.length === 0 && (
          <div className={STPointHistoryList.point_history_body_empty}>
            ポイント履歴はありません。
          </div>
        )}
        {props.pointHistories.map((history, index) => (
          <div className={STPointHistoryList.point_history_body} key={index}>
            <div className={`${STUtils.dn_pc} ${STPointHistoryList.body_date}`}>
              <div className={STPointHistoryList.action_month}>
                実施月&nbsp;:&nbsp;{history.actionMonth}
              </div>
              <div className={STPointHistoryList.entry_date}>
                申請日&nbsp;:&nbsp;
                <TextDate date={format(history.entryDate, "yyyy/MM/dd")} />
              </div>
            </div>
            <div className={STPointHistoryList.table_body}>
              <div className={STPointHistoryList.point_history_body_row}>
                <div
                  className={
                    history.status === "承認済"
                      ? `${STPointHistoryList.point_history_body_cell_approval}`
                      : `${STPointHistoryList.point_history_body_cell}`
                  }
                >
                  {history.status}
                </div>
                <div className={STPointHistoryList.point_history_body_cell}>
                  {history.pointName}
                </div>
                <div className={STPointHistoryList.point_history_body_cell}>
                  {history.point}
                  {site.isPoint && "pt"}
                </div>
                <div
                  className={`${STPointHistoryList.point_history_body_cell} ${STUtils.dn_sp}`}
                >
                  {history.actionMonth}
                </div>
                <div
                  className={`${STPointHistoryList.point_history_body_cell} ${STUtils.dn_sp}`}
                >
                  {format(history.entryDate, "yyyy/MM/dd")}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <Pagenation
        className=""
        urlPath={routes.pointHistory.path}
        total={props.pager.total}
        start={1}
        end={props.pager.max}
        current={props.pager.current || 1}
      />
    </div>
  );
};

export default PointHistoryList;

import STAppCalendarDate from "../../assets/styles/atoms/appCalendarDate.module.scss";

type Props = {
  dateNum?: number;
  isTarget: boolean;
  isClicked: boolean;
  isFull: boolean;
  hundleClick: (dateCount: number) => void;
};

const AppCalendarDate: React.FC<Props> = (props: Props) => {
  const hundleClick = (dateCount?: number) => {
    if (!dateCount || props.isFull || props.isClicked || !props.isTarget)
      return;
    props.hundleClick(dateCount);
  };
  if (!props.dateNum) return <li className={`${STAppCalendarDate.cell}`}></li>;

  return (
    <li
      className={`
          ${STAppCalendarDate.cell} 
          ${
            props.isTarget
              ? !props.isFull
                ? props.isClicked
                  ? STAppCalendarDate.event_calendar_target_date_clicked
                  : STAppCalendarDate.event_canlendar_target_date
                : STAppCalendarDate.cell_fullhouse
              : STAppCalendarDate.cell_disable
          } 
        `}
      key={props.dateNum}
      onClick={() => hundleClick(props.dateNum)}
    >
      {props.dateNum}
      {props.isFull && <p className={STAppCalendarDate.full_house_txt}>満席</p>}
    </li>
  );
};

export default AppCalendarDate;

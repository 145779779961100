// 入力必須
export const ERROR_ENTRY_FORM_REQURIRED_FIRSTNAME =
  "名前（姓）を入力してください。";
export const ERROR_ENTRY_FORM_REQURIRED_LASTNAME =
  "名前（名）を入力してください。";
export const ERROR_ENTRY_FORM_REQURIRED_FIRSTNAME_KANA =
  "ふりがな（姓）を入力してください。";
export const ERROR_ENTRY_FORM_REQURIRED_LASTNAME_KANA =
  "ふりがな（名）を入力してください。";
export const ERROR_ENTRY_FORM_REQURIRED_PHONE_NUMBER =
  "電話番号を入力してください。";
export const ERROR_ENTRY_FORM_REQURIRED_EMAIL =
  "メールアドレスを入力してください。";
export const ERROR_ENTRY_FORM_REQURIRED_POST_CODE =
  "郵便番号を入力してください。";
export const ERROR_ENTRY_FORM_REQURIRED_ADDRESS1 =
  "都道府県を選択してください。";
export const ERROR_ENTRY_FORM_REQURIRED_ADDRESS2 =
  "市区町村を入力してください。";
export const ERROR_ENTRY_FORM_REQURIRED_ADDRESS3 =
  "町名・番地を入力してください。";
export const ERROR_ENTRY_FORM_REQURIRED_PASSWORD =
  "パスワードを入力してください。";
export const ERROR_ENTRY_FORM_REQURIRED_INSTAGRAM =
  "Instagramユーザネームを入力してください。";
export const ERROR_ENTRY_FORM_REQURIRED_CONTRACTOR_FIRSTNAME =
  "契約者名義（姓）を入力してください。";
export const ERROR_ENTRY_FORM_REQURIRED_CONTRACTOR_LASTNAME =
  "契約者名義（名）を入力してください。";
export const ERROR_ENTRY_FORM_REQURIRED_PASSWORDCONFIRM =
  "確認用パスワードを入力してください。";
export const ERROR_ENTRY_FORM_REQURIRED_APPLY =
  "同意にチェックを入れてください。";

// 入力桁数
export const ERROR_ENTRY_FORM_LENGTH_LIMIT_FIRSTNAME =
  "名前（姓）は最低1文字以上、最大64文字以下で入力してください。";
export const ERROR_ENTRY_FORM_LENGTH_LIMIT_LASTNAME =
  "名前（名）は最低1文字以上、最大64文字以下で入力してください。";
export const ERROR_ENTRY_FORM_LENGTH_LIMIT_FIRSTNAME_KANA =
  "ふりがな（姓）は最低1文字以上、最大64文字以下で入力してください。";
export const ERROR_ENTRY_FORM_LENGTH_LIMIT_LASTNAME_KANA =
  "ふりがな（名）は最低1文字以上、最大64文字以下で入力してください。";
export const ERROR_ENTRY_FORM_LENGTH_LIMIT_CONTRACTOR_FIRSTNAME =
  "契約者名義（姓）は最低1文字以上、最大64文字以下で入力してください。";
export const ERROR_ENTRY_FORM_LENGTH_LIMIT_CONTRACTOR_LASTNAME =
  "契約者名義（名）は最低1文字以上、最大64文字以下で入力してください。";
export const ERROR_ENTRY_FORM_LENGTH_LIMIT_PHONE_NUMBER =
  "電話番号は最低10桁以上、最大11桁以下で入力してください。";
export const ERROR_ENTRY_FORM_LENGTH_LIMIT_ADDRESS2 =
  "市区町村は最低1文字以上、最大32文字以下で入力してください。";
export const ERROR_ENTRY_FORM_LENGTH_LIMIT_ADDRESS3 =
  "町名・番地は最低3文字以上、最大255文字以下で入力してください。";
export const ERROR_ENTRY_FORM_LENGTH_LIMIT_INSTAGRAM =
  "instagramユーザーネームは最低1文字以上、最大30文字以下で入力してください。";
export const ERROR_ENTRY_FORM_LENGTH_LIMIT_PASSWORD =
  "パスワードは最低8文字以上、最大20文字以下で入力してください。";

// 入力規則
export const ERROR_ENTRY_FORM_NAME_FORMAT =
  "ひらがな、カタカナ、漢字、半角英字で入力してください。";
export const ERROR_ENTRY_FORM_HIRAGANA_FORMAT = "ひらがなで入力してください。";
export const ERROR_ENTRY_FORM_EMAIL_FORMAT =
  "メールアドレスの形式で入力してください。";
export const ERROR_ENTRY_FORM_HANKAKU_NUMBER_FORMAT =
  "半角数字で入力してください。";
export const ERROR_ENTRY_FORM_POST_CODE_FORMAT =
  "123-4567または1234567の形式で入力してください。";
export const ERROR_ENTRY_FORM_CITY_NAME_FORMAT =
  "ひらがな、カタカナ、漢字で入力してください。";
export const ERROR_ENTRY_FORM_ADDRESS_FORMAT =
  "ひらがな、カタカナ、漢字、英字、数字、ハイフンで入力してください。";
export const ERROR_ENTRY_FORM_INSTAGRAM_FORMAT =
  "半角英数字と半角記号ピリオド（.）またはアンダーライン（_）のみ入力が可能です。";
export const ERROR_ENTRY_FORM_PASSWORD_FORMAT =
  "正しいパスワードの形式で入力してください。";
export const ERROR_ENTRY_FORM_SPACE_FORMAT =
  "文字の中にスペースを含めないでください。";
export const ERROR_BIRTHDAY_DATE = "年月日全て選択してください。";

// パスワード一致チェック
export const ERROR_ENTRY_FORM_MISMATCHED_PASSWORD =
  "パスワードが一致していません。";

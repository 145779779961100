import STEventInvitationEntryCompleteCard from "../../assets/styles/organisms/eventInvitationEntryCompleteCard.module.scss";
import PAGE_CONFIG from "../../config/page";
import ButtonLink from "../atoms/ButtonLink";
import STButtonLink from "../../assets/styles/atoms/buttonLink.module.scss";
import { Link } from "react-router-dom";
import { routes } from "../../router/Router";

type Props = {
  eventId: number;
  email: string;
  referrerId?: string;
};

const EventInvitationEntryCompleteCard: React.FC<Props> = ({
  eventId,
  email,
  referrerId,
}) => {
  return (
    <>
      <h1 className={STEventInvitationEntryCompleteCard.title}>予約完了</h1>
      {email !== "" && (
        <p className={STEventInvitationEntryCompleteCard.description}>
          {email}あてに予約完了メールをお送りしました。
        </p>
      )}
      <div className={STEventInvitationEntryCompleteCard.inner}>
        <div className={STEventInvitationEntryCompleteCard.sub_inner}>
          <p className={STEventInvitationEntryCompleteCard.header}>
            予約内容を変更する場合
          </p>
          <span className={STEventInvitationEntryCompleteCard.text}>
            <Link
              to={routes.contact.path}
              className={STEventInvitationEntryCompleteCard.link}
            >
              お問合せ
            </Link>
            からご連絡ください。
          </span>
          {email !== "" && (
            <>
              <p className={STEventInvitationEntryCompleteCard.header}>
                メールが届かない場合
              </p>
              <span className={STEventInvitationEntryCompleteCard.text}>
                <Link
                  to={routes.faq.path}
                  className={STEventInvitationEntryCompleteCard.link}
                >
                  よくあるご質問
                </Link>
                の「メールが届かない」をご確認ください。
              </span>
            </>
          )}
        </div>
      </div>
      <ButtonLink
        url={`/events/${eventId}${referrerId ? `?referrer_id=${referrerId}` : ""}`}
        text="イベントページに戻る"
        buttonClassName={`${STEventInvitationEntryCompleteCard.btn} ${STButtonLink.primary}  ${PAGE_CONFIG.STATIC_CLASSES.MORE_BUTTON_TEXT}`}
        linkClassName={STEventInvitationEntryCompleteCard.btn_link}
        external={true}
      />
    </>
  );
};

export default EventInvitationEntryCompleteCard;

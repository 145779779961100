import { TAppFormError } from "../../../types/TAppFormError";
import AlartMessages from "../../atoms/AlartMessages";
import STTextFormRow from "../../../assets/styles/molecules/formRow/textFormRow.module.scss";
import TextArea from "../../atoms/form/TextArea";
import validateFormRow from "../../../utils/form/validateFormRow";

type Props<T> = {
  rows: number;
  cols: number;
  defaultValue: string;
  title?: string;
  name: string;
  className: string;
  formKey: T;
  errors: TAppFormError<T>[];
  hundleOnChange: (value: string) => void;
  setErrors: (errors: TAppFormError<T>[]) => void;
  required?: boolean;
  maxLength?: number;
  minLength?: number;
  pattern?: RegExp;
  patternText?: string;
};

const TextAreaFormRow = <T,>({
  rows,
  cols,
  defaultValue,
  title,
  name,
  className,
  formKey,
  errors,
  hundleOnChange,
  setErrors,
  required = false,
  maxLength,
  minLength,
  pattern,
  patternText,
}: Props<T>) => {
  const hundleChange = (input: string) => {
    validateFormRow<T>({
      input,
      errors,
      setErrors,
      formKey,
      maxLength,
      minLength,
      pattern,
      patternText,
      required,
    });
    hundleOnChange(input);
  };

  return (
    <div className={STTextFormRow.text_form_row}>
      {title && (
        <span>
          <small>{title}</small>
        </span>
      )}
      <div>
        <TextArea
          rows={rows}
          cols={cols}
          className={className}
          name={name}
          defaultValue={defaultValue}
          onChange={(input) => hundleChange(input)}
        ></TextArea>
        {AlartMessages<T>({
          title: name,
          errors: errors,
          keys: [formKey],
        })}
      </div>
    </div>
  );
};

export default TextAreaFormRow;

import { AxiosError } from "axios";
import client from "../../api/client";
import TApiResult from "../../types/api/TApiResult";
import getErrorProperty from "./config/getErrorProperty";
import { TMemberIdentificationResponce } from "../../api/member/identification/_id@string/_datetime_hash@string";

export type TApiMemberIdentificationResult = {
  result?: TMemberIdentificationResponce;
};

export const postMemberIdentification = async (
  id: string,
  datetimeHash: string
): Promise<TApiResult> => {
  return client()
    .member.identification._id(id)
    ._datetime_hash(datetimeHash)
    .post({ body: {} })
    .then((res) => {
      return {
        isSuccess: true,
        status: 200,
        message: "",
        result: res.body,
      };
    })
    .catch((e: AxiosError) => {
      const { status, message, errorMessages } = getErrorProperty(
        e,
        "postMemberIdentification"
      );
      const invalidMessage =
        status === 409
          ? message
          : errorMessages && errorMessages.details.length > 0
          ? errorMessages.details[0].values.join(" ")
          : "正しいメールアドレスを入力してください。";

      return {
        isSuccess: false,
        status: status,
        message: invalidMessage,
      };
    });
};

import STUtils from "../../assets/styles/utility.module.scss";
import STLoginForm from "../../assets/styles/organisms/loginForm.module.scss";
import STButton from "../../assets/styles/atoms/button.module.scss";

import "../../assets/styles/font.module.scss";
import { useState } from "react";
import Text from "../atoms/form/Text";
import Password from "../atoms/form/Password";
import { Link, useHistory, useLocation } from "react-router-dom";
import AppCheckBox from "../atoms/form/AppCheckBox";
import Button from "../atoms/Button";
import ButtonLink from "../atoms/ButtonLink";
import PAGE_CONFIG from "../../config/page";
import TApiResult from "../../types/api/TApiResult";
import {
  postMemberRegisterStatus,
  TApMemberRegisterStatusResult,
} from "../../utils/api/postMemberRegisterStatus";
import { MEMBER_STATUS } from "../../types/TAppMemberStatus";
import { useAuth } from "../../hooks/useAuth";
import CONSTANTS from "../../utils/constants";
import { fetchSite, TApiSiteResult } from "../../utils/api/fetchSite";
import { postMemberLinkUserLineAccount } from "../../utils/api/postMemberLinkUserLineAccount";
import { routes } from "../../router/Router";
import { validateEmail } from "../../utils/form/emailValidate";

type TLoginForm = {
  email: string;
  password: string;
  save: string;
};

type Props = {
  linkToken?: string;
};

const LoginForm: React.FC<Props> = ({ linkToken }) => {
  const initial: TLoginForm = { email: "", password: "", save: "1" };
  const { login } = useAuth();
  const [loginForm, setLoginForm] = useState<TLoginForm>(initial);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [emailErrorMessage, setEmailErrorMessage] = useState<string>();
  const history = useHistory();
  let location = useLocation();
  let { from } = (location.state as { from: string }) || {
    from: { pathname: "/" },
  };
  const signin = async (email: string, password: string, save: string) => {
    fetchSite().then((res: TApiResult & TApiSiteResult) => {
      if (!res.isSuccess || !res.result) return history.push(routes.error.path);
      login(email, password, save === "1", res.result.gcp_id).then(
        (isSucceeded) => {
          if (isSucceeded) {
            if (linkToken !== undefined) {
              postMemberLinkUserLineAccount({
                linkToken,
              })
                .then((res) => {
                  window.location.href = res.result!.values;
                })
                .catch(() => {
                  setErrorMessage("LINE連携に失敗しました。");
                });
            } else {
              return history.push(from);
            }
          } else
            setErrorMessage("アカウント名、またはパスワードが間違っています。");
        }
      );
    });
  };

  const hundleInput = (email: string, password: string, save: string) => {
    const { emailErrorMessage } = validateEmail(email);
    setEmailErrorMessage(emailErrorMessage);
    const newLoginForm: TLoginForm = {
      email: email,
      password: password,
      save: save,
    };
    setLoginForm(newLoginForm);
  };

  const validate = (email: string, password: string): boolean => {
    return (
      CONSTANTS.PATTERN.EMAIL.test(email) &&
      CONSTANTS.PATTERN.PASSWORD.test(password)
    );
  };

  const hundleSubmit = (): void => {
    /*
    if (!validate(loginForm.email, loginForm.password)) {
      setErrorMessage(
        "正しい形式でメールアドレス、パスワードを入力してください。"
      );
      return;
    }
    */

    postMemberRegisterStatus(loginForm.email, loginForm.password)
      .then((res: TApiResult & TApMemberRegisterStatusResult): boolean => {
        if (!res.isSuccess || !res.result) {
          setErrorMessage("アカウント名、またはパスワードが間違っています。");
          return false;
        }
        if (res.result.status === MEMBER_STATUS.temporary) {
          setErrorMessage(
            "本登録が完了しておりません。自動返信メールから本登録をお願いします。URLの更新期限が切れている場合は再度メールアドレスの入力をしてください。"
          );
          return false;
        } else if (res.result.status === MEMBER_STATUS.waiting) {
          setErrorMessage(
            "ただいま会員登録申請の承認中のため、しばらくお待ちください。"
          );
          return false;
        } else {
          return true;
        }
      })
      .then((status: boolean) => {
        status && signin(loginForm.email, loginForm.password, loginForm.save);
      });
  };

  return (
    <section className={STUtils.container_form}>
      {errorMessage && (
        <div className={STLoginForm.error_message}>{errorMessage}</div>
      )}
      <Text
        className={`icon-mail ${STLoginForm.login_form_input}`}
        name="email"
        defaultValue={loginForm.email}
        placeholder="&#xe901;&nbsp;メールアドレス"
        onChange={(input) =>
          hundleInput(input, loginForm.password, loginForm.save)
        }
      />
      {emailErrorMessage && (
        <div className={STLoginForm.email_error_message}>
          {emailErrorMessage}
        </div>
      )}
      <Password
        className={`icon-pw ${STLoginForm.login_form_input}`}
        name="password"
        defaultValue={loginForm.password}
        placeholder="&#xe900;&nbsp;パスワード"
        onChange={(input) =>
          hundleInput(loginForm.email, input, loginForm.save)
        }
      />

      <div className={STLoginForm.login_form_password_reset_contain}>
        <div className={STLoginForm.login_form_password_reset}>
          <Link
            to={routes.resetPasswordMailAddressConfirm.path}
            className={STLoginForm.login_form_password_reset_link}
          >
            パスワードを忘れてしまった方
          </Link>
        </div>
        <div className={STLoginForm.login_form_password_reset}>
          <Link
            to={routes.faq.path}
            className={STLoginForm.login_form_password_reset_link}
          >
            ログインにお困りの方
          </Link>
        </div>
      </div>

      <div className={STLoginForm.login_form_password_save}>
        <AppCheckBox
          id="MemberSaved"
          className={`${STLoginForm.login_form_password_save_checkbox} ${PAGE_CONFIG.STATIC_CLASSES.CHECKBOX}`}
          checked={loginForm.save === "1"}
          value="1"
          onChange={(input) =>
            hundleInput(loginForm.email, loginForm.password, input)
          }
        />
        <label
          htmlFor="MemberSaved"
          className={STLoginForm.login_form_password_save_label}
        >
          ログイン状態を保存する
        </label>
      </div>
      <Button
        className={`${STLoginForm.login_form_apply_btn} ${STButton.primary}`}
        clickAction={hundleSubmit}
        text="ログイン"
      />
      <div className={STLoginForm.login_form_add_account_message}>
        アカウントをお持ちでない方
      </div>

      <ButtonLink
        url={routes.memberEntry.path}
        text="新規会員登録"
        buttonClassName={`${STLoginForm.login_form_apply_btn} ${STButton.tertiary}`}
      />

      {isLoggedIn ? <div data-testid="isLoggedIn" /> : <div />}
    </section>
  );
};

export default LoginForm;

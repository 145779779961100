// NOTE: https://qiita.com/saba_can00/items/bdefb28a1873658cf5d9
export const POINT_REQUEST_FORM_ITEM = {
  id: "id",
  entryMemo: "entry_memo",
  actionMonth: "action_month",
} as const;

type TypeOfPointRequestFormItem = typeof POINT_REQUEST_FORM_ITEM;
export type TAppPointRequestFormItem =
  TypeOfPointRequestFormItem[keyof TypeOfPointRequestFormItem];

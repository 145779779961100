import STUtils from "../../assets/styles/utility.module.scss";
import PAGE_CONFIG from "../../config/page";
import STDrawerMenuButton from "../../assets/styles/atoms/drawerMenuButton.module.scss";
import SvgHamburgerMenu from "./icons/HamburgerMenu";
import SvgClose from "./icons/Close";
import { useContext } from "react";
import { SiteContext } from "../../providers/SiteProvider";

type Props = {
  setIsOpen: (isOpen: boolean) => void;
  isOpen: boolean;
  subdomain: string;
};

const DrawerMenuButton: React.FC<Props> = (props: Props) => {
  const site = useContext(SiteContext);
  const newUiVersion = site.newUIVersion;
  const hundleClick = () => {
    props.setIsOpen(!props.isOpen);
  };
  return (
    <div className={STUtils.pc_dn}>
      <div
        className={
          props.isOpen
            ? `${!newUiVersion ? STDrawerMenuButton.drawerMenuButton : STDrawerMenuButton.drawerMenuButton_new}`
            : ``
        }
      >
        {props.isOpen ? (
          <SvgClose
            height={32}
            width={32}
            viewBox="-1 -3 24 24"
            onClick={() => hundleClick()}
          />
        ) : (
          <SvgHamburgerMenu
            height={32}
            width={32}
            viewBox="-1 -3 24 24"
            strokeWidth={"5px"}
            onClick={() => hundleClick()}
          />
        )}
      </div>
    </div>
  );
};

export default DrawerMenuButton;

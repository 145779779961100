import React from "react";
import STText from "../../../assets/styles/atoms/form/text.module.scss";

type Props = {
  className: string;
  name: string;
  defaultValue?: string;
  placeholder?: string;
  autoComplete?: string;
  onChange: (value: string) => void;
};

const Text: React.FC<Props> = (props: Props) => {
  const hundleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange(e.target.value);
  };
  return (
    <input
      type="text"
      className={`${STText.text} ${props.className}`}
      aria-label={props.name}
      autoComplete={props.autoComplete}
      placeholder={props.placeholder}
      value={props.defaultValue}
      onChange={hundleChange}
    />
  );
};

export default Text;

import BaseHead from "../templates/BaseHead";
import STUtils from "../../assets/styles/utility.module.scss";
import { getSubdomain } from "../../utils/getSubdomain";
import LoginBackground from "../organisms/LoginBackground";
import { useEffect, useState } from "react";
import MailAddressConfirmApplyCard from "../organisms/MailAddressConfirmApplyCard";
import { fetchMessageSetting } from "../../utils/api/fetchMessageSetting";
import { PANEL } from "../../types/TAppPanel";
import { postMemberIdentificationSend } from "../../utils/api/postMemberIdentificationSend";
import MailAddressConfirmForm from "../organisms/MailAddressConfirmForm";
import { useHistory } from "react-router-dom";
import { routes } from "../../router/Router";

const MailAddressConfirm: React.FC = () => {
  const [isApplied, setIsApplied] = useState(false);
  const subdomain = getSubdomain(window.location.hostname);
  const [message, setMessage] = useState("");
  const history = useHistory();

  useEffect(() => {
    fetchMessageSetting(PANEL.mailAddressConfirm).then((res) => {
      if (!res.isSuccess || !res.result) return history.push(routes.error.path);
      const newMessage =
        res.result.length !== 0 ? res.result[0].letter_content : "";
      setMessage(newMessage);
    });
  }, []);

  return (
    <BaseHead
      title={routes.mailAddressConfirm.title}
      login={false}
      subdomain={subdomain}
      hasBackground={true}
    >
      <LoginBackground />
      <section className={STUtils.container_large}>
        {isApplied ? (
          <MailAddressConfirmApplyCard action="会員登録" />
        ) : (
          <MailAddressConfirmForm
            setIsApplied={setIsApplied}
            postRequest={postMemberIdentificationSend}
            message={message}
          />
        )}
      </section>
    </BaseHead>
  );
};
export default MailAddressConfirm;

import { TAppTimeRange } from "../../types/TAppTimeRange";
import AppCalendar from "../molecules/AppCalendar";
import TimeRangeList from "../molecules/TimeRangeList";
import STEventInvitationDateForm from "../../assets/styles/organisms/eventInvitationDateForm.module.scss";
import { TAppEvent } from "../../types/TAppEvent";
import { TAppCalendarTarget } from "../../types/TAppCalendarTarget";
import SvgStep1 from "../atoms/icons/Step1";
import STUtils from "../../assets/styles/utility.module.scss";
import SvgStep1Sp from "../atoms/icons/Step1Sp";

type Props = {
  targets: TAppCalendarTarget[];
  month: string;
  setMonth: (month: string) => void;
  event: TAppEvent;
  date: string;
  setDate: (date: string) => void;
  timeRange: TAppTimeRange;
  setTimeRange: (ranges: TAppTimeRange) => void;
  resetTimeRange: () => void;
  setDateTimeSelected: (selected: boolean) => void;
};

const EventInvitationDateForm: React.FC<Props> = (props: Props) => {
  const timeRanges =
    props.targets
      .find((t) => t.month === props.month)
      ?.dates.find((d) => d.date === props.date)?.timeRanges || [];

  return (
    <>
      <div className={STEventInvitationDateForm.step_container}>
        <SvgStep1
          className={`${STEventInvitationDateForm.step_content} ${STEventInvitationDateForm.first_step_content} ${STUtils.dn_sp}`}
          viewBox={"0 0 880 34"}
        />
        <SvgStep1Sp
          className={`${STEventInvitationDateForm.step_content} ${STEventInvitationDateForm.first_step_content} ${STUtils.dn_pc}`}
          viewBox={"0 0 275 34"}
        />
      </div>
      <div className={STEventInvitationDateForm.event_tiile}>
        イベント：{props.event.title}
      </div>
      <h1 className={STEventInvitationDateForm.title}>Step1. 予約日時の選択</h1>
      <p className={STEventInvitationDateForm.text}>
        予約日<span className={STEventInvitationDateForm.attention}>必須</span>
      </p>
      <AppCalendar
        month={props.month}
        setMonth={props.setMonth}
        targets={props.targets}
        clickedDate={props.date}
        setClickedDate={props.setDate}
        resetTimeRange={props.resetTimeRange}
        setTimeRange={props.setTimeRange}
      />
      <p className={STEventInvitationDateForm.text}>
        予約時間
        <span className={STEventInvitationDateForm.attention}>必須</span>
      </p>
      <p className={STEventInvitationDateForm.sub_text}>
        {props.event.from}から{props.event.to}までの間で{props.event.spanMinute}
        単位での予約が可能です。（休憩 {props.event.restFrom}-
        {props.event.restTo}）
      </p>
      {props.date !== "" ? (
        <>
          <TimeRangeList
            timeRanges={timeRanges}
            selected={props.timeRange}
            setDateTimeSelected={props.setDateTimeSelected}
            setTimeRange={props.setTimeRange}
          />
        </>
      ) : (
        <>予約日を選択してください</>
      )}
    </>
  );
};

export default EventInvitationDateForm;

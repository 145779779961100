import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import STBottomNav from "../../assets/styles/organisms/footer.module.scss";


type Props = {
    icon: React.ReactNode,
    text: string,
    url: string,
    activeIcon: React.ReactNode,
}

const BottomNavigationItem: React.FC<Props> = (props: Props) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  
  return (
    <NavLink to={props.url} exact className={isActive =>
        STBottomNav.nav_item + ' ' + (isActive && !searchParams.has('list') ? STBottomNav.nav_item_active : STBottomNav.nav_item_inactive)}>
        {props.icon}
        {props.activeIcon}
        <h5>{props.text}</h5>
    </NavLink>
  )
}

export default BottomNavigationItem;

import STGoodButton from "../assets/styles/atoms/goodButton.module.scss";
import SvgGoodBtnOff from "./atoms/icons/GoodBtnOff";
import SvgGoodBtnOn from "./atoms/icons/GoodBtnOn";

type Props = {
  isClicked: boolean;
  count: number;
  hundleClick: (isClicked: boolean) => void;
};

const GoodButton: React.FC<Props> = (props: Props) => {
  return (
    <span className={STGoodButton.btn}>
      <span
        className={
          props.isClicked ? STGoodButton.titleClicked : STGoodButton.title
        }
        onClick={() => props.hundleClick(!props.isClicked)}
      >
        {props.isClicked ? (
          <SvgGoodBtnOn enableBackground="new 0 0 55 20;" />
        ) : (
          <SvgGoodBtnOff enableBackground="new 0 0 55 20;" />
        )}
      </span>
      <span className={STGoodButton.counter}>{props.count}</span>
    </span>
  );
};

export default GoodButton;

import STCustomAccordion from "../../assets/styles/molecules/customAccordion.module.scss";
import tokenNotFound from "../../utils/api/config/tokenNotFound";
import axios from "axios";
import { getAuth } from "firebase/auth";
import { Accordion, Typography } from "@material-ui/core";
import { FileDetails } from "./FileDetails";
import { FileDownloadResponse } from "../../types/FileDownloadResponse";
import { FolderAndFileProps } from "../../types/FolderAndFileProps";

export const FileList = ({ folderName, files }: FolderAndFileProps) => {
  const getDownload = async (fileId: number) => {
    const auth = getAuth();
    const token = await auth.currentUser?.getIdToken();
    if (!token) return tokenNotFound;
    try {
      const url = `${process.env.REACT_APP_API_ENDPOINT}/uploads/download`;
      const { data } = await axios.post<FileDownloadResponse>(
        url,
        {
          uploads_file_id: fileId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const link = document.createElement("a");
      link.href = data.data.url;
      link.click();
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <>
      <Accordion 
        className={STCustomAccordion.custom_accordion}
        defaultExpanded={!folderName}
        >
        {files.length === 0 ? (
          <Typography>　共有されているファイルはありません。</Typography>
        ) : (
          files
            .sort((a, b) => (a.modified > b.modified ? -1 : 1))
            .map((file) => (
              <FileDetails
                key={file.id}
                file={file}
                getDownload={getDownload}
              />
            ))
        )}
      </Accordion>
    </>
  );
};

import { useContext, useEffect, useState } from "react";
import STCategoryCardList from "../../assets/styles/organisms/categoryCardList.module.scss";
import STSwiper from "../../assets/styles/molecules/swiper.module.scss";
import TApiResult from "../../types/api/TApiResult";
import {
  fetchCategories,
  TApiCategoriesResult,
} from "../../utils/api/fetchCategories";
import { routes } from "../../router/Router";
import CategoryCard from "../molecules/CategoryCard";
import { categorySummary } from "../../types/categorySummary";
import PAGE_CONFIG from "../../config/page";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import { SiteContext } from "../../providers/SiteProvider";
import CategoryCardNew from "../molecules/CategoryCardNew";
import InformationList from "./InformationList";

const CategoryCardList: React.FC = () => {
  const [categories, setCategories] = useState<categorySummary[]>([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 799);
  const [activeId, setActiveId] = useState<number>(-1);
  const [categoryName, setCategoryName] = useState<string>('');
  const site = useContext(SiteContext);
  const newUiVersion = site.newUIVersion;
  const mainColor = site.mainColor;

  const handleChangeCategory = (activeId: number) => {
    setActiveId(activeId);
    const currentCategory = categories.filter(cate => cate.id === activeId);
    currentCategory.length > 0 && setCategoryName(currentCategory[0].name);
  }

  const updateMedia = () => {
    setIsMobile(window.innerWidth < 799);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => {
      window.removeEventListener("resize", updateMedia);
    };
  }, []);

  useEffect(() => {
    fetchCategories(true).then((res: TApiResult & TApiCategoriesResult) => {
      if (!res.isSuccess || !res.result) {
        return;
      }

      const newCategories = res.result.map((res) => {
        return {
          id: res.id,
          name: res.name,
          linkUrl: res.slug
            ? `${routes.informations.path}?category_slug=${res.slug}`
            : `${routes.informations.path}?category=${res.id}`,
          imageUrl: res.image_url,
        };
      });
      newUiVersion && newCategories.unshift({
          id: -1,
          name: 'すべて',
          linkUrl: '',
          imageUrl: '',
      })
      setCategories(newCategories);
    });
  }, []);

  const renderCategories = () => {
    if (isMobile && !newUiVersion) {
      return <SwiperComponent />;
    }

    if (!newUiVersion) {
      return categories.map((category) => (
        <CategoryCard key={category.id} {...category} />
      ));
    }

    return (
      <Swiper
        spaceBetween={2}
        slidesPerView={
          isMobile ? 2.5 : categories.length > 5 ? 5.5 : categories.length
        }
      >
        {categories.map((category) => (
          <SwiperSlide key={category.id}>
            <CategoryCardNew
              title={category.name}
              isActive={category.id === activeId}
              handleChangeCategory={handleChangeCategory}
              id={category.id}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    );
  };

  const SwiperComponent: React.FC = () => {
    return (
      <div className={STSwiper.swiper_container}>
        <Swiper
          className={STSwiper.category_swiper}
          spaceBetween={newUiVersion ? 8 : 16}
          slidesPerView={2.8}
          initialSlide={0}
        >
          {categories.map((category) => {
            return (
              <SwiperSlide className={newUiVersion ? STSwiper.slide_new : ''} key={`slide-${category.id}`}>
                <CategoryCard {...category} />
              </SwiperSlide>
            );
          })}
          <div className={STSwiper.banner_pagination}></div>
        </Swiper>
      </div>
    );
  };
  return (
    <>
      {categories.length > 0 && (
        <>
          <section className={STCategoryCardList.category_card_list}>
            {newUiVersion ?
              <div className={STCategoryCardList.category_card_list_title_new}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <circle cx="12" cy="12" r="9" stroke={mainColor} stroke-width="6"/>
                </svg>
                <h3>お役立ち記事</h3>
              </div>
              :
              <div
                className={`${STCategoryCardList.category_card_list_title} ${PAGE_CONFIG.STATIC_CLASSES.SUBJECT}`}
              >
                カテゴリ
              </div>
            }
            <div
              className={`${STCategoryCardList.category_card_list_wrap} ${
                newUiVersion && STCategoryCardList.category_card_list_wrap_new
              }`}
            >
              {renderCategories()}
            </div>
          </section>
          {newUiVersion && (
            <InformationList categoryName={categoryName} activeId={activeId} />
          )}
        </>
      )}
    </>
  );
};

export default CategoryCardList;

import BaseHead from "../templates/BaseHead";
import STUtils from "../../assets/styles/utility.module.scss";
import { getSubdomain } from "../../utils/getSubdomain";
import StaticInfoPreviewCard from "../organisms/StaticInfoPreviewCard";
import { useParams } from "react-router-dom";
import { routes } from "../../router/Router";

const ContentFixedPreview: React.FC = () => {
  const subdomain = getSubdomain(window.location.hostname);
  const urlParams = useParams<{ key: string }>();
  return (
    <BaseHead
      title={routes.contentFixedPreview.title}
      login={false}
      subdomain={subdomain}
    >
      <section className={STUtils.container_large}>
        <StaticInfoPreviewCard previewKey={urlParams.key} />
      </section>
    </BaseHead>
  );
};

export default ContentFixedPreview;

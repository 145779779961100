import { Box } from "@material-ui/core"
import PopupBanner from "../../components/organisms/PopupBanner"
import PopupScratch from "../../components/organisms/PopupScratch"
import {
  ScratchSetting, PlayedScratch
} from "../../types/popup";
import { TPopupSettingResponse } from "../../api/popup_setting";
import { TBannerSettingResponse } from "../../api/banner_setting";
import { useCallback } from 'react'
type props = {
  popupSetting: TPopupSettingResponse
  scratchSetting?: ScratchSetting,
  bannerSetting?: TBannerSettingResponse,
  playedScratch?: PlayedScratch,
  isOpenClickScratchBanner: boolean
  handleClosePopup: () => void
  saveInvisibleDate: (popup?: TPopupSettingResponse) => void,
  saveInvisibleUpdatedAt: (popupUpdatedAt?: string) => void,
  handleCloseBanner: () => void
}
const Popup = ({
  popupSetting,
  scratchSetting,
  bannerSetting,
  playedScratch,
  isOpenClickScratchBanner,
  handleClosePopup,
  saveInvisibleDate,
  saveInvisibleUpdatedAt,
  handleCloseBanner
}: props) => {

  /**
   * ローカルストレージに更新日付を保存する
   * 
   */
  const updateInvisibleUpdatedAt = useCallback(() => {
    saveInvisibleUpdatedAt(popupSetting.updated_at)
  }, [popupSetting.updated_at, saveInvisibleUpdatedAt])

  const updateSaveInvisibleDate = useCallback(() => {
    saveInvisibleDate(popupSetting)
  }, [popupSetting])

  const handleClosePopupOrBanner = useCallback(() => {
    handleClosePopup();
    handleCloseBanner();
  }, [handleClosePopup, handleCloseBanner])

  const Content = useCallback(() => {
    //1:バナー 2:スクラッチ
    if (popupSetting.display_type === 2 || isOpenClickScratchBanner) {
      return <PopupScratch
        popupSetting={popupSetting}
        scratchRes={scratchSetting! as ScratchSetting}
        playedScratch={playedScratch}
        handleClosePopupOrBanner={handleClosePopupOrBanner}
        saveInvisibleDate={updateSaveInvisibleDate}
        updateInvisibleUpdatedAt={updateInvisibleUpdatedAt}
      />
    } else {
      return <PopupBanner
        banner={bannerSetting! as TBannerSettingResponse}
        handleClosePopup={handleClosePopup}
        saveInvisibleDate={updateSaveInvisibleDate}
        updateInvisibleUpdatedAt={updateInvisibleUpdatedAt}
      />
    }
  }, [
    isOpenClickScratchBanner,
    playedScratch,
    scratchSetting,
    bannerSetting,
    popupSetting,
    handleClosePopup,
    updateSaveInvisibleDate,
    updateInvisibleUpdatedAt,
    handleClosePopupOrBanner
  ])
  return (
    <Box bgcolor={'#fff'} position={'relative'} p={0} style={{
      boxShadow: '0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)',
      borderRadius: '6px'
    }}>
      <Content />
    </Box >
  )
}
export default Popup

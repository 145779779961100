import { BenefitItem } from "../../types/benefitItem";
import STMemberBenefitInfo from "../../assets/styles/organisms/memberBenefitInfo.module.scss";
import ImageLink from "../molecules/ImageLink";
import Image from "../atoms/Image";
import { Link } from "react-router-dom";
import { useEffect, useState, useCallback, useMemo } from "react";
import {
  fetchBannerSettings,
  TApiBannerSettingsResult,
} from "../../utils/api/fetchBannerSettings";
import TApiResult from "../../types/api/TApiResult";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import "swiper/swiper.scss"; // core Swiper
import "swiper/modules/pagination/pagination.scss";
import STSwiper from "../../assets/styles/molecules/swiper.module.scss";
import STRecommendContentsList from "../../assets/styles/organisms/recommendContentsList.module.scss";
import { Autoplay, Navigation, Pagination } from "swiper";
import PAGE_CONFIG from "../../config/page";
import { ScratchSetting } from "../../types/popup";

type Props = {
  onClickScratchBanner: () => void;
  scratchSetting: ScratchSetting;
  setScratchSetting: (scratchSetting: ScratchSetting) => void;
};

const MemberBenefitInfo = ({
  onClickScratchBanner,
  scratchSetting,
  setScratchSetting,
}: Props) => {
  //true→PC画面  false→SP画面
  const [isChange, setIsChange] = useState(true);
  const [link, setLink] = useState<BenefitItem>();
  const [benefitItems, setBenefitItems] = useState<BenefitItem[]>([]);
  const LINK_UNDER_BANNER_LOCATION_ID = 13;
  const SCRATCH_LOCATION_ID = 19;
  const onClickScratchSlideBanner = () => {
    onClickScratchBanner();
  };
  const banners: ReadonlyArray<number> = useMemo(() => {
    return [
      10, //バナー1
      11, //バナー2
      12, //バナー3
      16, //バナー4
      17, //バナー5
      18, //バナー6
      19, //スクラッチ
    ];
  }, []);
  const LINK_UNDER_BANNER_TEXT = "会員特典をもっと見る＞";
  const linkPattern = /^http.+/;
  useEffect(() => {
    if (scratchSetting !== undefined) {
      setScratchSetting(scratchSetting);
    }
    fetchBannerSettings().then((res: TApiResult & TApiBannerSettingsResult) => {
      if (!res.isSuccess || !res.result) return;
      let newBenefitItems = res.result.map((res) => {
        return {
          id: res.id,
          siteId: res.site_id,
          locationId: res.location_id,
          imgUrl: res.img_url,
          imgType: res.img_type,
          linkUrl: res.link_url,
          linkName: res.link_name,
          description: res.description,
        };
      });
      const linkItem = newBenefitItems.find((benefitItem) => {
        return benefitItem.locationId === LINK_UNDER_BANNER_LOCATION_ID;
      });
      setLink(linkItem);
      newBenefitItems = newBenefitItems.filter((newBenefitItem) => {
        return (
          newBenefitItem.imgUrl !== null &&
          banners.includes(newBenefitItem.locationId) &&
          (scratchSetting.isPublic ||
            newBenefitItem.locationId !== SCRATCH_LOCATION_ID)
        );
      });
      //PC画面時は昇順ソート
      if (window.matchMedia("(min-width:800px)").matches) {
        newBenefitItems = newBenefitItems.sort(function (a, b) {
          return a.locationId < b.locationId ? -1 : 1;
        });
      }
      if (window.matchMedia("(max-width:799px)").matches) {
        //昇順ソート
        newBenefitItems = newBenefitItems.sort(function (a, b) {
          return a.locationId < b.locationId ? -1 : 1;
        });
        //左から2番目の位置にバナー1が来るようにソート
        if (newBenefitItems.length >= 4) {
          const minLocationId = Math.min(
            ...newBenefitItems.map((benefitItem) => benefitItem.locationId)
          );
          newBenefitItems = newBenefitItems.sort(function (a, b) {
            if (
              a.locationId === minLocationId ||
              b.locationId === minLocationId
            ) {
              return -1;
            }
            return a.locationId < b.locationId ? -1 : 1;
          });
        }
      }
      setBenefitItems(newBenefitItems);
    });
  }, [banners, isChange, scratchSetting]);
  const linkUnderBanner = link?.linkUrl ?? "";
  const linkUnderBannerText = link?.linkName
    ? link.linkName
    : LINK_UNDER_BANNER_TEXT;

  // 画面をリサイズした時にstate調整
  const resizeEvent = useCallback(() => {
    window.addEventListener("resize", () => {
      if (window.matchMedia("(max-width:799px)").matches) {
        setIsChange(false);
      } else {
        setIsChange(true);
      }
    });
  }, []);

  useEffect(() => {
    if (window.matchMedia("(max-width:799px)").matches) {
      setIsChange(false);
    }
    resizeEvent();
    return () => window.removeEventListener("resize", resizeEvent);
  }, [resizeEvent]);

  const PcBanner = () => {
    const isAddOption = benefitItems.length >= 4 ? true : false;
    return (
      <div className={STRecommendContentsList.swiper}>
        <Swiper
          modules={[Navigation, Autoplay]}
          loop={isAddOption}
          autoplay={
            isAddOption
              ? {
                  delay: 4000,
                  disableOnInteraction: false,
                }
              : false
          }
          speed={1000}
          spaceBetween={20}
          slidesPerView={3}
          navigation={
            isAddOption
              ? {
                  nextEl: `.${STSwiper.banner_button_next}`,
                  prevEl: `.${STSwiper.banner_button_prev}`,
                }
              : false
          }
        >
          {benefitItems.map((benefitItem) => {
            return (
              <div className={STMemberBenefitInfo.benefit_banner_list_item}>
                <SwiperSlide key={`slide-${benefitItem.id}`}>
                  {benefitItem.locationId === SCRATCH_LOCATION_ID &&
                  scratchSetting.isPublic ? (
                    <div onClick={onClickScratchSlideBanner}>
                      <Image
                        alt="スクラッチバナー画像"
                        className={STMemberBenefitInfo.benefit_banner_img}
                        imageUrl={benefitItem.imgUrl}
                      />
                    </div>
                  ) : (
                    <ImageLink
                      linkUrl={benefitItem?.linkUrl ? benefitItem.linkUrl : "/"}
                      imageUrl={benefitItem.imgUrl}
                      className={STMemberBenefitInfo.benefit_banner_img}
                    />
                  )}
                </SwiperSlide>
              </div>
            );
          })}
        </Swiper>
        {(() => {
          if (isAddOption) {
            return (
              <>
                <div
                  className={`${STSwiper.banner_button_prev} ${PAGE_CONFIG.STATIC_CLASSES.BANNER_BUTTON_PREV} ${PAGE_CONFIG.STATIC_CLASSES.BANNER_BUTTON_PREV_AFTER}`}
                ></div>
                <div
                  className={`${STSwiper.banner_button_next} ${PAGE_CONFIG.STATIC_CLASSES.BANNER_BUTTON_NEXT} ${PAGE_CONFIG.STATIC_CLASSES.BANNER_BUTTON_NEXT_AFTER}`}
                ></div>
              </>
            );
          }
        })()}
      </div>
    );
  };

  const SpBanner = () => {
    const bannerLength = benefitItems.length;
    let bannerArea:
      | { maxWidth?: string; width?: string; margin?: string }
      | undefined;
    let slidesPerView = 0;
    let centeredSlides = true;
    if (bannerLength === 1) {
      slidesPerView = 1;
      bannerArea = { maxWidth: "200px", margin: "0 auto" };
    }
    if (bannerLength === 2) {
      slidesPerView = 2;
      centeredSlides = false;
      bannerArea = { maxWidth: "420px", margin: "0 auto" };
    }
    if (bannerLength === 3) {
      slidesPerView = 3;
      centeredSlides = false;
      bannerArea = { margin: "0 auto" };
    }
    if (bannerLength >= 4) {
      slidesPerView = 3.5;
      centeredSlides = true;
      bannerArea = { margin: "0 calc(50% - 50vw) 0 calc(50% - 50vw)" };
    }
    if (bannerLength >= 5) {
      centeredSlides = true;
    }
    return (
      <div style={bannerArea} className={STRecommendContentsList.swiper}>
        <Swiper
          modules={[Autoplay, Pagination]}
          loop={true}
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
          }}
          spaceBetween={5}
          slidesPerView={slidesPerView}
          centeredSlides={centeredSlides}
          initialSlide={0}
          speed={1000}
          pagination={{
            clickable: true,
            el: `.${STSwiper.banner_pagination}`,
            bulletActiveClass: `${STSwiper.banner_pagination_bullet_active} ${PAGE_CONFIG.STATIC_CLASSES.BANNER_BULLET_ACTIVE}`,
            bulletClass: `${STSwiper.banner_pagination_bullet} ${PAGE_CONFIG.STATIC_CLASSES.BANNER_BULLET}`,
            renderBullet: (index, bulletClass) => {
              return '<span class ="' + bulletClass + '"> </span>';
            },
          }}
        >
          {benefitItems.map((benefitItem) => {
            return (
              <div className={STMemberBenefitInfo.benefit_banner_list_item}>
                <SwiperSlide key={`slide-${benefitItem.id}`}>
                  {benefitItem.locationId === SCRATCH_LOCATION_ID &&
                  scratchSetting.isPublic ? (
                    <div onClick={onClickScratchSlideBanner}>
                      <Image
                        alt="スクラッチバナー画像"
                        className={STMemberBenefitInfo.benefit_banner_img}
                        imageUrl={benefitItem.imgUrl}
                      />
                    </div>
                  ) : (
                    <ImageLink
                      linkUrl={benefitItem?.linkUrl ? benefitItem.linkUrl : "/"}
                      imageUrl={benefitItem.imgUrl}
                      className={STMemberBenefitInfo.benefit_banner_img}
                    />
                  )}
                </SwiperSlide>
              </div>
            );
          })}
          <div className={STSwiper.banner_pagination}></div>
        </Swiper>
      </div>
    );
  };

  const ChangeBannerComponent = () => {
    if (isChange) {
      return <PcBanner />;
    } else {
      return <SpBanner />;
    }
  };
  return (
    <div className={STMemberBenefitInfo.benefit_info}>
      <ChangeBannerComponent />
      <div className={STMemberBenefitInfo.benefit_link_container}>
        {!linkPattern.test(linkUnderBanner) ? (
          <Link to={linkUnderBanner}>{linkUnderBannerText}&nbsp;</Link>
        ) : (
          <a href={linkUnderBanner}>{linkUnderBannerText}&nbsp;</a>
        )}
      </div>
    </div>
  );
};

export default MemberBenefitInfo;

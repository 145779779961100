// NOTE: https://qiita.com/saba_can00/items/bdefb28a1873658cf5d9
export const CONTENT_TYPE = {
  news: 1,
  information: 2,
  static: 3,
  lp: 4,
  video: 5,
} as const;

type TypeOfContentType = typeof CONTENT_TYPE;
export type TAppContentType = TypeOfContentType[keyof TypeOfContentType];

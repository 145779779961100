type EmbedType = "Instagram" | "TikTok";

const setEmbedScript = (
  type: EmbedType,
  content: string,
  isModal?: boolean
) => {
  let embedSrc = "";

  switch (type) {
    case "Instagram":
      embedSrc = "//www.instagram.com/embed.js";
      break;
    case "TikTok":
      embedSrc = "https://www.tiktok.com/embed.js";
      break;
    default:
      return;
  }

  if (content.length > 0) {
    const existingScript = document.querySelector(`script[src='${embedSrc}']`);
    if (existingScript) {
      existingScript.remove();
    }
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = embedSrc;

    const head = document.getElementsByTagName("head")[0];
    head.appendChild(script);
  }

  if (type === "Instagram" && (window as any).instgrm) {
    (window as any).instgrm.Embeds.process();
  }
};

export default setEmbedScript;

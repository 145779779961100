import STOptionMessage from '../../../assets/styles/molecules/reactHookForms/optionMessage.module.scss'
import Information from '../../atoms/icons/Information';

type Props = {
  information?: string;
};

const OptionMessage = (props: Props) => {
  const { information } = props;
  return (
    <div className={STOptionMessage.container}>
      <Information className={STOptionMessage.icon} width="14px" height="14px" />
      <span className={STOptionMessage.information}>{information}</span>
    </div>
  )
};

export default OptionMessage;

import { FieldValues, Path, UseFormRegister } from "react-hook-form";
import { SelectBoxOption } from "../../../../types/form/selectBoxOption";
import STSelectBox from "../../../../assets/styles/atoms/form/reactHookForms/selectBox.module.scss";
import STForm from "../../../../assets/styles/atoms/form/reactHookForms/form.module.scss";
import PAGE_CONFIG from "../../../../config/page";

type Props<T extends FieldValues> = {
  formName: Path<T>;
  register: UseFormRegister<T>;
  options: SelectBoxOption[];
  defaultValue?: string;
};

const SelectBox = <T extends FieldValues>(props: Props<T>) => {
  const {formName, register, options, defaultValue} = props;
  const defaultValueKey = 0;

  return (
    <span className={STSelectBox.selectbox}>
      <select
        id={formName}
        value={defaultValue}
        className={`${STSelectBox.selectbox} ${STForm.form}`}
        {...register(formName)}
        data-testid={`entry-form_${formName}`}
      >
        {!defaultValue && (
          <option key={defaultValueKey}>選択してください。</option>
        )}
        {options.map(({ id, value, title }) => (
          <option key={id} value={value}>
            {title}
          </option>
        ))}
      </select>
    </span>
  );
};

export default SelectBox;

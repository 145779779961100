import PAGE_CONFIG from "../config/page";
import getImageUrl from "./getImageUrl";

const getUrl = (subdomain: string, fileName: string) => {
  return getImageUrl(subdomain, PAGE_CONFIG.IMAGE_URL_PATH.SITE, fileName);
};

var setManifestURL = function (name: string, subdomain: string) {
  var myUrl = window.location.href;

  var manifest = {
    name: name,
    start_url: myUrl,
    icons: [
      {
        src: getUrl(subdomain, "icon-72x72.png"),
        sizes: "72x72",
        type: "image/png",
      },
      {
        src: getUrl(subdomain, "icon-96x96.png"),
        sizes: "96x96",
        type: "image/png",
      },
      {
        src: getUrl(subdomain, "icon-128x128.png"),
        sizes: "128x128",
        type: "image/png",
      },
      {
        src: getUrl(subdomain, "icon-144x144.png"),
        sizes: "144x144",
        type: "image/png",
      },
      {
        src: getUrl(subdomain, "icon-152x152.png"),
        sizes: "152x152",
        type: "image/png",
      },
      {
        src: getUrl(subdomain, "icon-192x192.png"),
        sizes: "192x192",
        type: "image/png",
      },
      {
        src: getUrl(subdomain, "icon-384x384.png"),
        sizes: "384x384",
        type: "image/png",
      },
      {
        src: getUrl(subdomain, "icon-512x512.png"),
        sizes: "512x512",
        type: "image/png",
      },
    ],
  };

  const stringManifest = JSON.stringify(manifest);
  const blob = new Blob([stringManifest], { type: "application/json" });
  const manifestURL = URL.createObjectURL(blob);
  const attr = document.querySelector("#my-manifest");
  if (attr === null) return;
  attr.setAttribute("href", manifestURL);
};

export default setManifestURL;

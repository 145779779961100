import STForm from "../../../../assets/styles/atoms/form/reactHookForms/form.module.scss";

type Props = {
  name: string;
  message: string;
};

const ErrorMessage = (props: Props) => {
  return (
    <span role="alert" data-testid={`entry-form_error_${props.name}`} className={STForm.error_message}>{props.message}</span>
  );
};

export default ErrorMessage;

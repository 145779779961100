import STContactFormConfirmContent from "../../assets/styles/molecules/contactFormConfirmContent.module.scss";

type Props = {
  title: string;
  body: string;
};

const ContactFormConfirmContent: React.FC<Props> = (props: Props) => {
  return (
    <div>
      <div className={STContactFormConfirmContent.content}>
        <p className={STContactFormConfirmContent.content_title}>
          <label>{props.title}</label>
        </p>
        <div className={STContactFormConfirmContent.content_body}>
          {props.body}
        </div>
      </div>
    </div>
  );
};

export default ContactFormConfirmContent;

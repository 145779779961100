// @see: https://github.com/mzp/japan-postal-code/blob/4623ee7418153adb7c6b9132ee0386eace567bef/index.js#L96
type Address = {
  prefecture: string;
  city: string;
  area: string;
  street: string;
};

const getAddressData = (
  zip: string,
  callback: (
    prefecture: string,
    city: string,
    area: string,
    street: string
  ) => void
) => {
  const postal_code = require("japan-postal-code");
  postal_code.get(zip, function (address: Address) {
    callback(address.prefecture, address.city, address.area, address.street);
  });
};

export default getAddressData;

const CONSTANTS = {
  PATTERN: {
    ONLY_CALACTOR: /[^!-/:-@¥[-`{-~]+/,
    ONLY_NUMBER: /^\d+$/,
    ONLY_HIRAGANA: /^[ぁ-んー]+$/,
    EMAIL:
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    // RFC違反メールアドレスのため「ドットの連続」「@直前のドット」にも対応
    EMAIL_NOT_RFC:
      /^(([^<>()[\]\\,;:\s@\"]+(\.[^<>()[\]\\,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    PASSWORD: /^.*(?=.{8,50})(?=.*[a-zA-Z])(?=.*\d).*$/,
    INSTAGRAM: /^[a-zA-Z0-9_.]+$/,
  },
  MAX_LENGTH: {
    SEARCH_FORM_TEXT: 500,
    EMAIL: 255,
    PASSWORD: 20,
  },

  USER_SESSION_KEY: "userSession",
};
export default CONSTANTS;

import AppCalendarDate from "../atoms/AppCalendarDate";
import { format, parse, addMonths, subDays, getMonth, getWeeksInMonth } from "date-fns";
import STAppCalendar from "../../assets/styles/molecules/appCalendar.module.scss";
import AppCalendarHeader from "../atoms/AppCalendarHeader";
import { TAppCalendarTarget } from "../../types/TAppCalendarTarget";
import PAGE_CONFIG from "../../config/page";
import { TAppTimeRange } from "../../types/TAppTimeRange";
type Props = {
  month: string;
  setMonth: (newMonth: string) => void;
  targets: TAppCalendarTarget[];
  clickedDate: string;
  setClickedDate: (date: string) => void;
  resetTimeRange: () => void;
  setTimeRange: (ranges: TAppTimeRange) => void;
};

const AppCalendar: React.FC<Props> = ({
  month,
  setMonth,
  targets,
  clickedDate,
  setClickedDate,
  resetTimeRange,
}) => {
  //対象月の週数を算出
  const weekNum = getWeeksInMonth(new Date(month));
  const startDayOfMonth =
    Number(format(new Date(Date.parse(`${month}-01`)), "e")) - 1;
  const endDateOfMonth = subDays(
    addMonths(Date.parse(`${month}-01`), 1),
    1
  ).getDate();
  let dateCount = 1;

  const isTarget = (dayCount: number): boolean => {
    const target = targets.find((t) => t.month === month);
    if (!target) return false;

    return target.dates.some((d) => {
      return (
        parse(d.date, "yyyy-MM-dd", new Date()).toString() ===
        parse(`${month}-${dayCount}`, "yyyy-MM-dd", new Date()).toString()
      );
    });
  };

  const isFull = (dayCount: number): boolean => {
    const target = targets.find((t) => t.month === month);
    if (!target) return false;
    return target.dates
      .filter((d) => d.isFull === true)
      .some((d) => {
        return (
          parse(d.date, "yyyy-MM-dd", new Date()).toString() ===
          parse(`${month}-${dayCount}`, "yyyy-MM-dd", new Date()).toString()
        );
      });
  };

  const isClicked = (dayCount: number): boolean => {
    return (
      parse(clickedDate, "yyyy-MM-dd", new Date()).toString() ===
      parse(`${month}-${dayCount}`, "yyyy-MM-dd", new Date()).toString()
    );
  };

  const hundleClick = (dateCount: number) => {
    const newDate = format(
      parse(`${month}-${dateCount}`, "yyyy-mm-d", new Date()),
      "yyyy-mm-dd"
    );
    setClickedDate(newDate);
    resetTimeRange();
  };

  const isPrevClickable = targets[0].month !== month;
  const hundlePrevCick = () => {
    if (!isPrevClickable) return;
    const index = targets.map((target) => target.month).indexOf(month);
    if (index === -1) return;
    setMonth(targets[index - 1].month);
    setClickedDate("");
    resetTimeRange();
  };
  const isNextClickable =
    targets.map((target) => target.month).slice(-1)[0] !== month;
  const hundleNextCick = () => {
    if (!isNextClickable) return;
    const index = targets.map((target) => target.month).indexOf(month);
    if (index === -1) return;
    setMonth(targets[index + 1].month);
    setClickedDate("");
    resetTimeRange();
  };

  return (
    <>
      <AppCalendarHeader
        month={getMonth(Date.parse(`${month}-01`)) + 1}
        isPrevClickable={isPrevClickable}
        hundlePrevCick={hundlePrevCick}
        isNextClickable={isNextClickable}
        hundleNextCick={hundleNextCick}
      />
      <div className={STAppCalendar.calendar}>
        <ol className={STAppCalendar.header_row}>
          {PAGE_CONFIG.CALENDAR.WEEKS.map((week, index) => {
            return (
              <li key={index} className={STAppCalendar.header_day}>
                {week}
              </li>
            );
          })}
        </ol>
        {Array.from(Array(weekNum).keys()).map((weekNum, weekIndex) => {
          return (
            <ol key={weekIndex} className={STAppCalendar.body_row}>
              {Array.from(Array(PAGE_CONFIG.CALENDAR.WEEKS.length).keys()).map(
                (dayNum, dayIndex) => {
                  if (
                    (weekNum === 0 && dayNum < startDayOfMonth) ||
                    dateCount > endDateOfMonth
                  ) {
                    return (
                      <AppCalendarDate
                        key={`${weekIndex}_${dayIndex}`}
                        isTarget={isTarget(dateCount)}
                        isFull={isFull(dateCount)}
                        isClicked={isClicked(dateCount)}
                        hundleClick={hundleClick}
                      />
                    );
                  } else {
                    const current = dateCount;
                    dateCount++;
                    return (
                      <AppCalendarDate
                        key={`${weekIndex}_${dayIndex}`}
                        dateNum={current}
                        isTarget={isTarget(current)}
                        isFull={isFull(current)}
                        isClicked={isClicked(current)}
                        hundleClick={hundleClick}
                      />
                    );
                  }
                }
              )}
            </ol>
          );
        })}
      </div>
    </>
  );
};

export default AppCalendar;

import { AxiosError } from "axios";
import { getAuth } from "firebase/auth";
import client from "../../api/client";
import TApiResult from "../../types/api/TApiResult";
import { TAppFormError } from "../../types/TAppFormError";
import { TAppMemberEditFormItem } from "../../types/TAppMemberEditFormItem";
import getFormatDate from "../getFormatDate";
import getErrorProperty from "./config/getErrorProperty";
import tokenNotFoundWithValidateErrors from "./config/tokenNotFoundWithValidateErrors";

export type TApiMemberUpdateValidateErrors = {
  validateErrors: TAppFormError<TAppMemberEditFormItem>[];
};

export const postMemberUpdate = async (params: {
  email: string;
  tel: string;
  existPassword: string;
  newPassword: string;
  instagramOwnerinput: string;
  birthday?: Date;
  line: number;
  mail_not_recommended: number;
  line_not_recommended: number;
}): Promise<TApiResult & TApiMemberUpdateValidateErrors> => {
  const auth = getAuth();
  const token = await auth.currentUser?.getIdToken();
  if (!token) return tokenNotFoundWithValidateErrors;

  return await client()
    .member.update.post({
      body: {
        email: params.email,
        tel: params.tel,
        exist_password: params.existPassword,
        new_password: params.newPassword,
        instagram_ownerinput: params.instagramOwnerinput,
        birthday: getFormatDate(params.birthday),
        line_unlink: params.line,
        mail_not_recommended: params.mail_not_recommended,
        line_not_recommended: params.line_not_recommended,
      },
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      return {
        isSuccess: true,
        status: 200,
        message: "",
        validateErrors: [],
      };
    })
    .catch((e: AxiosError) => {
      const { status, message, errorMessages } = getErrorProperty(
        e,
        "postMemberUpdate"
      );
      if (!errorMessages) {
        return {
          isSuccess: false,
          status: status,
          message: message,
          validateErrors: [],
        };
      }

      const validateErrors: TAppFormError<TAppMemberEditFormItem>[] =
        errorMessages.details.map((d) => {
          return {
            key: d.key as TAppMemberEditFormItem,
            messages: d.values,
          };
        });

      return {
        isSuccess: false,
        status: status,
        message: "",
        validateErrors: validateErrors,
      };
    });
};

import STAlartMessageBox from "../../assets/styles/atoms/alartMessageBox.module.scss";
type Props = {
  message: string;
};

const AlartMessageBox: React.FC<Props> = ({ message }: Props) => {
  return <div className={STAlartMessageBox.message}>{message}</div>;
};

export default AlartMessageBox;

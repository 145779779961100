import { useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import { SiteContext } from "../providers/SiteProvider";

const usePageTracking = () => {
  const location = useLocation();
  const site = useContext(SiteContext);

  useEffect(() => {
    // Google Analytics 測定 ID を入力して設定
    if (site.googleAnalyticsId) {
      ReactGA.initialize(site.googleAnalyticsId);
      ReactGA.send({
        hitType: "pageview",
        // アクセスしたパス (pathname) とクエリ文字列 (search) を送付する (必要に応じて編集する)
        page: location.pathname + location.search,
      });
    }
  }, [location]);
};

export default usePageTracking;
import client from "../../api/client";
import { TSiteResponce } from "../../api/site";
import TApiResult from "../../types/api/TApiResult";
import { AxiosError } from "axios";
import getErrorProperty from "./config/getErrorProperty";

export type TApiSiteResult = {
  result?: TSiteResponce;
};

export const fetchSitePreview = async (key: string): Promise<TApiResult & TApiSiteResult> => {
  return client()
    .site.preview._key(key).get()
    .then((res) => {
      return {
        isSuccess: true,
        status: 200,
        message: "",
        result: res.body,
      };
    })
    .catch((e: AxiosError) => {
      const { status, message } = getErrorProperty(e, "fetchSite");
      return {
        isSuccess: false,
        status: status,
        message: message,
      };
    });
};
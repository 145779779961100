const PAGE_CONFIG = {
  HOST: process.env.HOST,
  CDN_ENDPOINT: process.env.REACT_APP_CDN_ENDPOINT,
  IMAGE_URL_PATH: {
    SITE: "/images/sites",
    RANK: "/images/ranks",
    CMS: "/images/cms_images",
    BANNER: "/images/top_banner",
  },
  BACKGROUND_LOGIN: "login_bg_01.jpg",
  BACKGROUND_LOGIN_SP: "login_bg_01_sp.jpg",
  BACKGROUND_TOP: "top_bg_01.png",
  BACKGROUND_TOP_SP: "top_bg_01_sp.png",
  BACKGROUND_MYPAGE_1: "sub_bg_01.png",
  BACKGROUND_MYPAGE_2: "sub_bg_02.png",
  BACKGROUND_MYPAGE_1_SP: "sub_bg_01_sp.png",
  BACKGROUND_MYPAGE_2_SP: "sub_bg_02_sp.png",

  LOGO: "logo.png",
  FAVICON: "favicon.ico",
  APPLE_TOUCH_ICON: "apple-touch-icon.png",
  NAV_ICON_1: "nav_icon_01.png",
  NAV_ICON_2: "nav_icon_02.png",
  NAV_ICON_3: "nav_icon_03.png",
  NAV_ICON_4: "nav_icon_04.png",
  NAV_ICON_5: "nav_icon_05.png",
  ARROW_ICON: "arrow_icon.png",
  MEMBER_PREMIUM_INFO: "memberPremium.jpeg",
  BTN_MOBILE_MENU: "btn_mobile_menu.png",
  BTN_MOBILE_MENU_CLOSE: "btn_mobile_menu_close.png",

  TOP_BANNER_1: "banner01.png",
  TOP_BANNER_2: "banner02.png",
  TOP_BANNER_3: "banner03.png",

  SAMPLE_IMG: "sample.png",
  STATIC_STYLE_FILE_PATH: "styles/css/site.css",

  STATIC_CLASSES: {
    FOOTER: "footer",
    BUTTON_PRIMARY: "btn_primary",
    BUTTON_SECONDARY: "btn_secondary",
    SELECTBOX: "selectbox",
    CHECKBOX: "checkbox",
    SUBJECT: "subject",
    FORM_TEXT: "form_text",
    ARROW: "arrow",
    MORE_BUTTON_TEXT: "more_btn_text",
    POINT_REQUEST_METHOD: "point_request_method",
    CHANGE_PASSWORD_BUTTON: "change_password_btn",
    NEXT_PREV_LINK: "next_prev_link",
    NAVI_ICON: "navi_icon",
    SVG_ICON: "svg_icon",
    FIRST_STEP_CONTENT: "first_step_content",
    SECOND_STEP_CONTENT: "second_step_content",
    THIRD_STEP_CONTENT: "third_step_content",
    PAGENATION_BTN: "pagenation_btn",
    PAGENATION_BTN_CURRENT: "pagenation_btn_current",
    EVENT_CALENDAR_HEADER: "event_canlendar_header",
    EVENT_CALENDAR_TARGRT_DATE: "event_canlendar_target_date",
    EVENT_CALENDAR_TARGRT_DATE_CLICKED: "event_canlendar_target_date_clicked",
    EVENT_CALENDAR_TIME_RANGE_CLICKED: "event_canlendar_time_range_clicked",
    BANNER_BUTTON_PREV: "banner_button_prev",
    BANNER_BUTTON_NEXT_AFTER: "banner_button_next::after",
    BANNER_BUTTON_PREV_AFTER: "banner_button_prev::after",
    BANNER_BUTTON_NEXT: "banner_button_next",
    BANNER_BULLET: "banner_bullet",
    BANNER_BULLET_ACTIVE: "banner_bullet_active",
  },

  CALENDAR: {
    WEEKS: ["日", "月", "火", "水", "木", "金", "土"],
  },

  DATE_FORMAT: "yyyy/MM/dd",
  PASSWORD_REGEX: /^([a-zA-Z0-9]{8,})$/,
};

export default PAGE_CONFIG;

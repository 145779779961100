import BaseHead from "../templates/BaseHead";
import STUtils from "../../assets/styles/utility.module.scss";
import InfoArticleList from "../organisms/InfoArticleList";
import { getSubdomain } from "../../utils/getSubdomain";
import { routes } from "../../router/Router";
import { useLocation } from "react-router-dom";
import { CONTENT_TYPE, TAppContentType } from "../../types/TAppContentType";
import { useContext } from "react";
import { SiteContext } from "../../providers/SiteProvider";
import BottomNavigationBar from "../organisms/BottomNavigationBar";

const NewsList: React.FC = () => {
    const query = new URLSearchParams(useLocation().search);
    const page = query.get("page") ? Number(query.get("page")) : 1;
    const category = query.get("category")
        ? Number(query.get("category"))
        : undefined;
    const title = query.get("title") ?? undefined;
    const categorySlug = query.get("category_slug") ?? undefined;
    const contentTypeIds: TAppContentType[] = [
        CONTENT_TYPE.news
    ]
    const newUiVersion = useContext(SiteContext).newUIVersion;

    const subdomain = getSubdomain(window.location.hostname);
    return (
        <BaseHead
        title={routes.informations.title}
        login={true}
        subdomain={subdomain}
        hasBackground={true}
        hasNavigationBar={true}
        >
        <section className={STUtils.container_large}>
            <InfoArticleList
                requestPage={page}
                requestCategory={category}
                requestTitle={title}
                requestCategorySlug={categorySlug}
                contentTypeIds={contentTypeIds}
            />
            {newUiVersion &&
                <div className={STUtils.bottom_bar}>
                    <BottomNavigationBar />
                </div>
            }
        </section>
        </BaseHead>
    );
}

export default NewsList;

import { Link } from "react-router-dom";
import Image from "../atoms/Image";
import STCategoryCard from "../../assets/styles/molecules/categoryCard.module.scss";

type Props = {
  name: string;
  linkUrl: string;
  imageUrl?: string;
};

const CategoryCard: React.FC<Props> = ({ name, linkUrl, imageUrl }) => {
  const cardClass = imageUrl
    ? STCategoryCard.category_card
    : STCategoryCard.noimg_category_card;
  const nameClass = imageUrl
    ? STCategoryCard.category_card_name
    : STCategoryCard.noimg_category_card_name;

  return (
    <div className={cardClass}>
      <Link className={STCategoryCard.category_card_link} to={linkUrl}>
        {imageUrl && (
          <Image
            className={STCategoryCard.category_card_thumbnail_img}
            imageUrl={imageUrl}
            alt={name}
          />
        )}
        <p className={nameClass}>{name}</p>
      </Link>
    </div>
  );
};

export default CategoryCard;

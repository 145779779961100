import { AxiosError } from "axios";
import client from "../../api/client";
import TApiResult from "../../types/api/TApiResult";
import getErrorProperty from "./config/getErrorProperty";
import { TAppFormError } from "../../types/TAppFormError";
import { TAppPointAutograntRequestFormItem } from "../../types/TAppPointAutogrant";
import { getAuth } from "firebase/auth";

export type TApiPointAutograntResult = {
  result?: {
    res: {};
  };
};

export type TApiPointAutograntValidateErrors = {
  validateErrors: TAppFormError<TAppPointAutograntRequestFormItem>[];
};

export const postPointAutogrant = async (param: {
  point_autogrant_event: number;
  session_uuid: string;
  url: string;
  uid: string;
  point_id: number | null;
}): Promise<TApiResult & TApiPointAutograntValidateErrors> => {
  const auth = getAuth();
  const token = await auth.currentUser?.getIdToken();
  return client()
    .point.autogrant.post({
      body: {
        point_autogrant_event: param.point_autogrant_event,
        session_uuid: param.session_uuid,
        url: param.url,
        uid: param.uid,
        point_id: param.point_id ? param.point_id : null,
      },
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      return {
        isSuccess: true,
        status: 200,
        message: "",
        validateErrors: [],
      };
    })
    .catch((e: AxiosError) => {
      const { status, message, errorMessages } = getErrorProperty(
        e,
        "postPointAutogrant"
      );
      if (!errorMessages) {
        return {
          isSuccess: false,
          status: status,
          message: message,
          validateErrors: [],
        };
      }

      const validateErrors: TAppFormError<TAppPointAutograntRequestFormItem>[] =
        errorMessages.details.map((d) => {
          return {
            key: d.key as TAppPointAutograntRequestFormItem,
            messages: d.values,
          };
        });

      return {
        isSuccess: false,
        status: status,
        message: "",
        validateErrors: validateErrors,
      };
    });
};

import styled from 'styled-components';
type Props = {
  catchCopy: string | undefined
  ribbonColor: string
  fontColor: string
}
const updateFontSizeByLength = (catchCopy: string | undefined): number => {
  if (catchCopy === undefined) {
    return 0;
  }
  switch (catchCopy.length) {
    case 15:
      return 10;
    case 14:
      return 11;
    case 13:
      return 12;
    case 12:
      return 13;
    case 11:
      return 14;
    default:
      return catchCopy.length <= 10 ? 16 : catchCopy.length;
  }
}
const Ribbon = ({ catchCopy, ribbonColor, fontColor }: Props) => {
  const RibbonContainer = styled.div`
        position: relative;
  `
  const RibbonBody = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -88%);
  `;
  const RibbonPath = styled.path`
        fill: ${ribbonColor || '#E53E3E'};
        stroke-width: 0px;
  `;
  const RibbonPolygonWhite = styled.polygon`
        fill: #fff;
        stroke-width: 0px;
  `;
  const RibbonPolygonRed = styled.polygon`
        fill: ${ribbonColor || '#E53E3E'};
        stroke-width: 0px;
  `;
  const RibbonLine = styled.line`
        fill: none;
        stroke: #fff;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-width: 1.47px;
  `;
  const Text = styled.span`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 180px;
    font-weight: 700;
    font-size: ${updateFontSizeByLength(catchCopy)}px;
    padding: 3px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 55px;
    color: ${fontColor};
  `
  return (
    <RibbonContainer>
      <RibbonBody>
        <Text>{catchCopy}</Text>
        <svg id="_レイヤー_2" data-name="レイヤー 2" xmlns="http://www.w3.org/2000/svg" width="250" height="74" viewBox="0 0 250 74">
          <g id="_レイヤー_1-2" data-name="レイヤー 1">
            <RibbonPath d="m203.3,73.93c.04.06,10.56.09,23.38.06l23.32-.09-7.64-13.7c-4.2-7.55-7.6-13.86-7.57-14.07.06-.18,3.46-6.41,7.59-13.8,4.12-7.36,7.53-13.55,7.55-13.7.04-.22-7.1-.31-19.78-.31h-19.86s-7.02,55.56-6.98,55.62Z" />
            <RibbonPath d="m46.78,73.93c-.04.06-10.58.09-23.42.06l-23.36-.09,7.66-13.7c4.22-7.55,7.66-13.86,7.64-14.07-.02-.18-3.46-6.5-7.64-14.01L.04,18.46l19.98-.09,19.98-.06s6.82,55.56,6.78,55.62Z" />
            <RibbonPolygonRed points="26.34 0 223.7 0 218.7 57.98 31.34 57.98 26.34 0" />
            <RibbonPolygonWhite points="44.82 72.23 31.41 57.98 42.85 57.98 44.82 72.23" />
            <RibbonLine x1="221.55" y1="33.16" x2="219.61" y2="55.25" />
            <RibbonLine x1="222.66" y1="20.53" x2="222.04" y2="27.66" />
            <RibbonLine x1="29.63" y1="46.57" x2="30.39" y2="55.25" />
            <RibbonLine x1="27.33" y1="20.53" x2="29.1" y2="40.64" />
            <RibbonPolygonWhite points="205.16 72.23 218.57 57.98 207.13 57.98 205.16 72.23" />
          </g>
        </svg>
      </RibbonBody>
    </RibbonContainer>
  )
}

export default Ribbon
import { Helmet } from "react-helmet";
import { useContext } from "react";
import Header from "../organisms/Header";
import Footer from "../organisms/Footer";
import STUtils from "../../assets/styles/utility.module.scss";

import MypageBackground from "../organisms/MypageBackground";
import PAGE_CONFIG from "../../config/page";
import { SiteContext } from "../../providers/SiteProvider";

type Props = {
  title: string;
  login: boolean;
  hasBackground?: boolean;
  subdomain: string;
  showFooter?: boolean;
  customMainStyle?: React.CSSProperties;
};

const BaseHeadNoindex: React.FC<Props> = ({
  title,
  login,
  children,
  hasBackground = false,
  subdomain,
  showFooter = true,
  customMainStyle,
}) => {
  const styleUrl = `${PAGE_CONFIG.CDN_ENDPOINT}/${subdomain}/${PAGE_CONFIG.STATIC_STYLE_FILE_PATH}`;
  const site = useContext(SiteContext);
  return (
    <>
      <Helmet>
        <title>{`${title} | ${site.name}`}</title>
        <link rel="stylesheet" href={styleUrl}></link>
        <meta property="og:site_name" content={site.name} />
        <meta property="og:title" content={`${title} | ${site.name}`} />
        <meta name="robots" content="noindex" />
      </Helmet>

      <MypageBackground hasBackground={hasBackground}>
        <Header login={login} subdomain={subdomain} />
        <main>
          <div role="main" className={STUtils.main} style={customMainStyle}>
            {children}
          </div>
        </main>
        {showFooter ? <Footer hasBackground={hasBackground} /> : <></>}
      </MypageBackground>
    </>
  );
};

export default BaseHeadNoindex;

import BaseHead from "../templates/BaseHead";
import STUtils from "../../assets/styles/utility.module.scss";
import LoginForm from "../organisms/LoginForm";
import { getSubdomain } from "../../utils/getSubdomain";
import LoginBackground from "../organisms/LoginBackground";
import { routes } from "../../router/Router";
import { useHistory, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { getAuth } from "firebase/auth";
import { postMemberLinkUserLineAccount } from "../../utils/api/postMemberLinkUserLineAccount";

const Login: React.FC = () => {
  const subdomain = getSubdomain(window.location.hostname);
  const query = new URLSearchParams(useLocation().search);

  const history = useHistory();
  const linkToken = query.get("linkToken")
    ? String(query.get("linkToken"))
    : undefined;
  const auth = getAuth();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (auth.currentUser) {
      if (linkToken !== undefined) {
        postMemberLinkUserLineAccount({
          linkToken,
        })
          .then((res) => {
            window.location.href = res.result!.values;
          })
          .catch(() => {
            history.push(routes.error.path);
          });
      } else {
        history.push(routes.top.path);
      }
    } else {
      setLoading(false);
    }
  }, []);

  if (loading) return <></>;

  return (
    <BaseHead
      title={routes.login.title}
      login={false}
      subdomain={subdomain}
      hasBackground={true}
    >
      <LoginBackground />
      <div className={`${STUtils.input_form} ${STUtils.container_large}`}>
        <LoginForm linkToken={linkToken} />
      </div>
    </BaseHead>
  );
};

export default Login;

import STUtils from "../../assets/styles/utility.module.scss";
import { ContactForm as TContactForm } from "../../types/form/contactForm";
import Label from "../atoms/form/Label";
import STContactForm from "../../assets/styles/organisms/contactForm.module.scss";
import Button from "../atoms/Button";
import STButton from "../../assets/styles/atoms/button.module.scss";
import PAGE_CONFIG from "../../config/page";
import { getAuth } from "firebase/auth";

import TextFormRow from "../molecules/formRow/TextFormRow";
import { TAppFormError } from "../../types/TAppFormError";
import {
  CONTACT_FORM_ITEM,
  TAppContactFormItem,
} from "../../types/TAppContactFormItem";
import TextAreaFormRow from "../molecules/formRow/TextAreaFormRow";
import { useEffect, useState } from "react";
import CONSTANTS from "../../utils/constants";

type Props = {
  form: TContactForm;
  setForm: (form: TContactForm) => void;
  setConfirm: (value: boolean) => void;
  formErrors: TAppFormError<TAppContactFormItem>[];
  setFormErrors: (formErrors: TAppFormError<TAppContactFormItem>[]) => void;
};

const ContactForm: React.FC<Props> = ({
  form,
  setForm,
  setConfirm,
  formErrors,
  setFormErrors,
}) => {
  const [canApply, setCanApply] = useState(false);
  const auth = getAuth();

  const hundleChange = (newForm: TContactForm): void => {
    setForm(newForm);
  };

  useEffect(() => {
    const newCanApply =
      (formErrors.filter((e) => e.messages.length !== 0).length === 0) && (form.body !== '');
    setCanApply(newCanApply);
  }, [formErrors]);

  const hundleSubmit = () => {
    if (!canApply) return;
    setConfirm(true);
  };

  return (
    <div className={STUtils.container_form}>
      <h1 className={STContactForm.contact_form_title}>お問い合わせ</h1>
      <div>
        {!auth.currentUser && (
          <div>
            <div>
              <label>
                <Label id="" className="" for="" value="お名前" />
                <span className={STContactForm.contact_form_content_attention}>
                  ※必須入力項目
                </span>
              </label>
              <TextFormRow<TAppContactFormItem>
                title="[姓]"
                defaultValue={form.sei}
                className={STContactForm.contact_form_input}
                name={CONTACT_FORM_ITEM.sei}
                formKey={CONTACT_FORM_ITEM.sei}
                hundleOnChange={(input) =>
                  hundleChange({ ...form, sei: input })
                }
                errors={formErrors}
                setErrors={setFormErrors}
                maxLength={255}
                pattern={CONSTANTS.PATTERN.ONLY_CALACTOR}
                patternText="文字または英数字"
                required={true}
              />
              <TextFormRow<TAppContactFormItem>
                title="[名]"
                defaultValue={form.mei}
                className={STContactForm.contact_form_input}
                name={CONTACT_FORM_ITEM.mei}
                formKey={CONTACT_FORM_ITEM.mei}
                hundleOnChange={(input) =>
                  hundleChange({ ...form, mei: input })
                }
                errors={formErrors}
                setErrors={setFormErrors}
                maxLength={255}
                pattern={CONSTANTS.PATTERN.ONLY_CALACTOR}
                patternText="文字または英数字"
                required={true}
              />
            </div>
            <div>
              <label>
                <Label id="" className="" for="" value="ふりがな" />
                <span className={STContactForm.contact_form_content_attention}>
                  ※必須入力項目
                </span>
              </label>
              <TextFormRow<TAppContactFormItem>
                title="[せい]"
                defaultValue={form.kanaSei}
                className={STContactForm.contact_form_input}
                name={CONTACT_FORM_ITEM.kanaSei}
                formKey={CONTACT_FORM_ITEM.kanaSei}
                hundleOnChange={(input) =>
                  hundleChange({ ...form, kanaSei: input })
                }
                errors={formErrors}
                setErrors={setFormErrors}
                maxLength={255}
                pattern={CONSTANTS.PATTERN.ONLY_HIRAGANA}
                patternText="ひらがな"
                required={true}
              />
              <TextFormRow<TAppContactFormItem>
                title="[めい]"
                defaultValue={form.kanaMei}
                className={STContactForm.contact_form_input}
                name={CONTACT_FORM_ITEM.kanaMei}
                formKey={CONTACT_FORM_ITEM.kanaMei}
                hundleOnChange={(input) =>
                  hundleChange({ ...form, kanaMei: input })
                }
                errors={formErrors}
                setErrors={setFormErrors}
                maxLength={255}
                pattern={CONSTANTS.PATTERN.ONLY_HIRAGANA}
                patternText="ひらがな"
                required={true}
              />
            </div>
            <div>
              <label>
                <Label id="" className="" for="" value="メールアドレス" />
                <span className={STContactForm.contact_form_content_attention}>
                  ※必須入力項目
                </span>
              </label>
              <TextFormRow<TAppContactFormItem>
                title="確認の為、２回入力して下さい。"
                defaultValue={form.email}
                className={STContactForm.contact_form_input}
                name={CONTACT_FORM_ITEM.email}
                formKey={CONTACT_FORM_ITEM.email}
                hundleOnChange={(input) =>
                  hundleChange({ ...form, email: input })
                }
                errors={formErrors}
                setErrors={setFormErrors}
                maxLength={255}
                pattern={CONSTANTS.PATTERN.EMAIL}
                patternText="メールアドレスの形式"
                required={true}
              />
              <TextFormRow<TAppContactFormItem>
                title="[確認]"
                defaultValue={form.emailConfirm}
                className={STContactForm.contact_form_input}
                name={CONTACT_FORM_ITEM.emailConfirm}
                formKey={CONTACT_FORM_ITEM.emailConfirm}
                hundleOnChange={(input) =>
                  hundleChange({ ...form, emailConfirm: input })
                }
                errors={formErrors}
                setErrors={setFormErrors}
                maxLength={255}
                pattern={CONSTANTS.PATTERN.EMAIL}
                patternText="メールアドレスの形式"
                required={true}
                matchValue={form.email}
                matchMessage="メールアドレス"
              />
            </div>
            <div>
              <label>
                <Label id="" className="" for="" value="電話番号" />
                <span className={STContactForm.contact_form_content_attention}>
                  ※必須入力項目
                </span>
              </label>
              <TextFormRow<TAppContactFormItem>
                defaultValue={form.tel}
                className={STContactForm.contact_form_input}
                name={CONTACT_FORM_ITEM.tel}
                formKey={CONTACT_FORM_ITEM.tel}
                hundleOnChange={(input) =>
                  hundleChange({ ...form, tel: input })
                }
                errors={formErrors}
                setErrors={setFormErrors}
                pattern={CONSTANTS.PATTERN.ONLY_NUMBER}
                patternText="半角数字"
                maxLength={11}
                minLength={10}
                required={true}
              />
            </div>
          </div>
        )}
        <div>
          <label>
            <Label id="" className="" for="" value="お問い合わせ内容" />
            <span className={STContactForm.contact_form_content_attention}>
              ※必須入力項目
            </span>
          </label>
          <TextAreaFormRow<TAppContactFormItem>
            rows={12}
            cols={30}
            defaultValue={form.body}
            className={STContactForm.contact_form_input}
            name={CONTACT_FORM_ITEM.body}
            formKey={CONTACT_FORM_ITEM.body}
            hundleOnChange={(input) => hundleChange({ ...form, body: input })}
            errors={formErrors}
            setErrors={setFormErrors}
            maxLength={1000}
            required={true}
          />
        </div>
        <Button
          className={
            canApply
              ? `${STContactForm.apply_btn} ${STButton.primary}`
              : `${STContactForm.apply_btn} ${STButton.disable}`
          }
          clickAction={hundleSubmit}
          disabled={!canApply}
          text="確認"
        />
      </div>
    </div>
  );
};

export default ContactForm;

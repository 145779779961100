import { useContext, useEffect, useState } from "react";
import { SelectBoxOption } from "../../types/form/selectBoxOption";
import Button from "../atoms/Button";
import Label from "../atoms/form/Label";
import SelectBox from "../atoms/form/SelectBox";
import TextLabel from "../atoms/TextLabel";
import STButton from "../../assets/styles/atoms/button.module.scss";
import STPointRequestCard from "../../assets/styles/organisms/pointRequestCard.module.scss";
import TApiResult from "../../types/api/TApiResult";
import {
  fetchPointRequest,
  TApiPointRequestResult,
} from "../../utils/api/fetchPointRequest";
import {
  postPointRequest,
  TApiPointRequestValidateErrors,
} from "../../utils/api/postPointRequest";
import { useHistory } from "react-router-dom";
import TextFormRow from "../molecules/formRow/TextFormRow";
import {
  POINT_REQUEST_FORM_ITEM,
  TAppPointRequestFormItem,
} from "../../types/TAppPointRequestFromItem";
import { PointRequestForm as TPointRequestForm } from "../../types/form/PointRequestForm";
import { TAppFormError } from "../../types/TAppFormError";
import PullDownFormRow from "../molecules/formRow/PullDownFormRow";
import { routes } from "../../router/Router";
import { postMemberAccessLog } from "../../utils/api/postMemberAccessLog";
import { ACCESS_LOG_ACTION } from "../../types/TAppAccessLogAction";
import { SiteContext } from "../../providers/SiteProvider";
import HtmlMessage from "../atoms/HtmlMessage";
import useMultipleClickPreventer from "../molecules/UseMultipleClickPreventer";

type FormSelectBox = {
  id: number;
  name: string;
  formType: string;
  label: string;
  options?: SelectBoxOption[];
  defaultValue?: string;
};

type FormSection = {
  method: string;
  title: string;
  entryMemo: FormSelectBox;
  actionMonth: FormSelectBox;
};

const actionMonthSelectBox: FormSelectBox = {
  id: 2,
  name: "data2",
  formType: "select",
  label: "いつ頃紹介しましたか？",
  defaultValue: "選択してください",
};

type Props = {
  setIsApply: (value: boolean) => void;
};

const PointRequestCard: React.FC<Props> = (props: Props) => {
  const [canApply, setCanApply] = useState(false);
  const [methodOptions, setMethodOptions] = useState<SelectBoxOption[]>([]);
  const [formSections, setFormSections] = useState<FormSection[]>([]);
  const [selectedMethod, setSelectedMethod] = useState<string>("");
  const [selectedFormSection, setSelectedRequestForm] = useState<FormSection>();
  const history = useHistory();
  const [formErrors, setFormErrors] = useState<
    TAppFormError<TAppPointRequestFormItem>[]
  >([]);
  const initialForm: TPointRequestForm = {
    entryMemo: "",
    actionMonth: "",
  };
  const [form, setForm] = useState<TPointRequestForm>(initialForm);

  useEffect(() => {
    fetchPointRequest().then((res: TApiResult & TApiPointRequestResult) => {
      if (!res.isSuccess || !res.result) return history.push(routes.error.path);
      const newMethodOptions: SelectBoxOption[] = res.result.master.map(
        (method) => {
          return { id: method.id, value: `${method.id}`, title: method.name };
        }
      );
      const monthOptions: SelectBoxOption[] = res.result.applyable_month.map(
        (month) => {
          return { id: month.id, value: `${month.month}`, title: month.month };
        }
      );
      setMethodOptions(newMethodOptions);
      const newForms: FormSection[] = res.result.master.map((method) => {
        return {
          method: `${method.id}`,
          title: method.letter_content,
          entryMemo: {
            id: method.id,
            name: method.name,
            formType: method.action_options.length > 0 ? "select" : "text",
            label: method.item_name,
            options: method.action_options.sort((a, b) => a.sort - b.sort).map((actionItem) => {
              return {
                id: actionItem.id,
                value: actionItem.name,
                title: actionItem.name,
              };
            }),
          },
          actionMonth: {
            ...actionMonthSelectBox,
            options: monthOptions,
            label: method.timing_name,
          },
        };
      });
      setFormSections(newForms);
      setSelectedMethod(newForms[0].method);
    });
  }, []);

  useEffect(() => {
    const form = formSections.find(
      (requestForm) => requestForm.method === selectedMethod
    );
    if (!form) return;
    setSelectedRequestForm(form);
    setForm(initialForm);
  }, [selectedMethod]);

  const validate = (entryMemo: string, actionMonth: string) => {
    const maxLength = 100;

    if (entryMemo.length > maxLength)
      setFormErrors([
        { key: "entry_memo", messages: ["100文字以下で入力してください"] },
      ]);

    return (
      entryMemo !== "" &&
      actionMonth !== "" &&
      entryMemo.length <= maxLength &&
      actionMonth.length <= maxLength
    );
  };

  const hundleChange = (newEntryMemo: string, newActionMonth: string): void => {
    const newForm: TPointRequestForm = {
      entryMemo: newEntryMemo,
      actionMonth: newActionMonth,
    };
    setForm(newForm);
    setCanApply(validate(newEntryMemo, newActionMonth));
  };

  const hundleSubmit = useMultipleClickPreventer<() => Promise<any>>(() => {
    if (!canApply) return Promise.resolve();
    return postPointRequest(
      Number(selectedMethod),
      form.entryMemo,
      form.actionMonth
    ).then((res: TApiResult & TApiPointRequestValidateErrors) => {
      if (!res.isSuccess) {
        setFormErrors(res.validateErrors);
      } else {
        const accessLogAction = site.isPoint ? ACCESS_LOG_ACTION.pointRequest : ACCESS_LOG_ACTION.rewardRequest;
        postMemberAccessLog(accessLogAction, document.referrer);
        props.setIsApply(true);
      }
    });
  });

  const site = useContext(SiteContext);

  return (
    <section className={STPointRequestCard.point_request_card}>
      <h1 className={STPointRequestCard.point_request_card_title}>
        {site.isPoint ? "ポイント申請" : "リワード申請"}
      </h1>
      <div
        className={`${STPointRequestCard.point_request_card_method}`}
      >
        <TextLabel
          className={STPointRequestCard.point_request_card_method_title}
          fontSize="14"
          isBold={true}
        >
          {site.isPoint ? "ポイントをためる方法" : "リワードをためる方法"}
        </TextLabel>
        <div className={STPointRequestCard.point_request_card_method_select}>
          <SelectBox
            className={
              STPointRequestCard.point_request_card_method_form_selectbox
            }
            name="method"
            options={methodOptions}
            defaultValue={selectedMethod}
            onChange={setSelectedMethod}
          />
        </div>
      </div>
      {selectedFormSection && (
        <div className={STPointRequestCard.point_request_card_request_form}>
          <HtmlMessage message={selectedFormSection.title} />
          <TextLabel
            className={
              STPointRequestCard.point_request_card_request_form_required_text
            }
            fontSize="12"
            isBold={false}
          >
            ※下記、全て必須入力項目です。
          </TextLabel>
          <div className={STPointRequestCard.form_item_label}>
            <Label
              className=""
              for={actionMonthSelectBox.name}
              value={selectedFormSection.entryMemo.label}
            />
          </div>
          <div className="form">
            {selectedFormSection.entryMemo.formType === "text" ? (
              <div className={STPointRequestCard.form_item}>
                <TextFormRow<TAppPointRequestFormItem>
                  defaultValue={form.entryMemo}
                  className={STPointRequestCard.form_item_text}
                  name={POINT_REQUEST_FORM_ITEM.entryMemo}
                  formKey={POINT_REQUEST_FORM_ITEM.entryMemo}
                  hundleOnChange={(input) =>
                    hundleChange(input, form.actionMonth)
                  }
                  errors={formErrors}
                  setErrors={setFormErrors}
                />
              </div>
            ) : (
              <PullDownFormRow<TAppPointRequestFormItem>
                defaultValue={form.entryMemo}
                name={POINT_REQUEST_FORM_ITEM.entryMemo}
                className={STPointRequestCard.form_item_selectbox}
                options={selectedFormSection.entryMemo.options || []}
                formKey={POINT_REQUEST_FORM_ITEM.entryMemo}
                errors={formErrors}
                hundleChange={(input) => hundleChange(input, form.actionMonth)}
              />
            )}
            <div className={STPointRequestCard.form_item}>
              <div className={STPointRequestCard.form_item_label}>
                <Label
                  className=""
                  for={actionMonthSelectBox.name}
                  value={selectedFormSection.actionMonth.label}
                />
              </div>
              <PullDownFormRow<TAppPointRequestFormItem>
                defaultValue={form.actionMonth}
                name={POINT_REQUEST_FORM_ITEM.actionMonth}
                className={STPointRequestCard.form_item_selectbox}
                options={selectedFormSection.actionMonth.options || []}
                formKey={POINT_REQUEST_FORM_ITEM.actionMonth}
                errors={formErrors}
                hundleChange={(input) => hundleChange(form.entryMemo, input)}
              />
            </div>
            <Button
              className={
                canApply
                  ? `${STPointRequestCard.point_request_card_request_form_request_btn} ${STButton.primary}`
                  : `${STPointRequestCard.point_request_card_request_form_request_btn} ${STButton.disable}`
              }
              clickAction={hundleSubmit}
              text="申請"
            />
          </div>
        </div>
      )}
    </section>
  );
};

export default PointRequestCard;

import { format as fnsFormat } from "date-fns";
import PAGE_CONFIG from "../../config/page";
export type TApiDatetime = string;

export const parse = (value: TApiDatetime): Date | undefined => {
  if (value === "") return undefined;
  return new Date(Date.parse(value));
};

export const format = (
  value: TApiDatetime,
  pattern: string = PAGE_CONFIG.DATE_FORMAT
): string => {
  if (value === "") return "";
  return fnsFormat(new Date(Date.parse(value)), pattern);
};

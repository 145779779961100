import { ReactNode } from "react";
import STTableRow from "../../assets/styles/atoms/tableRow.module.scss";

type Props = {
  title: string;
  required?: boolean;
  subTitle?: string;
  children: ReactNode;
};

const TableRow: React.FC<Props> = ({
  title,
  required = false,
  subTitle,
  children,
}) => {
  return (
    <tr>
      <th>
        <p>
          {title}
          {required && <span className={STTableRow.attention}>必須</span>}
        </p>
        {subTitle && <p className={STTableRow.subTitle}>{subTitle}</p>}
      </th>
      <td>{children}</td>
    </tr>
  );
};

export default TableRow;

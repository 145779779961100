import { AxiosError } from "axios";
import client from "../../api/client";
import TApiResult from "../../types/api/TApiResult";
import getErrorProperty from "./config/getErrorProperty";

export const postPasswordResetMail = async (
  email: string
): Promise<TApiResult> => {
  return client()
    .password.reset_mail.post({ body: { email: email } })
    .then((res) => {
      return {
        isSuccess: true,
        status: 200,
        message: "",
      };
    })
    .catch((e: AxiosError) => {
      const { status, message, errorMessages } = getErrorProperty(
        e,
        "postMemberPasswordResetEmail"
      );

      const invalidMessage =
        status === 404 ? 'このメールアドレスは登録されておりません。'
          : errorMessages && errorMessages.details.length > 0
          ? errorMessages.details[0].values.join(" ")
          : "正しいメールアドレスを入力してください。";

      return {
        isSuccess: false,
        status: status,
        message: invalidMessage,
      };
    });
};

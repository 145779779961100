import STUtils from "../../assets/styles/utility.module.scss";
import STPointRequestApplyCard from "../../assets/styles/organisms/pointRequestApplyCard.module.scss";
import PAGE_CONFIG from "../../config/page";
import { Link, useHistory } from "react-router-dom";
import { routes } from "../../router/Router";
import Button from "../atoms/Button";
import STButton from "../../assets/styles/atoms/button.module.scss";
import { useContext } from "react";
import { SiteContext } from "../../providers/SiteProvider";

const PointRequestApplyCard = () => {
  const history = useHistory();
  const site = useContext(SiteContext);
  return (
    <div className={STUtils.container_form}>
      <h1 className={STPointRequestApplyCard.point_request_apply_card_title}>
        {site.isPoint ? "ポイント申請完了" : "リワード申請完了"}
      </h1>
      <p className={STPointRequestApplyCard.point_request_apply_card_txt}>
        申請ありがとうございました。
        <br />
        確認しますので、申請が承認されるまでお待ちください。
      </p>
      <Button
        className={`${STPointRequestApplyCard.point_request_apply_card_more_btn}  ${STButton.primary}`}
        clickAction={() => history.go(0)}
        text="続けて申請"
      />
      <div className={STPointRequestApplyCard.point_request_apply_card_link}>
        <Link to={routes.pointHistory.path}>
          {site.isPoint ? "ポイント履歴" : "リワード履歴"} &gt;
        </Link>
      </div>
    </div>
  );
};

export default PointRequestApplyCard;

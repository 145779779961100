import client from "../../api/client";
import TApiResult from "../../types/api/TApiResult";
import { AxiosError } from "axios";
import getErrorProperty from "./config/getErrorProperty";
import { TBannerSettingResponse } from "../../api/banner_setting";

export type TApiBannerSettingsResult = {
  result?: TBannerSettingResponse[];
};

export const fetchBannerSettings = async (): Promise<TApiResult & TApiBannerSettingsResult> => {
  return client()
    .banner_setting.get()
    .then((res) => {
      return {
        isSuccess: true,
        status: 200,
        message: "",
        result: res.body,
      };
    })
    .catch((e: AxiosError) => {
      const { status, message } = getErrorProperty(e, "fetchMessageSetting");
      return {
        isSuccess: false,
        status: status,
        message: message,
      };
    });
};

// NOTE: https://qiita.com/saba_can00/items/bdefb28a1873658cf5d9
export const EVENT_INVITATION_FORM_ITEM = {
  introducerName: "introducer_name",
  introducerRelated: "introducer_related",
  sei: "sei",
  mei: "mei",
  kanaSei: "kana_sei",
  kanaMei: "kana_mei",
  tel: "tel",
  email: "email",
  zipcode: "zipcode",
  prefName: "pref_name",
  cityName: "city_name",
  address: "address",
  adultCount: "adult_count",
  childCount: "child_count",
  note: "note",
  freeItem: "free_item",
  location: "location",
} as const;

type TypeOfEventInvitationFormItem = typeof EVENT_INVITATION_FORM_ITEM;

export type TAppEventInvitationFormItem =
  TypeOfEventInvitationFormItem[keyof TypeOfEventInvitationFormItem];

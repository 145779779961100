import { TAppFormError } from "../../types/TAppFormError";
import AlartMessageBox from "./AlartMessageBox";

type Props<T> = {
  title: string;
  errors: TAppFormError<T>[];
  keys: T[];
};

const AlartMessages = <T,>({ title, errors, keys }: Props<T>) => {
  return errors.filter((error) => keys.includes(error.key)).length !== 0 ? (
    errors
      .filter((error) => keys.includes(error.key))
      .map((error, keyIndex) =>
        error.messages.map((message, messageIndex) => (
          <AlartMessageBox
            key={`${title}_${keyIndex}_${messageIndex}`}
            message={message}
          />
        ))
      )
  ) : (
    <></>
  );
};

export default AlartMessages;

import { AxiosError } from "axios";
import TApiErrorMessage from "../../../types/api/TApiErrorMessage";

const getErrorProperty = (
  e: AxiosError,
  api: string
): { status: number; message: string; errorMessages?: TApiErrorMessage } => {
  let status = e.response?.status;
  let message = "";

  if (e.response && status === 400) {
    const errorMessage: TApiErrorMessage = e.response?.data;
    return {
      status: status,
      message: e.response.data.message,
      errorMessages: errorMessage,
    };
  }
  if (e.response && status === 404) {
    message = e.response?.data.message;
  }
  if (e.response && status === 401) {
    message = "タイムアウトしました。";
  }
  if (e.response && status === 409) {
    message = e.response.data.detail;
  }
  if (e.response && status === 500) {
    message = e.response.data.message;
  }
  status = status || 500;

  return { status, message };
};
export default getErrorProperty;

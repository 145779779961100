import STUtils from "../../assets/styles/utility.module.scss";
import STMemberEntryApplyCard from "../../assets/styles/organisms/memberEntryApplyCard.module.scss";
import ButtonLink from "../atoms/ButtonLink";
import STButton from "../../assets/styles/atoms/button.module.scss";
import { routes } from "../../router/Router";

const MemberEntryApplyCard: React.FC = () => {
  return (
    <div className={STUtils.container_form}>
      <h1 className={STMemberEntryApplyCard.title}>登録申請完了</h1>
      <section>
        <h2 className={STMemberEntryApplyCard.sub_title}>
          登録申請が完了しました。
        </h2>
        <p className={STMemberEntryApplyCard.text}>
          承認手続きが完了しましたら、
          <br />
          メールにて2営業日以内にご連絡いたします。
          <br />
          承認完了後、マイページにログインしてください。
        </p>
        <ButtonLink
          url={routes.top.path}
          text="TOPページ"
          buttonClassName={`${STMemberEntryApplyCard.btn} ${STButton.tertiary}`}
        />
      </section>
    </div>
  );
};

export default MemberEntryApplyCard;

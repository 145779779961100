import MemberEditForm from "../organisms/MemberEditForm";
import BaseHead from "../templates/BaseHead";
import STUtils from "../../assets/styles/utility.module.scss";
import { getSubdomain } from "../../utils/getSubdomain";
import { routes } from "../../router/Router";
import BottomNavigationBar from "../organisms/BottomNavigationBar";

const MemberEdit: React.FC = () => {
  const subdomain = getSubdomain(window.location.hostname);

  return (
    <BaseHead
      title={routes.memberEdit.title}
      login={true}
      subdomain={subdomain}
    >
      <section className={STUtils.container_large}>
        <MemberEditForm />
        <div style={{marginBottom: '20px'}}>
          <BottomNavigationBar />
        </div>
      </section>
    </BaseHead>
  );
};
export default MemberEdit;

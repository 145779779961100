import BaseHeadNoindex from "../templates/BaseHeadNoindex";
import STUtils from "../../assets/styles/utility.module.scss";
import { getSubdomain } from "../../utils/getSubdomain";
import { useEffect, useState } from "react";
import EventInvitationDateForm from "../organisms/EventInvitationDateForm";
import { TAppTimeRange } from "../../types/TAppTimeRange";
import { EventEntryForm } from "../../types/form/eventEntryForm";
import EventInvitationEntryForm from "../organisms/EventInvitationEntryForm";
import { TAppEvent } from "../../types/TAppEvent";
import {
  TAppEventFromSeting,
  TAppEventFromSettingFormItem,
  TAppEventFromSettingFreeItem,
} from "../../types/TAppEventFormSetting";
import EventInvitationEntryConfirmCard from "../organisms/EventInvitationEntryConfirmCard";
import EventInvitationEntryCompleteCard from "../organisms/EventInvitationEntryCompleteCard";
import { TAppCalendarTarget } from "../../types/TAppCalendarTarget";
import { fetchReservationSettingDetail } from "../../utils/api/fetchReservationSettingDetail";
import { fetchEventDetail } from "../../utils/api/fetchEventDetail";
import { TAppCalendarTargetDate } from "../../types/TAppCalendarTargetDate";
import getGroupCalendarTarget from "../../utils/getGroupCalendarTargets";
import { fetchEventRequired } from "../../utils/api/fetchEventRequired";
import { TAppEventInvitationFormItem } from "../../types/TAppEventInvitationFormItem";
import { TAppFormError } from "../../types/TAppFormError";
import { useHistory, useParams } from "react-router-dom";
import { TReservationSettingTarget } from "../../api/reservation_settings";
import { routes } from "../../router/Router";
import { fetchSite } from "../../utils/api/fetchSite";
import { useCookies } from "react-cookie";
import { TApiTrackingRequestBody } from "../../api/tracking";
import { postTracking } from "../../utils/api/postTrackingRequest";

const initialForm: EventEntryForm = {
  introducerName: "",
  introducerRelated: "",
  sei: "",
  mei: "",
  kanaSei: "",
  kanaMei: "",
  tel: "",
  email: "",
  zipcode: "",
  prefName: "",
  cityName: "",
  address: "",
  adultCount: "",
  childCount: "",
  note: "",
  freeItem: "",
  location: "",
};
const initialTimeRange = { time: "", isFull: false };

const fetchEvent = async (eventId: number) => {
  return fetchEventDetail(eventId).then((res) => {
    if (!res.isSuccess || !res.result) return;
    return res.result;
  });
};
const fetchSetting = async (eventId: number) => {
  return fetchReservationSettingDetail(eventId).then((res) => {
    if (!res.isSuccess || !res.result) return;
    return res.result;
  });
};

const fetchRequired = async (eventId: number) => {
  return fetchEventRequired(eventId).then((res) => {
    if (!res.isSuccess || !res.result) return;
    return res.result;
  });
};

const convertCalenderTarget = (
  targets: TReservationSettingTarget[]
): TAppCalendarTarget[] => {
  return targets.map((target) => {
    const dateMap = getGroupCalendarTarget(
      target.targetDates,
      (target) => target.date
    );
    const newDates: TAppCalendarTargetDate[] = [];
    dateMap.forEach((v, k) => {
      const timeRanges = v.map((date) => {
        return { time: date.time, isFull: date.is_full };
      });
      newDates.push({
        date: k,
        isFull: v.every((date) => date.is_full),
        timeRanges: timeRanges,
      });
    });
    return { month: target.month, dates: newDates };
  });
};
const subdomain = getSubdomain(window.location.hostname);

const EventInvitation: React.FC = () => {
  const urlParams = useParams<{ eventId: string }>();

  const history = useHistory();
  const [eventId, setEventId] = useState<number>();
  const [month, setMonth] = useState<string>("");
  const [date, setDate] = useState("");
  const [timeRange, setTimeRange] = useState<TAppTimeRange>(initialTimeRange);
  const [dateTimeSelected, setDateTimeSelected] = useState(false);
  const [form, setForm] = useState<EventEntryForm>(initialForm);
  const [formEntried, setFormEntried] = useState(false);
  const [apply, setApply] = useState(false);
  const [event, setEvent] = useState<TAppEvent>();
  const [targets, setTargets] = useState<TAppCalendarTarget[]>([]);
  const [eventFormSetting, setEventFormSetting] =
    useState<TAppEventFromSeting>();
  const [formErrors, setFormErrors] = useState<
    TAppFormError<TAppEventInvitationFormItem>[]
  >([]);
  const [privacyPolicyLink, setPrivacyPolicyLink] = useState<string>();
  const [referrerId, setReferrerId] = useState("");
  const [cookies] = useCookies(["ambLpView"]);
  const resetTimeRange = () => {
    setTimeRange(initialTimeRange);
  };

  useEffect(() => {
    if (isNaN(parseInt(urlParams.eventId)))
      return history.push(routes.error.path);
    setEventId(parseInt(urlParams.eventId));
  }, [urlParams.eventId, history]);

  const isSkipDatetimeSelect = (event: TAppEvent) => {
    return !event.schedule_setting_flg;
  };

  useEffect(() => {
    (async () => {
      if (!eventId) return;
      const event = await fetchEvent(eventId);
      if (!event) return history.push(routes.error.path);

      const setting = await fetchSetting(event.id);
      const eventRequired = await fetchRequired(eventId);
      if (!eventRequired || !setting) return history.push(routes.error.path);

      const newEvent: TAppEvent = {
        id: event.id,
        title: event.name,
        reservationSettingsId: event.reservation_settings_id,
        from: setting.reservable_time_start,
        to: setting.reservable_time_end,
        spanMinute: setting.reservable_border_time,
        restFrom: setting.rest_time_start,
        restTo: setting.rest_time_end,
        schedule_setting_flg: event.schedule_setting_flg,
        lp_view_point_grant_flg: event.lp_view_point_grant_flg,
        cv_point_grant_flg: event.cv_point_grant_flg,
      };
      setEvent(newEvent);

      const newTargets = convertCalenderTarget(setting.targets);

      setTargets(newTargets);
      if (newTargets.length > 0) setMonth(newTargets[0].month);

      const freeItem: TAppEventFromSettingFreeItem = {
        title: setting.free_item_name,
        options: setting.free_item_option,
      };
      const formItems: TAppEventFromSettingFormItem[] = eventRequired
        .filter((item) => item.is_display)
        .map((item) => {
          return {
            name: item.item_name as TAppEventInvitationFormItem,
            required: item.is_required,
          };
        });
      const newEventFormSetting: TAppEventFromSeting = {
        relate: setting.introducer_option,
        place: setting.location_option,
        freeItem: freeItem,
        formItems: formItems,
      };
      setEventFormSetting(newEventFormSetting);
      if (history.location.search) {
        setReferrerId(
          history.location.search.substring(
            history.location.search.lastIndexOf("=") + 1
          )
        );
      }
      const trackingView: TApiTrackingRequestBody = {
        uid: "",
        referrer_uid: history.location.search
          ? history.location.search.substring(
              history.location.search.lastIndexOf("=") + 1
            )
          : "",
        session_uuid: cookies.ambLpView,
        url: window.location.href,
        tracking_flg: 1,
        track_event_name: "申込ボタンクリック",
        click_tag: "click",
        referrer: window.location.href.replace("event_invitation", "events"),
      };
      postTracking(trackingView).then((res) => {
        if (!res.isSuccess) return history.push(routes.error.path);
      });
    })();

    fetchSite().then((res) => {
      if (!res.isSuccess || !res.result) return;
      setPrivacyPolicyLink(res.result.privacy_policy_path);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId, history]);

  if (!event || !targets || !eventFormSetting) return <></>;

  return (
    <BaseHeadNoindex
      title={routes.eventInvitation.title}
      login={false}
      subdomain={subdomain}
    >
      <section className={STUtils.container_event}>
        <div className={STUtils.container_event_inner}>
          {!isSkipDatetimeSelect(event) && !dateTimeSelected ? (
            <EventInvitationDateForm
              targets={targets}
              month={month}
              setMonth={setMonth}
              event={event}
              date={date}
              setDate={setDate}
              timeRange={timeRange}
              setTimeRange={setTimeRange}
              resetTimeRange={resetTimeRange}
              setDateTimeSelected={setDateTimeSelected}
            />
          ) : !formEntried ? (
            <EventInvitationEntryForm
              event={event}
              form={form}
              formErrors={formErrors}
              setForm={setForm}
              setFormEntried={setFormEntried}
              setFormErrors={setFormErrors}
              date={date}
              timeRange={timeRange}
              eventFormSetting={eventFormSetting}
              privacyPolicyLink={privacyPolicyLink}
              setDateTimeSelected={setDateTimeSelected}
            />
          ) : !apply ? (
            <EventInvitationEntryConfirmCard
              event={event}
              form={form}
              date={date}
              timeRange={timeRange}
              eventFormSetting={eventFormSetting}
              setApply={setApply}
              setFormEntried={setFormEntried}
              setFormErrors={setFormErrors}
              referrerId={referrerId}
              uuid={cookies.ambLpView}
              url={window.location.href}
            />
          ) : (
            <EventInvitationEntryCompleteCard
              email={form.email}
              eventId={event.id}
              referrerId={referrerId}
            />
          )}
        </div>
      </section>
    </BaseHeadNoindex>
  );
};
export default EventInvitation;

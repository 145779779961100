import client from "../../api/client";
import TApiResult from "../../types/api/TApiResult";
import { AxiosError } from "axios";
import getErrorProperty from "./config/getErrorProperty";
import { TPointRequestResponse } from "../../api/point/request";
import { getAuth } from "firebase/auth";
import tokenNotFound from "./config/tokenNotFound";

export type TApiPointRequestResult = {
  result?: TPointRequestResponse;
};

export const fetchPointRequest = async (): Promise<
  TApiResult & TApiPointRequestResult
> => {
  const auth = getAuth();
  const token = await auth.currentUser?.getIdToken();
  if (!token) return tokenNotFound;
  return await client()
    .point.request.get({
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      return {
        isSuccess: true,
        status: 200,
        message: "",
        result: res.body,
      };
    })
    .catch((e: AxiosError) => {
      const { status, message } = getErrorProperty(e, "fetchPointRequest");
      return {
        isSuccess: false,
        status: status,
        message: message,
      };
    });
};

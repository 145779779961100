import TableRow from "../atoms/TableRow";

type Props = {
  isDisplay: boolean;
  title: string;
  body: string;
};

const TextTableRow: React.FC<Props> = ({ isDisplay, title, body }) => {
  return isDisplay ? (
    <TableRow title={title}>
      <p>{body}</p>
    </TableRow>
  ) : (
    <></>
  );
};

export default TextTableRow;

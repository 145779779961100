import client from "../../api/client";
import TApiResult from "../../types/api/TApiResult";
import { AxiosError } from "axios";
import getErrorProperty from "./config/getErrorProperty";
import { TMenuSettingResponse } from "../../api/menu_setting";

export type TApiMenuSettingsResult = {
  result?: TMenuSettingResponse[];
};

export const fetchMenuSettings = async (
  locationId?: number
): Promise<TApiResult & TApiMenuSettingsResult> => {
  return client()
    .menu_setting.get({
      query: {
        location_id: locationId
      }
    })
    .then((res) => {
      return {
        isSuccess: true,
        status: 200,
        message: "",
        result: res.body,
      };
    })
    .catch((e: AxiosError) => {
      const { status, message } = getErrorProperty(e, "fetchMessageSetting");
      return {
        isSuccess: false,
        status: status,
        message: message,
      };
    });
};

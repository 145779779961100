import STUtils from "../../assets/styles/utility.module.scss";
import { ContactForm as TContactForm } from "../../types/form/contactForm";
import STContactFormConfirm from "../../assets/styles/organisms/contactFormConfirm.module.scss";
import ContactFormConfirmContent from "../molecules/ContactFormConfirmContent";
import Button from "../atoms/Button";
import STButton from "../../assets/styles/atoms/button.module.scss";
import PAGE_CONFIG from "../../config/page";
import { getAuth } from "firebase/auth";

type Props = {
  form: TContactForm;
  hundleApply: () => void;
  setConfirm: (value: boolean) => void;
};

const ContactFormConfirm: React.FC<Props> = ({
  form,
  hundleApply,
  setConfirm,
}) => {
  const hundleSubmit = () => {
    hundleApply();
  };

  const hundleReturnSubmit = () => {
    setConfirm(false);
  };
  const auth = getAuth();

  return (
    <div className={STUtils.container_form}>
      <h1 className={STContactFormConfirm.title}>お問い合わせ</h1>
      <p className={STContactFormConfirm.description}>
        入力した内容に間違いがなければ
        <br />
        「送信する」ボタンをクリックしてください。
      </p>
      {!auth.currentUser && (
        <>
          <ContactFormConfirmContent title="お名前[姓]" body={form.sei} />
          <ContactFormConfirmContent title="お名前[名]" body={form.mei} />
          <ContactFormConfirmContent title="ふりがな[姓]" body={form.kanaSei} />
          <ContactFormConfirmContent title="ふりがな[名]" body={form.kanaMei} />
          <ContactFormConfirmContent title="メールアドレス" body={form.email} />
          <ContactFormConfirmContent title="電話番号" body={form.tel} />
        </>
      )}
      <ContactFormConfirmContent title="お問い合わせ内容" body={form.body} />

      <Button
        className={`${STContactFormConfirm.apply_btn} ${STButton.primary}`}
        clickAction={hundleSubmit}
        text="送信する"
      />
      <Button
        className={`${STContactFormConfirm.apply_btn} ${STButton.tertiary}`}
        clickAction={hundleReturnSubmit}
        text="書き直す"
      />
    </div>
  );
};

export default ContactFormConfirm;
